import { EegUserPageFilters, ProtocolStatus } from '../types';

export const serializeEegLabUserTableParams = (
  search: string | null,
  filters: EegUserPageFilters,
  sortFields: {
    created_at: string;
    is_archived: boolean;
  }
) => {
  const res = [];
  if (search) {
    res.push(`search=${search}`);
  }
  if (filters.nest_rfr) {
    res.push(`nest_status=${ProtocolStatus.READY_FOR_REVIEW}`);
  }
  if (filters.nest_approved) {
    res.push(
      `nest_status=${ProtocolStatus.APPROVED}&nest_status=${ProtocolStatus.UPDATED}`
    );
  }
  if (filters.nest_broken) {
    res.push(`nest_status=${ProtocolStatus.BROKEN}`);
  }
  if (filters.nexstim_rfr) {
    res.push(`nextstim_status=${ProtocolStatus.READY_FOR_REVIEW}`);
  }
  if (filters.nexstim_approved) {
    res.push(
      `nextstim_status=${ProtocolStatus.APPROVED}&nextstim_status=${ProtocolStatus.UPDATED}`
    );
  }
  if (filters.nexstim_broken) {
    res.push(`nextstim_status=${ProtocolStatus.BROKEN}`);
  }
  if (filters.protocolPending) {
    res.push(`status=${ProtocolStatus.PROCESSING}`);
  }
  if (filters.protocolProcessing) {
    res.push(`status=${ProtocolStatus.PENDING_PROCESSING}`);
  }
  if (filters.protocolBroken) {
    res.push(`status=${ProtocolStatus.BROKEN}`);
  }
  if (filters.protocolSuccess) {
    res.push(`status=${ProtocolStatus.OK}`);
  }
  if (sortFields.created_at) {
    res.push(`sort=${sortFields.created_at}`);
  }
  res.push(`is_archived=${sortFields.is_archived}`);
  filters.organizations.forEach((org) => {
    res.push(`org_id=${org.value}`);
  });
  return res.join('&');
};
