export const errorMessages = {
  BAD_USERNAME_OR_PASSWORD: 'Bad username or password',
  CLIENT_NOT_FOUND: 'There is no client with username',
  VALIDATION_ERRORS: 'Validation errors',
  INVALID_CODE: 'Invalid code',
  CONFIRMATION_CODE_IS_NOT_CORRECT: 'Confirmation Code is not correct',
  TEMPORARY_PASSWORD_IS_NOT_CORRECT:
    'Temporary password entered is not correct',
};

export const errorTypes = {
  TOS_NOT_SIGNED: 'tos_not_signed',
};
