import {
  ClientReportsFilter,
  EegUserPageFilters,
  EegUserFilterValues,
  PatientFiltersCommonValues,
  PatientFiltersFormVal,
  UserTableFiltersData,
  UserTableFilterFormValues,
  PPMDownloadsHistoryTableColumn,
  Organization,
} from '../types';

export const wnAdminEmptyResponseTableData: any[] = [
  {
    user_name: '',
    organization: '',
    status: '',
    is_active: '',
    role: '',
  },
];

export const wnAdminClientsTableColumns: any = [
  {
    Header: 'Client name',
    accessor: (data: { first_name: string; last_name: string }): string => {
      const { first_name, last_name } = data || {
        first_name: 'Unknown',
        last_name: 'Client',
      };

      return `${first_name} ${last_name}`;
    },
    width: '17%',
  },
  {
    Header: 'Organization',
    accessor: (data: {
      org_index_data: { name: string; is_fake: boolean; id: number };
    }): { name: string; is_fake: boolean; id: number } => {
      const { org_index_data } = data || {
        org_index_data: '',
      };

      return org_index_data;
    },
    width: '15%',
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '15%',
  },
  {
    Header: 'App username',
    accessor: 'user_name',
    width: '15%',
  },
  {
    Header: 'Session history',
    accessor: 'number_of_sonal_sessions',
    width: '10%',
  },
  {
    Header: 'TOS Status',
    accessor: 'tos_state',
    width: '25%',
  },
];

export const organisationClientsTableColumns: any = [
  {
    Header: 'Client name',
    accessor: (data: { first_name: string; last_name: string }): string => {
      const { first_name, last_name } = data || {
        first_name: 'Unknown',
        last_name: 'Client',
      };

      return `${first_name} ${last_name}`;
    },
    width: '20%',
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '25%',
  },
  {
    Header: 'App username',
    accessor: 'user_name',
    width: '15%',
  },
  {
    Header: 'Session History',
    accessor: 'session_history',
    width: '15%',
  },
  {
    Header: 'TOS Status',
    accessor: 'tos_state',
    width: '25%',
  },
];

export const wnAdminOrganizationsTableColumns: any[] = [
  {
    Header: 'Organization',
    accessor: 'name',
    width: '25%',
  },
  {
    Header: 'Organization ID',
    accessor: 'id',
    width: '25%',
  },
  {
    Header: 'Number of users',
    accessor: 'users_count',
    width: '25%',
  },
  {
    Header: 'Number of clients',
    accessor: 'clients_count',
    width: '25%',
  },
];

export const wnAdminTOSEmptyTableData: any[] = [
  {
    user_name: '',
    organization: '',
    email: '',
    tos_status: '',
    session_history: '',
  },
];

export const wnAdminTableColumns: any = [
  {
    Header: 'User name',
    accessor: 'user_name',
    width: '25%',
  },
  {
    Header: 'User type',
    accessor: 'role',
    width: '25%',
  },
  {
    Header: 'Organization',
    accessor: (user: {
      orgs?: Organization[];
      organizations?: Organization[];
    }) => {
      const { orgs = [], organizations = [] } = user ?? {};

      return (orgs?.length && orgs) || (organizations?.length && organizations);
    },
    width: '25%',
  },
  {
    Header: 'Approval status',
    accessor: 'status',
    width: '25%',
  },
  {
    Header: 'Activity status',
    accessor: 'is_active',
    width: '25%',
  },
];

export const organizationsProfileColumns: any = [
  {
    Header: 'User name',
    accessor: 'user_name',
    width: '25%',
  },
  {
    Header: 'User type',
    accessor: 'role',
    width: '25%',
  },
  {
    Header: 'Organization',
    accessor: (user: { organizations: Organization[] }) => {
      const { organizations = [] } = user ?? {};
      return organizations;
    },
    width: '25%',
  },
  {
    Header: 'User status',
    accessor: 'status',
    width: '25%',
  },
];

export const storeAdminEmptyResponseTableData: any[] = [
  {
    patient_name: '',
    eeg_filename: '',
    report: '',
    protocol: '',
  },
];
export const storeAdminEmptyResponseWithOrgTableData: any[] = [
  {
    patient_name: '',
    eeg_filename: '',
    report: '',
    organization: '',
    protocol: '',
  },
];

export const storeAdminTableColumns: any = [
  {
    Header: 'Client name',
    accessor: 'patient_name',
    width: '25%',
  },
  {
    Header: 'EEG file',
    accessor: 'eeg_filename',
    width: '25%',
  },
  {
    Header: 'Report',
    accessor: 'report',
  },
  {
    Header: 'Protocol',
    accessor: 'protocol',
    width: '25%',
  },
];

export const storeAdminTableWithOrgColumns: PPMDownloadsHistoryTableColumn[] = [
  {
    Header: 'Client name',
    accessor: 'patient_name',
    width: '20%',
  },
  {
    Header: 'Organization',
    accessor: 'organization',
    width: '20%',
  },
  {
    Header: 'EEG file',
    accessor: 'eeg_filename',
    width: '20%',
  },
  {
    Header: 'Report',
    accessor: 'report',
    width: '20%',
  },
  {
    Header: 'Protocol',
    accessor: 'protocol',
    width: '20%',
  },
];

export const patientProfileTableColumns: any = [
  {
    Header: 'EEG File',
    accessor: 'eeg_filename',
    width: '25%',
  },
  {
    Header: 'EEG File Type',
    accessor: 'eeg_type',
    width: '20%',
  },
  {
    Header: 'Report',
    accessor: 'report',
    width: '25%',
  },
  {
    Header: 'Protocol',
    accessor: 'protocol',
    width: '25%',
  },
  {
    Header: '',
    accessor: 'action',
    width: '5%',
  },
];

export const patientProfileEmptyResponseTableData: any[] = [
  {
    eeg_filename: '',
    eeg_type: '',
    report: '',
    protocol: '',
    action: '',
  },
];

export const clientReportsTableColumns: any = [
  {
    Header: 'EEG file',
    accessor: 'eeg_file',
    width: '25%',
  },
  {
    Header: 'EEG recording date',
    accessor: 'recording_date',
    width: '25%',
  },
  {
    Header: 'Report type',
    accessor: 'report_type',
    width: '25%',
  },
  {
    Header: 'Report status',
    accessor: 'status',
    width: '25%',
  },
];

export const PPMDownloadsHistoryTableColumns: any[] = [
  {
    Header: 'Download Date',
    accessor: 'downloaded_at',
    width: '20%',
  },
  {
    Header: 'Organization',
    accessor: 'org.name',
    width: '20%',
  },
  {
    Header: 'User Name',
    accessor: (data: {
      user: {
        first_name: string;
        last_name: string;
      };
    }): string => {
      const { first_name, last_name } = data?.user || {
        first_name: 'Unknown',
        last_name: 'User',
      };

      return `${first_name} ${last_name}`;
    },
    width: '20%',
    icon: 'question',
    description: 'The user who downloaded the PPM',
  },
  {
    Header: 'User Role',
    accessor: 'user.role',
    width: '20%',
  },
  {
    Header: 'Client Name',
    accessor: (data: {
      client: {
        first_name: string;
        last_name: string;
      };
    }): string => {
      const { first_name, last_name } = data?.client || {
        first_name: 'Unknown',
        last_name: 'Client',
      };

      return `${first_name} ${last_name}`;
    },
    width: '20%',
    icon: 'question',
    description: 'For whom the PPM was downloaded',
  },
];

export const clientReportsTableEmptyData: any[] = [
  {
    eeg_file: '',
    recording_date: '',
    report_type: '',
    status: '',
  },
];

export const filtersKeyValues: any = {
  pendingProcessing: 'Pending processing',
  processing: 'Processing',
  delayed: 'Delayed',
  approved: 'Approved',
  rejected: 'Rejected',
  broken: 'Processing failed',
  protocolBroken: 'Processing failed',
  protocolPending: 'Pending processing',
  protocolProcessing: 'Processing',
  protocolSuccess: 'Successfully processed',
  brokenReport: 'Report processing failed',
  brokenNeurorefReport: 'Processing failed',
  brokenBcReport: 'Processing failed',
  readyForReview: 'Ready for review',
  readyForReviewBc: 'Ready for review',
  approvedBc: 'Approved',
  rejectedBc: 'Rejected',
  nest_rfr: 'Ready for review Nest',
  nest_broken: 'Processing failed Nest',
  nexstim_rfr: 'Ready for review NexStim',
  nexstim_broken: 'Processing failed NexStim',
  nest_approved: 'Approved Nest',
  nexstim_approved: 'Approved NexStim',
  pendingApproval: 'Pending approval',
  active: 'Active',
  inactive: 'Inactive',
  protocolApproved: 'Protocol approved',
  protocolNotSpecified: 'Protocol not specified',
  protocolUpdated: 'Protocol updated',
  archived: 'Archived requests',
  storeUser: 'Store User',
  storeAdmin: 'Store Admin',
  eegLabUser: 'Eeg Lab User',
  wnSales: 'Wn Sales',
  executive: 'Executive',
  wnAdmin: 'Wn Admin',
  signed: 'Signed',
  notSigned: 'Not Signed',
  waitingForSignature: 'Waiting for signature',
  allowed: 'Active',
};

export const patientFiltersCommonValues: PatientFiltersCommonValues = {
  delayed: false,
  approved: false,
  brokenReport: false,
  protocolBroken: false,
  rejected: false,
  protocolApproved: false,
  protocolNotSpecified: false,
  protocolUpdated: false,
  archived: false,
};

export const eegUserFilterInitialValues: EegUserFilterValues = {
  protocolPending: false,
  protocolProcessing: false,
  protocolSuccess: false,
  nest_broken: false,
  nexstim_broken: false,
  readyForReview: false,
  delayed: false,
  approved: false,
  rejected: false,
  nest_rfr: false,
  nexstim_rfr: false,
  protocolBroken: false,
  brokenBcReport: false,
  brokenNeurorefReport: false,
  nest_approved: false,
  nexstim_approved: false,
  readyForReviewBc: false,
  approvedBc: false,
  rejectedBc: false,
};

export const patientFiltersInitialValues: PatientFiltersFormVal = {
  ...patientFiltersCommonValues,
  organizations: [],
};

export const eegUserFiltersInitialValues: EegUserPageFilters = {
  ...eegUserFilterInitialValues,
  organizations: [],
};

export const userTableFilterFormInitialValues: UserTableFilterFormValues = {
  pendingApproval: false,
  active: false,
  inactive: false,
  approved: false,
  allowed: false,
  rejected: false,
  storeAdmin: false,
  storeUser: false,
  eegLabUser: false,
  wnSales: false,
  executive: false,
  wnAdmin: false,
};

export const userTableFiltersInitialData: UserTableFiltersData = {
  ...userTableFilterFormInitialValues,
  organizations: [],
};

export const clientsTableFilterFormInitialValues = {
  waitingForSignature: false,
  signed: false,
  notSigned: false,
};

export const clientsTableFiltersInitialData = {
  ...clientsTableFilterFormInitialValues,
  organizations: [],
};

export const clientReportsTableFiltersInitialValues: ClientReportsFilter = {
  approved: false,
  rejected: false,
  processing: false,
  broken: false,
};

const eegLabUserCommonColumnsTable = [
  {
    Header: 'Client name',
    accessor: 'patient_name',
    width: '19%',
  },
  {
    Header: 'Organization',
    accessor: 'organization',
    width: '20%',
  },
  {
    Header: 'EEG file',
    accessor: 'eeg_filename',
    width: '25%',
  },
];

const eegLagUserTableCommonEmptyResponse = {
  patient_name: '',
  organization: '',
  eeg_filename: '',
};

export const eegLagUserNeurorefReportTableColumns: any = [
  ...eegLabUserCommonColumnsTable,
  {
    Header: 'Neuroref report',
    accessor: 'neuroref_report',
    width: '36%',
  },
];

export const eegLagUserBraincareReportTableColumns: any = [
  {
    Header: 'Client name',
    accessor: 'patient_name',
    width: '19%',
  },
  {
    Header: 'Organization',
    accessor: 'organization',
    width: '20%',
  },
  {
    Header: 'EEG file',
    accessor: 'eeg_filename',
    width: '25%',
  },
  {
    Header: 'Braincare report',
    accessor: 'braincare_report',
    width: '30%',
  },
];

export const eegLagUserProtocolTableColumns: any = [
  ...eegLabUserCommonColumnsTable,
  {
    Header: 'Protocol',
    accessor: 'protocol',
    width: '30%',
  },
];

export const eegLagUserTableNeurorefReportEmptyResponse: any[] = [
  {
    ...eegLagUserTableCommonEmptyResponse,
    neuroref_report: '',
  },
];

export const eegLagUserTableBraincareReportEmptyResponse: any[] = [
  {
    patient_name: '',
    organization: '',
    eeg_filename: '',
    braincare_report: '',
  },
];

export const eegLagUserTableProtocolEmptyResponse: any[] = [
  {
    ...eegLagUserTableCommonEmptyResponse,
    protocol: '',
  },
];

export const wnAdminTOSTableColumns: any = [
  {
    Header: 'Client name',
    accessor: 'user_name',
    width: '25%',
  },
  {
    Header: 'Organization',
    accessor: 'organization',
    width: '25%',
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: '25%',
  },
  {
    Header: 'TOS Status',
    accessor: 'tos_status',
    width: '25%',
  },
];

export const organizationsTableFiltersInitialData = {
  organizations: [],
};
