import React, { useContext, useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import { pathOr } from 'ramda';

import { UserContext } from '../../context/UserContext';
import Avatar from '../Avatar';

const SidebarMenuClientInfo: React.FunctionComponent = () => {
  const { user } = useContext(UserContext);

  const clientInfoRef = useRef(null);

  const avatarThumbnailUrl = pathOr('', ['image_thumbnail_url'], user);

  return (
    <div
      ref={clientInfoRef}
      data-tip
      data-for="client-info"
      className="flex justify-center py-3"
    >
      <Avatar src={avatarThumbnailUrl} imageStyles="w-6 h-6 rounded-full" />
      <ReactTooltip
        className="tooltip"
        id="client-info"
        place="right"
        effect="solid"
      >
        {pathOr('', ['name'], user)}
      </ReactTooltip>
    </div>
  );
};

export default SidebarMenuClientInfo;
