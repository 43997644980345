import { ChangeEventHandler, useState } from 'react';

interface Result {
  totalPageCount: number;
  setTotalPageCount: (value: number) => void;
  hasNext: boolean;
  setHasNext: (value: boolean) => void;
  hasPrev: boolean;
  setHasPrev: (value: boolean) => void;
  handleFilters: (filtersToSet: any) => void;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  setSearchTerm: (v: string) => void;
}

export const usePageData = (
  pageData: any,
  setPageData: (value: any) => void
): Result => {
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [hasPrev, setHasPrev] = useState<boolean>(false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (pageData.currentPage !== 1) {
      setPageData({
        ...pageData,
        currentPage: 1,
        searchTerm: event.target.value,
      });
      return;
    }
    setPageData({
      ...pageData,
      searchTerm: event.target.value,
    });
  };

  const handleFilters = (filtersToSet: any) => {
    if (pageData.currentPage !== 1) {
      setPageData({
        ...pageData,
        currentPage: 1,
        filters: filtersToSet,
      });
      return;
    }
    setPageData({
      ...pageData,
      filters: filtersToSet,
    });
  };

  const setSearchTerm = (value: string) =>
    setPageData({ ...pageData, searchTerm: value });

  return {
    totalPageCount,
    setTotalPageCount,
    hasNext,
    hasPrev,
    setHasNext,
    setHasPrev,
    handleChange,
    handleFilters,
    setSearchTerm,
  };
};
