import React, { createContext, FC, useState } from 'react';

import packageJson from '../../package.json';
import { USERS_SEEN_RELEASE_NOTES } from '../constants';
import { getItem, setItem } from '../utils/localStorageApi';

export interface ReleaseNotesContextProps {
  showReleaseNotes: (id?: number) => void;
  closeReleaseNotes: () => void;
  isShow: boolean;
  currentVersion: string;
}

export const ReleaseNotesContext = createContext<ReleaseNotesContextProps>({
  showReleaseNotes: () => undefined,
  closeReleaseNotes: () => undefined,
  isShow: true,
  currentVersion: '',
});

const ReleaseNotesProvider: FC = (props): JSX.Element => {
  const [isShowRN, setIsShowRN] = useState(false);
  const currentVersion = packageJson.version;

  const showReleaseNotes = (id?: number) => {
    const seenReleaseNotesUsers = JSON.parse(
      getItem(USERS_SEEN_RELEASE_NOTES) ?? ''
    );

    if (id === undefined) {
      // click on version (header place)
      setIsShowRN(true);
    }

    if (
      !seenReleaseNotesUsers ||
      seenReleaseNotesUsers.version !== currentVersion
    ) {
      // first set or set when new version is coming
      setItem(
        USERS_SEEN_RELEASE_NOTES,
        JSON.stringify({ version: currentVersion, ids: [id] })
      );
      setIsShowRN(true);
    } else if (!seenReleaseNotesUsers?.ids?.includes(id) && id) {
      // other set
      setIsShowRN(true);
      setItem(
        USERS_SEEN_RELEASE_NOTES,
        JSON.stringify({
          ...seenReleaseNotesUsers,
          ids: [...seenReleaseNotesUsers.ids, id],
        })
      );
    }
  };

  const closeReleaseNotes = () => {
    setIsShowRN(false);
  };

  const { children } = props;

  return (
    <ReleaseNotesContext.Provider
      value={{
        showReleaseNotes,
        closeReleaseNotes,
        isShow: isShowRN,
        currentVersion,
      }}
    >
      {children}
    </ReleaseNotesContext.Provider>
  );
};

export default ReleaseNotesProvider;
