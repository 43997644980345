import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Modal from 'react-modal';

import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import ModalClose from 'assets/icons/ModalClose';

import { modalStyles } from 'constants/styles';

import { ReleaseNotesContext } from 'context/ReleaseNotesContext';
import { UserContext } from 'context/UserContext';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import CommonButton from '../UI/Buttons/CommonButton';
import defaultImage from './baseImage/base-plug.png';
import { releaseContent } from './releaseContent';

export const ReleaseNotes = () => {
  const [step, setStep] = useState(0);

  const modalRef = useRef(null);

  const { isShow, currentVersion, closeReleaseNotes } = useContext(
    ReleaseNotesContext
  );

  const { user } = useContext(UserContext);
  const userRole = user?.role;

  const stepsCount = releaseContent[userRole]?.length;

  const isFirstStep = step === 0;
  const isLastStep = step === stepsCount - 1;

  const nextStep = () => {
    if (isLastStep) {
      closeReleaseNotes();
      setStep(0);
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step !== 0) setStep(step - 1);
  };

  const closeReleaseNotesModal = useCallback(() => {
    setStep(0);

    closeReleaseNotes();
  }, [closeReleaseNotes]);

  const path = releaseContent?.[userRole]?.[step];

  useEffect(() => {
    if (userRole) return;

    closeReleaseNotes();
  }, [closeReleaseNotes, userRole]);

  useEffect(() => {
    if (!isShow) return;

    window.onpopstate = () => closeReleaseNotesModal();
  }, [isShow, closeReleaseNotesModal]);

  useOnClickOutside(modalRef, () => closeReleaseNotesModal());

  return (
    <Modal isOpen={isShow} style={modalStyles} contentLabel="Release Notes">
      <div className="flex flex-col py-8 px-16 w-620px" ref={modalRef}>
        <div className="flex flex-row border-b border-tarquoise-blue justify-between mb-2">
          <span className="font-inter text-outer-space font-normal text-3.5xl mb-4 leading-10">
            What’s new in version {currentVersion}
          </span>
          <div
            className="cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={closeReleaseNotesModal}
          >
            <ModalClose />
          </div>
        </div>
        {Array.isArray(path?.description) ? (
          <div className="h-[500px] mt-6 text-outer-space">
            <div className="text-2xl mb-2">{path?.title}</div>
            <ul className="list-disc pl-6 overflow-auto h-[400px]">
              {path?.description?.map((item: string) => (
                <li key={item} className="mt-5 text-lg font-normal">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="mt-6">
            <div>
              {path?.mediaType === 'img' ? (
                <img
                  className="rounded h-[260px] mx-auto"
                  src={path?.media ? path?.media : defaultImage}
                  alt="RNImage"
                />
              ) : (
                <video
                  autoPlay
                  loop
                  controls
                  key={path?.media}
                  width="100%"
                  className="h-[260px]"
                >
                  <source src={path?.media} type="video/mp4" />
                  <track kind="captions" />
                </video>
              )}
            </div>
            <div className="mt-6">
              <div className="text-2xl font-light">{path?.title}</div>
              <div className="mt-5 text-outer-space h-64 overflow-auto">
                {typeof path?.description === 'function' ? (
                  <div>
                    <path.description />
                  </div>
                ) : (
                  path?.description
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className={`flex flex-row items-center mt-10 ${
            isFirstStep && 'flex-row-reverse'
          }`}
        >
          {step !== 0 && (
            <div className="w-[50%]">
              <CommonButton
                onClick={prevStep}
                icon={<ArrowLeftIcon />}
                text="Back"
                type="button"
                customStyles="text-nevada w-[40px] bg-white !py-4 flex-1 justify-start
                hover:opacity-60"
              />
            </div>
          )}

          <CommonButton
            onClick={nextStep}
            icon={isLastStep ? null : <ArrowRightIcon />}
            text={isLastStep ? 'Close' : 'Next'}
            type="button"
            reverse
            customStyles={`bg-tarquoise-blue !py-4 flex-1 hover:opacity-80 ${
              isFirstStep && 'max-w-[50%]'
            }`}
          />
        </div>
      </div>
    </Modal>
  );
};
