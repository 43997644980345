import React, { createContext, useCallback, useMemo, useState } from 'react';

import { SessionHistoryContextType } from 'types';

export const SessionHistoryContext = createContext<SessionHistoryContextType>({
  isShowDownloadButton: false,
  setToggleShowDownloadButton: () => true,
  clearSessionHistoryPageContextState: () => undefined,
});

const SessionHistoryProvider = (props: any) => {
  const [isShowDownloadButton, setToggleShowDownloadButton] = useState<boolean>(
    false
  );
  const { children } = props;

  const clearSessionHistoryPageContextState = useCallback(() => {
    setToggleShowDownloadButton(() => false);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isShowDownloadButton,
      setToggleShowDownloadButton,
      clearSessionHistoryPageContextState,
    }),
    [
      isShowDownloadButton,
      setToggleShowDownloadButton,
      clearSessionHistoryPageContextState,
    ]
  );

  return (
    <SessionHistoryContext.Provider value={memoizedValue}>
      {children}
    </SessionHistoryContext.Provider>
  );
};

export default SessionHistoryProvider;
