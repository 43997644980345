import { Styles } from 'react-modal';

export const selectDefaultStyles = {
  valueContainer: (styles: any) => ({
    ...styles,
    padding: '11.5px 20px',
  }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: 'none',
    border: '0.5px solid #A4A7A8',
    ':hover': {
      border: '0.5px solid #A4A7A8',
    },
    fontSize: '18px',
    outline: 'none',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#6B7071',
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    margin: 0,
    fontWeight: '400',
  }),
  dropdownIndicator: (styles: any, state: any) => ({
    ...styles,
    ':hover': {
      color: '#A4A7A8',
    },
    color: '#A4A7A8',
    padding: '11.5px 20px',
    cursor: 'pointer',
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: 'none',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#323839',
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: '400',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: 'pointer',
    padding: '8px 20px !important',
    color: state.isSelected ? '#42CDDD' : '#323839',
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    margin: 0,
    fontWeight: '400',
    backgroundColor: 'white',
    ':first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    ':last-child': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    ':hover': {
      backgroundColor: 'white',
      color: '#42CDDD',
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    marginTop: '8px',
    marginBottom: 0,
    borderRadius: '8px',
    zIndex: 1000,
    boxShadow: 'none',
  }),
  menuList: (styles: any) => ({
    ...styles,
    borderRadius: '8px',
    border: '0.5px solid #A4A7A8',
    boxShadow: 'none',
  }),
};

export const multiselectCheckboxStyles = {
  valueContainer: (styles: any) => ({
    ...styles,
    padding: '4px 12px',
  }),
  control: (styles: any) => ({
    ...styles,
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: 'none',
    border: '2px solid #DEDFDF',
    ':hover': {
      border: '2px solid #DEDFDF',
    },
    fontSize: '16px',
    outline: 'none',
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#A4A7A8',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    margin: 0,
    fontWeight: '400',
    paddingLeft: '36px',
  }),
  input: (styles: any) => ({
    ...styles,
    marginLeft: '12px',
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '4px 0 !important',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: 'white',
    },
  }),
  menu: () => ({ marginTop: '16px', boxShadow: 'none', height: '160px' }),
  menuList: (provided: any) => ({ ...provided, height: '160px' }),
};

export const multiselectStyles = {
  ...selectDefaultStyles,
  valueContainer: (styles: any) => ({
    ...styles,
    padding: '7px 20px',
  }),
  multiValue: (styles: any) => ({
    ...styles,
    backgroundColor: '#A4A7A8',
    borderRadius: '8px',
    padding: '1px 4px',
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontFamily: 'Inter, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    color: 'white',
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    '&:hover': {
      backgroundColor: '#A4A7A8',
    },
  }),
  noOptionsMessage: (base: any) => ({
    ...base,
    height: '100%',
  }),
};

export const tableSelectStyles = {
  container: (styles: any) => ({
    ...styles,
    height: '100%',
  }),
  placeholder: (styles: any, { isDisabled }: any) =>
    ({
      fontFamily: 'Inter, sans-serif',
      height: '100%',
      fontSize: '1.125rem',
      fontWeight: '400',
      color: !isDisabled ? '#323839' : '#A4A7A8',
      outline: 'none',
      margin: 0,
      marginRight: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
    } as any),
  control: (styles: any, { menuIsOpen }: any) => ({
    ...styles,
    cursor: 'pointer',
    border: 'none',
    height: '100%',
    justifyContent: 'flex-start',
    boxShadow: menuIsOpen
      ? '0px 2px 18px rgba(0, 0, 0, 0.1), 0 -10px 0 white'
      : 'none',
    backgroundColor: menuIsOpen ? 'white' : 'transparent',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderBottomLeftRadius: menuIsOpen ? '0px' : '8px',
    borderBottomRightRadius: menuIsOpen ? '0px' : '8px',
    ':hover': {
      border: 'none',
    },
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.125rem',
    fontWeight: '400',
    color: '#323839',
    outline: 'none',
    flexWrap: 'nowrap',
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: 'none',
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
  }),
  singleValue: () =>
    ({
      fontFamily: 'Inter, sans-serif',
      fontSize: '1.125rem',
      fontWeight: '400',
      height: '100%',
      color: '#323839',
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
    } as any),
  option: (styles: any, { isDisabled }: any) => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexDirection: 'row',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: '#fff',
    ':hover': {
      color: isDisabled ? '#A4A7A8' : '#323839',
      backgroundColor: '#F6F6F6',
    },
    padding: '8px 12px 8px 28px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '1.125rem',
    fontWeight: '400',
    color: isDisabled ? '#A4A7A8' : '#323839',
    margin: 0,
    textAlign: 'center',
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    padding: '4px',
  }),
  menu: (styles: any) => ({
    ...styles,
    marginTop: '0px',
    marginBottom: 0,
    boxShadow: 'none',
    zIndex: 1000,
  }),
  menuList: (styles: any) => ({
    ...styles,
    backgroundColor: '#fff',
    padding: '20px 0',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    boxShadow: '0 -10px 0 white, 0px 2px 18px rgba(0, 0, 0, 0.1)',
    borderTop: '1px solid #DEDFDF',
    margin: 0,
  }),
};

export const confirmationModalStyles: Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(50, 56, 57, 0.3)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    minWidth: '310px',
    borderRadius: '8px',
  },
};

export const modalStyles: Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(50, 56, 57, 0.3)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    overflow: 'auto',
    maxHeight: '100%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    width: 'auto',
    borderRadius: '8px',
    boxShadow: '0px 2px 18px rgba(0, 0, 0, 0.1)',
  },
};

export const scalesModalStyles: Styles = {
  content: { width: '620px' },
};

export const avatarDropzoneStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '288px',
  height: '288px',
  alignItems: 'center',
  paddingTop: '62px',
  borderWidth: 2,
  borderRadius: '144px',
  borderColor: '#A4A7A8',
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  outline: 'none',
  cursor: 'pointer',
};

export const fileDropzoneStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '80px',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 1,
  borderRadius: '8px',
  borderColor: '#A4A7A8',
  borderStyle: 'dashed',
  borderDasharray: '8px 12px',
  backgroundColor: '#fff',
  outline: 'none',
  cursor: 'pointer',
};

export const dropzoneFocusedStyle = {
  borderColor: '#42CDDD',
};

export const dropzoneAcceptStyle = {
  borderColor: '#5EB250',
};

export const dropzoneRejectStyle = {
  borderColor: '#EA1F4C',
};

export const disableStyleRepOrProtoc = 'opacity-50';
export const dataTipDisableReport =
  'The report will be available when the TOS is signed.';
export const dataTipDisableProtocol =
  'The protocol will be available when the TOS is signed.';
