import { useCallback, useContext } from 'react';

import { ClientsPageContext } from '../context/ClientsPageContext';
import { EegLabUserBraincareFilterContext } from '../context/EegLabUserBraincareFiltersContext';
import { EegLabUserFilterContext } from '../context/EegLabUserFiltersContext';
import { EegLabUserNeurorefFilterContext } from '../context/EegLabUserNeurorefFiltersContext';
import { ManagePatientsTableContext } from '../context/ManagePatientsTableContext';
import { OrganizationsPageContext } from '../context/OrganizationsPageContext';
import { PPMDownloadsHistoryTableContext } from '../context/PPMDownloadsHistoryTableContext';
import { PatientProfileTableContext } from '../context/PatientProfileTableContext';
import { TosTableContext } from '../context/TosTableContext';
import { WnAdminHomeProviderContext } from '../context/WnAdminHomeTableContext';

export const useClearTablesData = (): { clearTablesData: () => void } => {
  const { clearWnAdminHomeTableContextState } = useContext(
    WnAdminHomeProviderContext
  );
  const { clearTosTableContextState } = useContext(TosTableContext);
  const { clearPatientsTableContextState } = useContext(
    ManagePatientsTableContext
  );
  const { clearPatientProfileTableContextState } = useContext(
    PatientProfileTableContext
  );
  const { clearEegHomeTableContextState } = useContext(EegLabUserFilterContext);

  const { clearEegHomeBraincareTableContextState } = useContext(
    EegLabUserBraincareFilterContext
  );

  const { clearEegHomeNeurorefTableContextState } = useContext(
    EegLabUserNeurorefFilterContext
  );

  const { clearPPMDownloadsHistoryTableContextState } = useContext(
    PPMDownloadsHistoryTableContext
  );

  const { clearClientsPageContextState } = useContext(ClientsPageContext);

  const { clearOrganizationsPageContextState } = useContext(
    OrganizationsPageContext
  );

  const clearTablesData = useCallback(() => {
    clearWnAdminHomeTableContextState();
    clearTosTableContextState();
    clearPatientsTableContextState();
    clearPatientProfileTableContextState();
    clearEegHomeTableContextState();
    clearEegHomeBraincareTableContextState();
    clearEegHomeNeurorefTableContextState();
    clearPPMDownloadsHistoryTableContextState();
    clearClientsPageContextState();
    clearOrganizationsPageContextState();
  }, [
    clearEegHomeTableContextState,
    clearEegHomeBraincareTableContextState,
    clearEegHomeNeurorefTableContextState,
    clearPatientProfileTableContextState,
    clearPatientsTableContextState,
    clearTosTableContextState,
    clearWnAdminHomeTableContextState,
    clearPPMDownloadsHistoryTableContextState,
    clearClientsPageContextState,
    clearOrganizationsPageContextState,
  ]);

  return { clearTablesData };
};
