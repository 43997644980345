import {
  PatientFiltersCommonValues,
  ProtocolStatus,
  ReportStatus,
} from '../types';

export const serializePatientProfileTableParams = (
  search: string | null,
  filters: PatientFiltersCommonValues,
  sortFields?: { created_at: string }
) => {
  const res = [];
  if (search) {
    res.push(`search=${search}`);
  }
  if (filters.protocolUpdated) {
    res.push(`protocol_status=${ProtocolStatus.UPDATED}`);
  }
  if (filters.protocolApproved) {
    res.push(`protocol_status=${ProtocolStatus.APPROVED}`);
  }
  if (filters.protocolNotSpecified) {
    res.push(`protocol_status=${ProtocolStatus.NOT_SPECIFIED}`);
  }
  if (filters.protocolBroken) {
    res.push(`protocol_status=${ProtocolStatus.BROKEN}`);
  }
  if (filters.brokenReport) {
    res.push(`report_status=${ReportStatus.BROKEN}`);
  }
  if (filters.approved) {
    res.push(`report_status=${ReportStatus.APPROVED}`);
  }
  if (filters.rejected) {
    res.push(`report_status=${ReportStatus.REJECTED}`);
  }
  if (filters.delayed) {
    res.push(`report_status=${ReportStatus.DELAYED}`);
  }
  if (filters.archived) {
    res.push(`is_archived=${filters.archived}`);
  }
  if (sortFields?.created_at) {
    res.push(`sort=${sortFields.created_at}`);
  }

  return res.join('&');
};
