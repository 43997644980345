import React, { useContext } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../constants';
import { AuthContext } from '../context/AuthContext';
import ErrorHandler from './ErrorHandler';
import { ReleaseNotes } from './ReleaseNotes';
import RoutesManager from './RoutesManager';
import SidebarMenu from './SidebarMenu/SidebarMenu';

Modal.setAppElement('#root');

const App: React.FunctionComponent = (): JSX.Element => {
  const authContext = useContext(AuthContext);

  const { isAuthenticated } = authContext;
  const location = useLocation();

  const showContentOnly = [
    ROUTES.BRAINCARE_REPORT_PREVIEW,
    ROUTES.PDF_PREVIEW,
    ROUTES.SCALE_COMPARISON,
    ROUTES.SCALE_VIEW_DETAILS,
    ROUTES.INVITATION_WELCOME,
  ].includes(location.pathname);

  return (
    <>
      <ErrorHandler />
      <ReleaseNotes />
      <main className="h-screen w-full flex flex-row">
        {isAuthenticated && !showContentOnly && <SidebarMenu />}
        <section className="w-full h-screen relative">
          <div
            className={`flex flex-col w-full h-screen ${
              isAuthenticated && !showContentOnly ? 'pl-105px' : ''
            }`}
          >
            <RoutesManager />
          </div>
        </section>
      </main>
    </>
  );
};

export default App;
