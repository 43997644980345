import React, { FC } from 'react';

import NotFoundIcon from '../../assets/icons/NotFoundIcon';
import PageContainer from '../../components/PageContainer';

const NotFound: FC = (): JSX.Element => (
  <PageContainer title="Not Found">
    <div className="w-full flex flex-col">
      <span className="mb-9 font-inter text-outer-space font-normal text-4xl">
        Page not found
      </span>
      <div className="flex flex-1 items-center justify-center">
        <NotFoundIcon />
      </div>
    </div>
  </PageContainer>
);

export default NotFound;
