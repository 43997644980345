import React from 'react';

const SideBarLogo: React.FunctionComponent = () => (
  <svg
    width="57"
    height="26"
    viewBox="0 0 42 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10218_39388)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.403 7.97448C25.6757 6.66765 24.9257 5.33451 24.0557 4.27725C23.1984 3.23542 22.2374 2.46222 20.9992 2.46222C19.761 2.46222 18.7992 3.23724 17.9428 4.27725C16.4073 6.14311 15.2364 8.86475 13.9427 10.7914C12.8991 12.3496 11.7281 13.396 10.3972 13.514C9.47262 13.5938 8.41896 13.259 7.22984 12.2171C7.14116 12.1432 7.0682 12.0522 7.01525 11.9497C6.9623 11.8472 6.93044 11.7351 6.92153 11.6201C6.91261 11.5051 6.92684 11.3895 6.96337 11.28C6.99989 11.1706 7.05798 11.0696 7.13421 10.9829C7.21044 10.8962 7.30327 10.8256 7.40724 10.7753C7.51121 10.7249 7.62422 10.6958 7.73962 10.6898C7.85501 10.6837 7.97046 10.7007 8.07916 10.7398C8.18787 10.7789 8.28763 10.8394 8.37259 10.9176C9.05351 11.5147 9.62716 11.8069 10.1644 11.7951C10.7636 11.7824 11.2554 11.3849 11.7436 10.8386C12.38 10.1289 12.9545 9.1597 13.5418 8.11605C14.6436 6.16398 15.7718 3.97596 17.1846 2.5321C18.2664 1.42583 19.5156 0.733398 21.0029 0.733398C22.4902 0.733398 23.7357 1.42583 24.8211 2.5321C25.8284 3.56213 26.6912 4.9706 27.503 6.40175L27.2057 6.81649L26.403 7.97448ZM28.403 8.02076L28.4558 8.11151C29.043 9.15425 29.6221 10.1235 30.254 10.8341C30.7403 11.3786 31.234 11.7779 31.8331 11.7906C32.3704 11.8024 32.944 11.5102 33.625 10.913C33.7985 10.7683 34.0218 10.697 34.2473 10.7144C34.4727 10.7318 34.6824 10.8365 34.8316 11.0062C34.9807 11.1758 35.0576 11.3969 35.0456 11.6224C35.0337 11.8478 34.934 12.0596 34.7677 12.2126C33.5786 13.2544 32.524 13.5893 31.6004 13.5094C30.2667 13.3951 29.0949 12.3487 28.0548 10.7869C27.8039 10.4139 27.5585 10.01 27.3139 9.58986L27.9157 8.72227L28.403 8.02076Z"
        fill="#FFE06A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5528 7.86833C19.4892 10.6617 21.5056 13.5666 22.462 14.9333C23.5693 16.517 25.1239 17.331 26.6885 17.3319C28.2531 17.3328 29.8049 16.5152 30.9077 14.9352C33.015 11.9059 40.477 1.1409 40.477 1.1409C40.604 0.95906 40.7983 0.835056 41.0169 0.796167C41.2356 0.757278 41.4607 0.80669 41.6429 0.933533C41.8251 1.06038 41.9493 1.25426 41.9882 1.47253C42.0272 1.6908 41.9777 1.91558 41.8506 2.09742C41.8506 2.09742 34.3868 12.8597 32.2795 15.8917C30.8249 17.979 28.7513 19.0054 26.6876 19.0045C24.6239 19.0036 22.5502 17.979 21.092 15.8917C20.212 14.6339 18.4347 12.0738 16.6492 9.49732C16.9455 8.95644 17.2437 8.40739 17.5528 7.86833ZM15.5946 7.97451C13.8964 5.52602 12.3509 3.29807 11.7691 2.45226H0.834284C0.617592 2.44512 0.412166 2.35418 0.261408 2.19863C0.11065 2.04309 0.0263672 1.83514 0.0263672 1.61871C0.0263672 1.40228 0.11065 1.19432 0.261408 1.03878C0.412166 0.88324 0.617592 0.792292 0.834284 0.785154H12.2063C12.3418 0.785433 12.4752 0.818628 12.5949 0.881874C12.7147 0.945119 12.8172 1.03651 12.8936 1.14816C12.8936 1.14816 14.5428 3.52676 16.511 6.36276C16.1973 6.89275 15.8964 7.43635 15.5946 7.97451Z"
        fill="#E21142"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2297 2.45314C28.9205 4.34531 22.8104 13.1618 20.9067 15.8844C19.4521 17.9717 17.373 18.9945 15.3102 18.9954C13.2475 18.9963 11.1738 17.9735 9.71921 15.8826C7.61189 12.856 0.14809 2.09739 0.14809 2.09739C0.0215067 1.91555 -0.0275296 1.69096 0.0117686 1.47303C0.0510669 1.2551 0.175481 1.06168 0.35764 0.935318C0.539799 0.808957 0.764783 0.760007 0.983097 0.799236C1.20141 0.838465 1.39517 0.962661 1.52176 1.1445C1.52176 1.1445 8.98374 11.9058 11.0911 14.9388C12.1938 16.5242 13.7457 17.3355 15.3102 17.3346C16.8748 17.3337 18.4294 16.5178 19.5367 14.9369C21.6722 11.8786 29.1051 1.1445 29.1051 1.1445C29.1816 1.03296 29.2842 0.941654 29.4039 0.878422C29.5236 0.81519 29.6569 0.781928 29.7924 0.781494H41.1644C41.386 0.781494 41.5985 0.869363 41.7552 1.02577C41.9119 1.18218 41.9999 1.39431 41.9999 1.6155C41.9999 1.8367 41.9119 2.04883 41.7552 2.20524C41.5985 2.36164 41.386 2.44951 41.1644 2.44951L30.2297 2.45314Z"
        fill="#62C4E3"
      />
    </g>
    <defs>
      <clipPath id="clip0_10218_39388">
        <rect
          width="42"
          height="18.2692"
          fill="white"
          transform="translate(0 0.730713)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SideBarLogo;
