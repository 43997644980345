import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

export const BraincareReportContext = createContext({
  isDataLoading: true,
  handleSetIsDataLoading: (flag: boolean) => {},
});

const BraincareReportProvider: FC<{ children: ReactNode }> = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { children } = props;

  const handleSetIsDataLoading = useCallback((flag: boolean) => {
    setIsDataLoading(() => flag);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isDataLoading,
      handleSetIsDataLoading,
    }),
    [isDataLoading, handleSetIsDataLoading]
  );

  return (
    <BraincareReportContext.Provider value={memoizedValue}>
      {children}
    </BraincareReportContext.Provider>
  );
};

export default BraincareReportProvider;
