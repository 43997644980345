import React, { ChangeEventHandler, useMemo, useState } from 'react';

import { userTableFiltersInitialData } from '../constants/table';
import { usePageData } from '../hooks/usePageData';
import { UserTableFiltersData } from '../types';
import { serializeQueryString } from '../utils/serializeQueryString';

export interface TosTableContextProps {
  queryString: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  setSearchTerm: (v: string) => void;
  totalPageCount: number;
  setTotalPageCount: (pageCount: number) => void;
  hasNext: boolean;
  setHasNext: (value: boolean) => void;
  hasPrev: boolean;
  setHasPrev: (value: boolean) => void;
  pageData: TosTablePageData;
  setPageData: (v: TosTablePageData) => void;
  clearTosTableContextState: () => void;
}

export type TosTablePageData = {
  searchTerm: string | null;
  filters: UserTableFiltersData;
  currentPage: number;
  sortFields: {
    first_name: string;
  };
};

export const TosTableContext = React.createContext<TosTableContextProps>({
  queryString: '',
  handleChange: () => undefined,
  totalPageCount: 0,
  setTotalPageCount: () => undefined,
  hasNext: false,
  setHasNext: () => undefined,
  setSearchTerm: () => undefined,
  hasPrev: false,
  setHasPrev: () => undefined,
  pageData: {
    searchTerm: null,
    filters: userTableFiltersInitialData,
    currentPage: 1,
    sortFields: {
      first_name: '',
    },
  },
  setPageData: () => undefined,
  clearTosTableContextState: () => undefined,
});

interface TosTableProps {
  children: React.ReactNode;
}

const TosTableProvider: React.FunctionComponent<TosTableProps> = (props) => {
  const [pageData, setPageData] = useState<TosTablePageData>({
    searchTerm: null,
    filters: userTableFiltersInitialData,
    currentPage: 1,
    sortFields: {
      first_name: '',
    },
  });

  const {
    totalPageCount,
    setTotalPageCount,
    hasNext,
    hasPrev,
    setHasNext,
    setHasPrev,
    handleChange,
    setSearchTerm,
  } = usePageData(pageData, setPageData);

  const queryString = useMemo(
    () =>
      serializeQueryString({
        search: pageData.searchTerm,
        filters: pageData.filters,
        sortFields: pageData.sortFields,
      }),
    [pageData.searchTerm, pageData.filters, pageData.sortFields]
  );

  const clearTosTableContextState = () => {
    setTotalPageCount(0);
    setHasNext(false);
    setHasPrev(false);
    setPageData({
      searchTerm: null,
      filters: userTableFiltersInitialData,
      currentPage: 1,
      sortFields: {
        first_name: '',
      },
    });
  };

  const { children } = props;

  return (
    <TosTableContext.Provider
      value={{
        setSearchTerm,
        queryString,
        handleChange,
        totalPageCount,
        setTotalPageCount,
        hasNext,
        setHasNext,
        hasPrev,
        setHasPrev,
        pageData,
        setPageData,
        clearTosTableContextState,
      }}
    >
      {children}
    </TosTableContext.Provider>
  );
};

export default TosTableProvider;
