import { UserStatus, UserTableFiltersData, UserRole } from '../types';

export const serializeQueryString = (params: {
  search: string | null;
  filters: UserTableFiltersData;
  sortType?: string;
  sortFields?: { first_name: string };
  useMore?: boolean;
}) => {
  const { search, filters, sortType, sortFields, useMore } = params ?? {};

  const res = [];
  if (search) {
    res.push(`search=${search}`);
  }
  if (filters.active) {
    res.push(`is_active=true`);
  }
  if (filters.inactive) {
    res.push(`is_active=false`);
  }
  if (filters.rejected) {
    res.push(`status=${UserStatus.REJECTED}`);
  }
  if (filters.pendingApproval) {
    res.push(`status=${UserStatus.PENDING_APPROVAL}`);
  }
  if (filters.approved) {
    res.push(`status=${UserStatus.APPROVED}`);
  }
  if (useMore) {
    res.push(`per_page=50`);
  }

  // sorting
  if (sortType) {
    res.push(`sort=username:${sortType}`);
  }
  if (sortFields?.first_name) {
    res.push(
      `sort=${sortFields.first_name.includes('-') ? '-' : ''}last_name,${
        sortFields.first_name
      }`
    );
  }

  // roles
  if (filters.storeAdmin) {
    res.push(`role=${UserRole.STORE_ADMIN}`);
  }
  if (filters.storeUser) {
    res.push(`role=${UserRole.STORE_USER}`);
  }
  if (filters.executive) {
    res.push(`role=${UserRole.EXECUTIVE}`);
  }
  if (filters.wnSales) {
    res.push(`role=${UserRole.WN_SALES}`);
  }
  if (filters.wnAdmin) {
    res.push(`role=${UserRole.WN_ADMIN}`);
  }
  if (filters.eegLabUser) {
    res.push(`role=${UserRole.EEG_LAB_USER}`);
  }
  filters.organizations.forEach((org) => {
    res.push(`org_id=${org.value}`);
  });
  return res.join('&');
};
