import React from 'react';
import Loader from 'react-loader-spinner';

import { ButtonTypes } from '../../../../types';

interface CommonButtonProps {
  type: ButtonTypes;
  text: string;
  disabled?: boolean;
  loaderColor?: string;
  fetching?: boolean;
  customStyles?: string;
  onClick?: () => void;
  font?: string;
  icon?: React.ReactNode;
  reverse?: boolean;
}

const CommonButton: React.FunctionComponent<CommonButtonProps> = ({
  type,
  text,
  disabled = false,
  loaderColor = '#323839',
  fetching = false,
  customStyles = '',
  onClick,
  font = 'text-lg',
  icon,
  reverse = false,
}) => {
  const isFilledButton = customStyles?.split(' ').includes('bg-tarquoise-blue');

  const disabledStyles = {
    backgroundColor: isFilledButton ? '#DEDFDF' : '#FDFDFD',
    borderColor: isFilledButton ? '' : '#DEDFDF',
    color: '#A4A7A8',
  };

  return (
    <button
      disabled={disabled}
      className={`rounded-lg flex items-center justify-center focus:outline-none py-17px outline-none leading-22px ${font} ${customStyles} ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      type={type}
      onClick={onClick}
      style={disabled ? disabledStyles : {}}
    >
      {reverse ? (
        <>
          {!fetching ? (
            text
          ) : (
            <Loader type="Oval" color={loaderColor} height={24} width={24} />
          )}
          {icon && <div className="ml-1.5">{icon}</div>}
        </>
      ) : (
        <>
          {icon && <div className="mr-1.5">{icon}</div>}
          {!fetching ? (
            text
          ) : (
            <Loader type="Oval" color={loaderColor} height={24} width={24} />
          )}
        </>
      )}
    </button>
  );
};

export default CommonButton;
