import React, {
  FC,
  useMemo,
  useState,
  createContext,
  useCallback,
} from 'react';

import { organizationsTableFiltersInitialData } from '../constants/table';
import { usePageData } from '../hooks/wnAdmin/usePageData';
import {
  OrganizationsContextProps,
  OrganizationsTablePageDataType,
  ProviderProps,
} from '../types';
import { serializeOrganizationsQueryString } from '../utils/serializeOrganizationsQueryString';

export const OrganizationsPageContext = createContext<OrganizationsContextProps>(
  {
    queryString: '',
    handleInputSearchChange: () => undefined,
    handleInputSearchClick: () => undefined,
    handleFilters: () => undefined,
    pageData: {
      searchTerm: null,
      searchInputValue: '',
      filters: organizationsTableFiltersInitialData,
    },
    setPageData: () => undefined,
    clearOrganizationsPageContextState: () => undefined,
  }
);

const WnAdminOrganizationsProvider: FC<ProviderProps> = (props) => {
  const [pageData, setPageData] = useState<OrganizationsTablePageDataType>({
    searchTerm: null,
    searchInputValue: '',
    filters: organizationsTableFiltersInitialData,
  });

  const {
    handleFilters,
    handleInputSearchChange,
    handleInputSearchClick,
  } = usePageData(pageData, setPageData);

  const queryString = useMemo(
    () =>
      serializeOrganizationsQueryString({
        search: pageData.searchTerm,
        filters: pageData.filters,
      }),
    [pageData.searchTerm, pageData.filters]
  );
  const clearOrganizationsPageContextState = useCallback(() => {
    setPageData({
      searchTerm: null,
      searchInputValue: '',
      filters: organizationsTableFiltersInitialData,
    });
  }, []);

  const { children } = props;

  const memoizedValue = useMemo(
    () => ({
      handleFilters,
      handleInputSearchChange,
      handleInputSearchClick,
      queryString,
      pageData,
      setPageData,
      clearOrganizationsPageContextState,
    }),
    [
      handleFilters,
      handleInputSearchChange,
      handleInputSearchClick,
      queryString,
      pageData,
      setPageData,
      clearOrganizationsPageContextState,
    ]
  );

  return (
    <OrganizationsPageContext.Provider value={memoizedValue}>
      {children}
    </OrganizationsPageContext.Provider>
  );
};

export default WnAdminOrganizationsProvider;
