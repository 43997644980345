export const API = {
  SYSTEM_USERS: '/manage/users',
  CLIENTS: '/manage/clients',
  ORGANIZATIONS: '/manage/orgs',
  BRAINCARE_REPORTS: '/braincare_reports',
  NEUROREF_REPORTS: '/neuroref',
  REPORTS: '/eegs',
  PPMS: '/nest/ppm_history',
};

export const FILTERS_QUERY_PARAMS = {
  WAITING_FOR_SIGNATURE: 'WAITING_SIGNATURE',
  SIGNED: 'SIGNED',
  NOT_SIGNED: 'NOT_SIGNED',
};
