import { UserRole } from 'types';

import emailsLayoutContent from './media/emailsLayout.webp';
import searchSyncContent from './media/searchSync.gif';

type ReleaseContentType = {
  media?: string;
  mediaType: string;
  title: string;
  description: string | string[] | (() => JSX.Element);
};

const searchSyncContentUpdate = {
  media: searchSyncContent,
  mediaType: 'img',
  title: 'Logic of choosing the Protocol for a treatment',
  description:
    'The system allows users to conduct searches by saving search requests based on client information such as name, surname, client ID, or EEG file name, enabling seamless navigation between Braincare report, Neuroref report, and Protocols tabs while preserving search criteria.',
};

const emailsLayoutContentUpdate = {
  media: emailsLayoutContent,
  mediaType: 'img',
  title: 'Updated emails layout',
  description:
    "In this update, we've transformed email layouts for a sleeker look and feel, ensuring adaptability across desktop and mobile devices. Enjoy a more responsive design, updated email texts, and an enhanced user experience.",
};

export const releaseContent: {
  [key: string]: ReleaseContentType[];
} = {
  [UserRole.WN_ADMIN]: [emailsLayoutContentUpdate],
  [UserRole.WN_SALES]: [emailsLayoutContentUpdate],
  [UserRole.STORE_ADMIN]: [emailsLayoutContentUpdate],
  [UserRole.STORE_USER]: [emailsLayoutContentUpdate],
  [UserRole.EEG_LAB_USER]: [searchSyncContentUpdate, emailsLayoutContentUpdate],
  [UserRole.CLIENT]: [emailsLayoutContentUpdate],
};
