import React, { useContext } from 'react';

import packageJson from '../../../package.json';
import { ReleaseNotesContext } from '../../context/ReleaseNotesContext';

export const SidebarMenuVersionInfo = () => {
  const { showReleaseNotes } = useContext(ReleaseNotesContext);

  return (
    <button
      onClick={() => {
        showReleaseNotes();
      }}
      className="flex items-center flex-col py-3 font-inter text-nevada text-xs text-center w-full cursor-pointer"
    >
      <span>Version:</span>
      {packageJson.version}
    </button>
  );
};
