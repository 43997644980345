import React from 'react';

interface ProfileIconProps {
  isActive?: boolean;
}

const ProfileIcon: React.FunctionComponent<ProfileIconProps> = ({
  isActive,
  children,
}): JSX.Element => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9998 12.5C14.2098 12.5 15.9998 10.71 15.9998 8.5C15.9998 6.29 14.2098 4.5 11.9998 4.5C9.78975 4.5 7.99976 6.29 7.99976 8.5C7.99976 10.71 9.78975 12.5 11.9998 12.5ZM11.9998 14.5C9.32975 14.5 3.99976 15.84 3.99976 18.5V20.5H19.9998V18.5C19.9998 15.84 14.6698 14.5 11.9998 14.5Z"
      fill={isActive ? '#FFDE6E' : '#FBFBFB'}
    />
  </svg>
);

export default ProfileIcon;
