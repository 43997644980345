import React, { FC } from 'react';

import PageTitle from './PageTitle';

interface PageContainerProps {
  title: string;
}

const PageContainer: FC<PageContainerProps> = ({
  title,
  children,
}): JSX.Element => (
  <>
    <PageTitle title={title} />
    <div className="w-full flex flex-col flex-1 px-12 pt-10 pb-12 relative">
      {children}
    </div>
  </>
);

export default PageContainer;
