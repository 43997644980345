import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import packageJson from '../package.json';
import App from './components/App';
import { IdleTimerProvider } from './components/IdleTimer';
import AuthProvider from './context/AuthContext';
import BraincareReportProvider from './context/BraincareReportContext';
import { ClientReportsProvider } from './context/ClientReportsTableContext';
import WnAdminClientsProvider from './context/ClientsPageContext';
import EegLabUserBraincareFilterProvider from './context/EegLabUserBraincareFiltersContext';
import EegLabUserFilterProvider from './context/EegLabUserFiltersContext';
import EegLabUserNeurorefFilterProvider from './context/EegLabUserNeurorefFiltersContext';
import GoogleMapAPIContextProvider from './context/GoogleMapAPIContext';
import ManagePatientsTableProvider from './context/ManagePatientsTableContext';
import WnAdminOrganizationsProvider from './context/OrganizationsPageContext';
import PPMDownloadsHistoryTableProvider from './context/PPMDownloadsHistoryTableContext';
import PatientProfileTableProvider from './context/PatientProfileTableContext';
import ReleaseNotesProvider from './context/ReleaseNotesContext';
import SessionHistoryProvider from './context/SessionHistoryContext';
import TosTableProvider from './context/TosTableContext';
import UserProvider from './context/UserContext';
import VideoSideMenuProvider from './context/VideoSideMenuContext';
import WnAdminHomeProvider from './context/WnAdminHomeTableContext';
import './index.css';

const env = process.env.REACT_APP_ENV ?? 'development';

if (['test', 'production'].includes(env)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
    release: `${packageJson.name}@${packageJson.version}`,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <ReleaseNotesProvider>
          <UserProvider>
            <VideoSideMenuProvider>
              <EegLabUserFilterProvider>
                <EegLabUserBraincareFilterProvider>
                  <EegLabUserNeurorefFilterProvider>
                    <ManagePatientsTableProvider>
                      <PatientProfileTableProvider>
                        <PPMDownloadsHistoryTableProvider>
                          <TosTableProvider>
                            <WnAdminOrganizationsProvider>
                              <WnAdminClientsProvider>
                                <WnAdminHomeProvider>
                                  <ClientReportsProvider>
                                    <SessionHistoryProvider>
                                      <BraincareReportProvider>
                                        <BrowserRouter>
                                          <IdleTimerProvider>
                                            <GoogleMapAPIContextProvider>
                                              <ToastContainer />
                                              <App />
                                            </GoogleMapAPIContextProvider>
                                          </IdleTimerProvider>
                                        </BrowserRouter>
                                      </BraincareReportProvider>
                                    </SessionHistoryProvider>
                                  </ClientReportsProvider>
                                </WnAdminHomeProvider>
                              </WnAdminClientsProvider>
                            </WnAdminOrganizationsProvider>
                          </TosTableProvider>
                        </PPMDownloadsHistoryTableProvider>
                      </PatientProfileTableProvider>
                    </ManagePatientsTableProvider>
                  </EegLabUserNeurorefFilterProvider>
                </EegLabUserBraincareFilterProvider>
              </EegLabUserFilterProvider>
            </VideoSideMenuProvider>
          </UserProvider>
        </ReleaseNotesProvider>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
