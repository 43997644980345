import React, { FC } from 'react';

import { SideMenuIconProps } from '../../types';

export const SideMenuClientsIcon: FC<SideMenuIconProps> = ({ isActive }) => (
  <svg
    width="22"
    height="15"
    viewBox="0 0 22 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="20.5"
      height="13.5"
      rx="1.25"
      fill={isActive ? '#FFDE6E' : '#FBFBFB'}
      stroke={isActive ? '#FFDE6E' : '#FBFBFB'}
      strokeWidth="1.5"
    />
    <path
      d="M6.65217 7.5C7.66109 7.5 8.47826 6.71688 8.47826 5.75C8.47826 4.78312 7.66109 4 6.65217 4C5.64326 4 4.82609 4.78312 4.82609 5.75C4.82609 6.71688 5.64326 7.5 6.65217 7.5ZM6.65217 8.375C5.43326 8.375 3 8.96125 3 10.125V11H10.3043V10.125C10.3043 8.96125 7.87109 8.375 6.65217 8.375Z"
      fill="#323839"
    />
    <rect x="11" y="5" width="8.34783" height="1" fill="#323839" />
    <rect x="11" y="7" width="6.26087" height="1" fill="#323839" />
    <rect x="11" y="9" width="5.21739" height="1" fill="#323839" />
  </svg>
);
