import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

interface PageTitleProps {
  title: string | undefined;
}

const PageTitle: FC<PageTitleProps> = ({ title }) => {
  const breadcrumbs = ['Wave Neuro'];

  if (title) breadcrumbs.unshift(title);

  return (
    <Helmet defer={false}>
      <title>{breadcrumbs.join(' | ')}</title>
    </Helmet>
  );
};

export default PageTitle;
