import { OrganizationsTableFiltersDataType } from '../types';

export const serializeOrganizationsQueryString = (params: {
  search: string | null;
  filters: OrganizationsTableFiltersDataType;
}) => {
  const { search, filters } = params ?? {};

  const res = [];

  if (search) {
    res.push(`search=${search}`);
  }

  filters.organizations.forEach((org) => {
    res.push(`org_id=${org.value}`);
  });

  return res.join('&');
};
