import React, {
  ChangeEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { eegUserFiltersInitialValues } from '../constants/table';
import { usePageData } from '../hooks/wnAdmin/usePageData';
import {
  EegLabUserTableState,
  EegUserPageFilters,
  ReprocessType,
} from '../types';
import { serializeEegLabUserTableParams } from '../utils/eegLabUser';

export interface EegLabUserFilterContextProps {
  queryString: string;
  handleInputSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleInputSearchClick: () => void;
  handleFilters: (values: EegUserPageFilters) => void;
  pageData: EegTablePageData;
  setPageData: (v: EegTablePageData) => void;
  clearEegHomeTableContextState: () => void;
  eegIdToReprocess: number | string;
  setReprocessEegId: (value: number | string) => void;
  reprocessType: ReprocessType | null;
  setReprocessType: (v: ReprocessType | null) => void;
  totalCount: number;
  setTotalCount: (total: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  userType: EegLabUserTableState;
  setUserType: (v: EegLabUserTableState) => void;
}

export type EegTablePageData = {
  searchTerm: string | null;
  searchInputValue: string;
  filters: EegUserPageFilters;
  currentPage: number;
  sortFields: {
    created_at: string;
    is_archived: boolean;
  };
};

export const EegLabUserFilterContext = React.createContext<EegLabUserFilterContextProps>(
  {
    queryString: '',
    handleInputSearchChange: () => undefined,
    handleInputSearchClick: () => undefined,
    handleFilters: () => undefined,
    pageData: {
      searchTerm: null,
      searchInputValue: '',
      filters: eegUserFiltersInitialValues,
      currentPage: 1,
      sortFields: {
        created_at: '',
        is_archived: false,
      },
    },
    totalCount: 0,
    setTotalCount: () => undefined,
    setPageData: () => undefined,
    clearEegHomeTableContextState: () => undefined,
    eegIdToReprocess: '',
    setReprocessEegId: () => undefined,
    reprocessType: null,
    setReprocessType: () => undefined,
    isLoading: false,
    setIsLoading: () => undefined,
    userType: EegLabUserTableState.NEUROREF,
    setUserType: () => undefined,
  }
);

interface EegLabUserFilterProviderProps {
  children: React.ReactNode;
}

const EegLabUserFilterProvider: React.FunctionComponent<EegLabUserFilterProviderProps> = (
  props
): JSX.Element => {
  const [pageData, setPageData] = useState<EegTablePageData>({
    searchTerm: null,
    searchInputValue: '',
    filters: eegUserFiltersInitialValues,
    currentPage: 1,
    sortFields: {
      created_at: '',
      is_archived: false,
    },
  });
  const [eegIdToReprocess, setEegIdToReprocess] = useState<number | string>('');
  const [reprocessType, setReprocessType] = useState<ReprocessType | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [userType, setUserType] = useState<EegLabUserTableState>(
    EegLabUserTableState.NEUROREF
  );

  const {
    handleInputSearchChange,
    handleInputSearchClick,
    handleFilters,
  } = usePageData(pageData, setPageData);

  const queryString = useMemo(
    () =>
      serializeEegLabUserTableParams(
        pageData.searchTerm,
        pageData.filters,
        pageData.sortFields
      ),
    [pageData.searchTerm, pageData.filters, pageData.sortFields]
  );

  const clearEegHomeTableContextState = useCallback(() => {
    setPageData({
      searchTerm: null,
      searchInputValue: '',
      filters: eegUserFiltersInitialValues,
      currentPage: 1,
      sortFields: {
        created_at: '',
        is_archived: false,
      },
    });
  }, []);

  const { children } = props;

  const memoizedValues = useMemo(
    () => ({
      queryString,
      handleInputSearchChange,
      handleInputSearchClick,
      handleFilters,
      pageData,
      setPageData,
      clearEegHomeTableContextState,
      eegIdToReprocess,
      setReprocessEegId: setEegIdToReprocess,
      reprocessType,
      setReprocessType,
      totalCount,
      setTotalCount,
      isLoading,
      setIsLoading,
      userType,
      setUserType,
    }),
    [
      queryString,
      handleInputSearchChange,
      handleInputSearchClick,
      handleFilters,
      pageData,
      setPageData,
      clearEegHomeTableContextState,
      eegIdToReprocess,
      setEegIdToReprocess,
      reprocessType,
      setReprocessType,
      totalCount,
      setTotalCount,
      isLoading,
      setIsLoading,
      userType,
      setUserType,
    ]
  );

  return (
    <EegLabUserFilterContext.Provider value={memoizedValues}>
      {children}
    </EegLabUserFilterContext.Provider>
  );
};

export default EegLabUserFilterProvider;
