import React, { FC } from 'react';

import { SideMenuIconProps } from '../../types';

export const SystemUsersIcon: FC<SideMenuIconProps> = ({
  isActive = false,
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1875 8.5C12.1875 10.4338 10.6213 12 8.6875 12C6.75375 12 5.1875 10.4338 5.1875 8.5C5.1875 6.56625 6.75375 5 8.6875 5C10.6213 5 12.1875 6.56625 12.1875 8.5ZM1.6875 17.25C1.6875 14.9225 6.35125 13.75 8.6875 13.75C10.1713 13.75 12.5939 14.2229 14.1643 15.1644C12.8105 15.6633 11.6875 16.4426 11.6875 17.5V19H1.6875V17.25Z"
      fill={isActive ? '#FFDE6E' : '#FBFBFB'}
    />
    <path
      d="M18.1875 13.5C19.7069 13.5 20.9375 12.2694 20.9375 10.75C20.9375 9.23062 19.7069 8 18.1875 8C16.6681 8 15.4375 9.23062 15.4375 10.75C15.4375 12.2694 16.6681 13.5 18.1875 13.5ZM18.1875 14.875C16.3519 14.875 12.6875 15.7962 12.6875 17.625V19H23.6875V17.625C23.6875 15.7962 20.0231 14.875 18.1875 14.875Z"
      fill={isActive ? '#FFDE6E' : '#FBFBFB'}
    />
  </svg>
);
