import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { errorMessages, errorTypes } from 'constants/errors';

import { SignInRoleType } from 'types';

import { removeUserData } from '../cognito/logout';
import { AuthContext } from '../context/AuthContext';
import { UserContext } from '../context/UserContext';
import { useClearTablesData } from '../hooks/useClearTablesData';
import { axiosInstance } from '../utils/api';

const ErrorHandler = () => {
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);

  const { roleType, setAuthenticated } = authContext;

  const { setUser } = userContext;

  const isWnUser = roleType === SignInRoleType.WN_USER;

  const { clearTablesData } = useClearTablesData();

  const history = useHistory();
  // to-do refactor showing error logic
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (res) => res,
      async (err) => {
        if (err.response) {
          if (
            (err.response.status === 400 &&
              (err.response.data.error?.startsWith(
                errorMessages.CLIENT_NOT_FOUND
              ) ||
                err.response.data.message === errorMessages.VALIDATION_ERRORS ||
                err.response.data.error === errorMessages.INVALID_CODE ||
                err.response.data.error_type === errorTypes.TOS_NOT_SIGNED)) ||
            (err.response.status === 422 &&
              (err.response.data.error ===
                errorMessages.BAD_USERNAME_OR_PASSWORD ||
                err.response.data.error ===
                  errorMessages.CONFIRMATION_CODE_IS_NOT_CORRECT ||
                err.response.data.error ===
                  errorMessages.TEMPORARY_PASSWORD_IS_NOT_CORRECT)) ||
            err.response.status === 408
          ) {
            toast.error(
              err?.response?.data?.error || err?.response?.data?.message
            );
          }
          if (err.response.status === 401)
            removeUserData(history, setAuthenticated, setUser, clearTablesData);
        }

        return Promise.reject(err);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [
    authContext,
    history,
    userContext,
    clearTablesData,
    isWnUser,
    setAuthenticated,
    setUser,
  ]);

  return null;
};

export default ErrorHandler;
