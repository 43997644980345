import { ClientReportsFilter, ReportStatus } from '../types';

export const serializeClientReportsTableParams = (
  search: string | null,
  filters: ClientReportsFilter,
  sortFields: {
    recording_date: string;
  }
) => {
  const res = [];
  if (search) {
    res.push(`search=${search}`);
  }
  if (filters.approved) {
    res.push(`status=${ReportStatus.APPROVED}`);
  }
  if (filters.rejected) {
    res.push(`status=${ReportStatus.REJECTED}`);
  }
  if (filters.broken) {
    res.push(`status=${ReportStatus.BROKEN}`);
  }
  if (filters.processing) {
    res.push(
      `status=${ReportStatus.READY_FOR_REVIEW}&status=${ReportStatus.DELAYED}`
    );
  }
  if (sortFields.recording_date) {
    res.push(`sort=${sortFields.recording_date}`);
  }
  return res.join('&');
};
