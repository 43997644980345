import React, { useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import LogOut from '../../assets/icons/LogOut';
import { logout } from '../../cognito/logout';
import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { useClearTablesData } from '../../hooks/useClearTablesData';

export const SidebarMenuLogOut = () => {
  const logoutRef = useRef(null);

  const userContext = useContext(UserContext);
  const authContext = useContext(AuthContext);

  const history = useHistory();

  const { clearTablesData } = useClearTablesData();

  return (
    <button
      ref={logoutRef}
      data-tip
      data-for="logout"
      onClick={() => logout(userContext, authContext, clearTablesData, history)}
      className="flex justify-center py-3 w-full cursor-pointer"
    >
      <LogOut />
      <ReactTooltip
        className="tooltip"
        id="logout"
        place="right"
        effect="solid"
      >
        Log Out
      </ReactTooltip>
    </button>
  );
};
