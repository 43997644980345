import React, {
  ChangeEventHandler,
  FC,
  useMemo,
  useState,
  createContext,
  ReactNode,
  useCallback,
} from 'react';

import { userTableFiltersInitialData } from '../constants/table';
import { usePageData } from '../hooks/wnAdmin/usePageData';
import { UserTableFiltersData } from '../types';
import { serializeQueryString } from '../utils/serializeQueryString';

export type WnHomeTablePageData = {
  searchTerm: string | null;
  searchInputValue: string;
  filters: UserTableFiltersData;
  sortType: string;
};

interface WnHomeTableProviderProps {
  children: ReactNode;
}

export interface WnAdminHomeTableContextProps {
  handleFilters: (values: UserTableFiltersData) => void;
  queryString: string;
  handleInputSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleInputSearchClick: () => void;
  pageData: WnHomeTablePageData;
  setPageData: (v: WnHomeTablePageData) => void;
  clearWnAdminHomeTableContextState: () => void;
}

export const WnAdminHomeProviderContext = createContext<WnAdminHomeTableContextProps>(
  {
    queryString: '',
    handleInputSearchChange: () => undefined,
    handleInputSearchClick: () => undefined,
    handleFilters: () => undefined,
    pageData: {
      searchTerm: null,
      searchInputValue: '',
      filters: userTableFiltersInitialData,
      sortType: 'asc',
    },
    setPageData: () => undefined,
    clearWnAdminHomeTableContextState: () => undefined,
  }
);

const WnAdminHomeProvider: FC<WnHomeTableProviderProps> = (props) => {
  const [pageData, setPageData] = useState<WnHomeTablePageData>({
    searchTerm: null,
    searchInputValue: '',
    filters: userTableFiltersInitialData,
    sortType: 'asc',
  });

  const {
    handleInputSearchChange,
    handleInputSearchClick,
    handleFilters,
  } = usePageData(pageData, setPageData);

  const queryString = useMemo(
    () =>
      serializeQueryString({
        search: pageData.searchTerm,
        filters: pageData.filters,
        sortType: pageData.sortType,
        useMore: true,
      }),
    [pageData.searchTerm, pageData.filters, pageData.sortType]
  );

  const clearWnAdminHomeTableContextState = useCallback(() => {
    setPageData({
      searchTerm: null,
      searchInputValue: '',
      filters: userTableFiltersInitialData,
      sortType: 'asc',
    });
  }, []);

  const { children } = props;

  const memoizedValue = useMemo(
    () => ({
      handleFilters,
      handleInputSearchChange,
      handleInputSearchClick,
      queryString,
      pageData,
      setPageData,
      clearWnAdminHomeTableContextState,
    }),
    [
      handleFilters,
      handleInputSearchChange,
      handleInputSearchClick,
      queryString,
      pageData,
      setPageData,
      clearWnAdminHomeTableContextState,
    ]
  );

  return (
    <WnAdminHomeProviderContext.Provider value={memoizedValue}>
      {children}
    </WnAdminHomeProviderContext.Provider>
  );
};

export default WnAdminHomeProvider;
