import React, { useMemo } from 'react';

import { Libraries, useLoadScript } from '@react-google-maps/api';

const libraries = ['places'] as Libraries;
const key = process.env.REACT_APP_GOOGLE_MAP_APIKEY ?? '';

export const GoogleMapAPIContext = React.createContext<any>({});

const GoogleMapAPIContextProvider: React.FunctionComponent<any> = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: key,
    libraries,
  });

  const GoogleMapAPIContextProviderData = useMemo(() => ({ isLoaded }), [
    isLoaded,
  ]);

  const { children } = props;

  return (
    <GoogleMapAPIContext.Provider value={GoogleMapAPIContextProviderData}>
      {children}
    </GoogleMapAPIContext.Provider>
  );
};

export default GoogleMapAPIContextProvider;
