import React, {
  ChangeEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { eegUserFiltersInitialValues } from '../constants/table';
import { usePageData } from '../hooks/wnAdmin/usePageData';
import { EegUserPageFilters, ReprocessType } from '../types';
import { serializeEegLabUserNeurorefTableParams } from '../utils/eegLabUserNeuroref';

export interface EegLabUserNeurorefFilterContextProps {
  queryString: string;
  handleInputSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleInputSearchClick: () => void;
  handleFilters: (values: EegUserPageFilters) => void;
  pageData: EegTablePageData;
  setPageData: (v: EegTablePageData) => void;
  clearEegHomeNeurorefTableContextState: () => void;
  eegIdToReprocess: number | string;
  setReprocessEegId: (value: number | string) => void;
  reprocessType: ReprocessType | null;
  setReprocessType: (v: ReprocessType | null) => void;
  totalCount: number;
  setTotalCount: (total: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export type EegTablePageData = {
  searchTerm: string | null;
  searchInputValue: string;
  filters: EegUserPageFilters;
  currentPage: number;
  sortFields: {
    created_at: string;
    is_archived: boolean;
  };
};

export const EegLabUserNeurorefFilterContext = React.createContext<EegLabUserNeurorefFilterContextProps>(
  {
    queryString: '',
    handleInputSearchChange: () => undefined,
    handleInputSearchClick: () => undefined,
    handleFilters: () => undefined,
    pageData: {
      searchTerm: null,
      searchInputValue: '',
      filters: eegUserFiltersInitialValues,
      currentPage: 1,
      sortFields: {
        created_at: '',
        is_archived: false,
      },
    },
    totalCount: 0,
    setTotalCount: () => undefined,
    setPageData: () => undefined,
    clearEegHomeNeurorefTableContextState: () => undefined,
    eegIdToReprocess: '',
    setReprocessEegId: () => undefined,
    reprocessType: null,
    setReprocessType: () => undefined,
    isLoading: false,
    setIsLoading: () => undefined,
  }
);

interface EegLabUserNeurorefFilterProviderProps {
  children: React.ReactNode;
}

const EegLabUserNeurorefFilterProvider: React.FunctionComponent<EegLabUserNeurorefFilterProviderProps> = (
  props
): JSX.Element => {
  const [pageData, setPageData] = useState<EegTablePageData>({
    searchTerm: null,
    searchInputValue: '',
    filters: eegUserFiltersInitialValues,
    currentPage: 1,
    sortFields: {
      created_at: '',
      is_archived: false,
    },
  });
  const [reprocessType, setReprocessType] = useState<ReprocessType | null>(
    null
  );
  const [eegIdToReprocess, setEegIdToReprocess] = useState<number | string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const queryString = useMemo(
    () =>
      serializeEegLabUserNeurorefTableParams(
        pageData.searchTerm,
        pageData.filters,
        pageData.sortFields
      ),
    [pageData.searchTerm, pageData.filters, pageData.sortFields]
  );

  const {
    handleInputSearchChange,
    handleInputSearchClick,
    handleFilters,
  } = usePageData(pageData, setPageData);

  const clearEegHomeNeurorefTableContextState = useCallback(() => {
    setPageData({
      searchTerm: null,
      searchInputValue: '',
      filters: eegUserFiltersInitialValues,
      currentPage: 1,
      sortFields: {
        created_at: '',
        is_archived: false,
      },
    });
  }, []);

  const { children } = props;

  const memoizedValues = useMemo(
    () => ({
      queryString,
      handleInputSearchChange,
      handleInputSearchClick,
      handleFilters,
      pageData,
      setPageData,
      clearEegHomeNeurorefTableContextState,
      eegIdToReprocess,
      setReprocessEegId: setEegIdToReprocess,
      reprocessType,
      setReprocessType,
      totalCount,
      setTotalCount,
      isLoading,
      setIsLoading,
    }),
    [
      queryString,
      handleInputSearchChange,
      handleInputSearchClick,
      handleFilters,
      pageData,
      setPageData,
      clearEegHomeNeurorefTableContextState,
      eegIdToReprocess,
      setEegIdToReprocess,
      reprocessType,
      setReprocessType,
      totalCount,
      setTotalCount,
      isLoading,
      setIsLoading,
    ]
  );

  return (
    <EegLabUserNeurorefFilterContext.Provider value={memoizedValues}>
      {children}
    </EegLabUserNeurorefFilterContext.Provider>
  );
};

export default EegLabUserNeurorefFilterProvider;
