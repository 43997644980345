import React, { useContext, useEffect, Suspense } from 'react';
import ReactGA from 'react-ga4';
import Loader from 'react-loader-spinner';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ROUTES, UNAUTHORIZED_ROUTES } from '../constants';
import { AuthContext } from '../context/AuthContext';
import { UserContext } from '../context/UserContext';
import NotFound from '../routes/NotFound';
import { RouteType } from '../types';
import { finishPendingRequests } from '../utils/api';
import { getRoleBasedRoutes } from '../utils/routing';

const RoutesManager = () => {
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);

  const location = useLocation();

  const { isAuthenticated } = authContext;
  const { user } = userContext;

  const routesList: RouteType[] = isAuthenticated
    ? getRoleBasedRoutes(user?.role)
    : UNAUTHORIZED_ROUTES;

  useEffect(
    () => () => {
      finishPendingRequests('RouteChange');
    },
    [location?.pathname]
  );

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  }, []);

  return (
    <Suspense
      fallback={
        <div className="w-full flex flex-col flex-1 justify-center items-center">
          <Loader type="Oval" color="#12141A" height={45} width={45} />
        </div>
      }
    >
      <Switch>
        {routesList.map((route) => {
          const { component, id, path, exact, title } = route;

          const Component = component;

          return (
            <Route
              key={id}
              path={path}
              exact={exact}
              render={() => <Component title={title} />}
            />
          );
        })}
        {isAuthenticated ? (
          <>
            <Route path={ROUTES.DEFAULT} exact>
              <Redirect to={ROUTES.HOME} />
            </Route>
            <Route path={ROUTES.SIGNIN} exact>
              <Redirect to={ROUTES.HOME} />
            </Route>
            <Route path={ROUTES.NOT_FOUND} component={NotFound} />
          </>
        ) : (
          <Route path={ROUTES.NOT_FOUND}>
            <Redirect to={ROUTES.SIGNIN} />
          </Route>
        )}
      </Switch>
    </Suspense>
  );
};

export default RoutesManager;
