import React, { lazy } from 'react';

import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns';

import { SystemSettingsPageIcon } from 'assets/icons/SettingsPageIcon';

import { BuildingIcon } from '../assets/icons/BuildingIcon';
import HomeIcon from '../assets/icons/HomeIcon';
import PPMDonwloadsHistoryIcon from '../assets/icons/PPMDownloadsHistoryIcon';
import ProfileIcon from '../assets/icons/ProfileIcon';
import { SideMenuClientsIcon } from '../assets/icons/SideMenuClientsIcon';
import { SystemUsersIcon } from '../assets/icons/SystemUsersIcon';
import { TosListIcon } from '../assets/icons/TosListIcon';
import {
  BrainLocation,
  DateRangeOption,
  EegStatus,
  ReportStatus,
  Sex,
  UserStatus,
  TosStatus,
  UserRole,
} from '../types';

export const AUTH_USER_TOKEN_KEY = 'AUTH_USER_TOKEN_KEY';
export const AUTH_USER_ACCESS_TOKEN_KEY = 'AUTH_USER_ACCESS_TOKEN_KEY';
export const AUTH_USER_REFRESH_TOKEN_KEY = 'AUTH_USER_REFRESH_TOKEN_KEY';
export const AUTH_USER_ROLE_TYPE = 'AUTH_USER_ROLE_TYPE';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const USER = 'USER';
export const REMEMBER_ME_USERNAME = 'REMEMBER_ME_USERNAME';
export const USERS_SEEN_RELEASE_NOTES = 'USERS_SEEN_RELEASE_NOTES';

export const LOW_VOLTAGE_OPTIONS = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];

export const ROLES_OPTIONS = [
  { value: UserRole.WN_ADMIN, label: 'WN Admin' },
  { value: UserRole.WN_SALES, label: 'WN Sales' },
  { value: UserRole.STORE_ADMIN, label: 'Store Admin' },
  { value: UserRole.STORE_USER, label: 'Store User' },
  { value: UserRole.EEG_LAB_USER, label: 'EEG Lab User' },
  { value: UserRole.EXECUTIVE, label: 'Executive' },
];

export const STORE_ADMIN_ROLES_OPTIONS = [
  { value: UserRole.STORE_ADMIN, label: 'Store Admin' },
  { value: UserRole.STORE_USER, label: 'Store User' },
];

export const ROLES_VALUES = {
  [UserRole.WN_ADMIN]: 'WN Admin',
  [UserRole.WN_SALES]: 'WN Sales',
  [UserRole.STORE_ADMIN]: 'Store Admin',
  [UserRole.STORE_USER]: 'Store User',
  [UserRole.EEG_LAB_USER]: 'EEG Lab User',
  [UserRole.EXECUTIVE]: 'Executive',
  [UserRole.CLIENT]: 'Client',
};

export const SEX_OPTIONS = [
  { value: Sex.MALE, label: 'Male' },
  { value: Sex.FEMALE, label: 'Female' },
];

export const LOCATION_OPTIONS = [
  { value: BrainLocation.Fp1_Fpz_Fp2, label: BrainLocation.Fp1_Fpz_Fp2 },
  { value: BrainLocation.F1_Fz_F2, label: BrainLocation.F1_Fz_F2 },
  { value: BrainLocation.P1_Pz_P2, label: BrainLocation.P1_Pz_P2 },
  { value: BrainLocation.C1_Cz_C2, label: BrainLocation.C1_Cz_C2 },
  { value: BrainLocation.C2_C4_C6, label: BrainLocation.C2_C4_C6 },
  { value: BrainLocation.C3_CP3_P3, label: BrainLocation.C3_CP3_P3 },
  { value: BrainLocation.C4_CP4_P4, label: BrainLocation.C4_CP4_P4 },
  { value: BrainLocation.C5_C3_C1, label: BrainLocation.C5_C3_C1 },
  { value: BrainLocation.Cz_CPz_Pz, label: BrainLocation.Cz_CPz_Pz },
  { value: BrainLocation.O1_Oz_O2, label: BrainLocation.O1_Oz_O2 },
  { value: BrainLocation.F1_F3_F5, label: BrainLocation.F1_F3_F5 },
  { value: BrainLocation.F2_F4_F6, label: BrainLocation.F2_F4_F6 },
  { value: BrainLocation.F5_F3_F1, label: BrainLocation.F5_F3_F1 },
  { value: BrainLocation.F7_FT7_T3, label: BrainLocation.F7_FT7_T3 },
  { value: BrainLocation.F8_FT8_T4, label: BrainLocation.F8_FT8_T4 },
  { value: BrainLocation.FC1_FCz_FC2, label: BrainLocation.FC1_FCz_FC2 },
  { value: BrainLocation.FCz_Cz_CPz, label: BrainLocation.FCz_Cz_CPz },
  { value: BrainLocation.FT7_T3_TP7, label: BrainLocation.FT7_T3_TP7 },
  { value: BrainLocation.FT8_T4_TP8, label: BrainLocation.FT8_T4_TP8 },
  { value: BrainLocation.P1_P3_P5, label: BrainLocation.P1_P3_P5 },
  { value: BrainLocation.P2_P4_P6, label: BrainLocation.P2_P4_P6 },
  { value: BrainLocation.P5_PO3_O1, label: BrainLocation.P5_PO3_O1 },
  { value: BrainLocation.P6_PO4_O2, label: BrainLocation.P6_PO4_O2 },
  { value: BrainLocation.T3_CP5_P3, label: BrainLocation.T3_CP5_P3 },
  { value: BrainLocation.T4_CP6_P4, label: BrainLocation.T4_CP6_P4 },
  { value: BrainLocation.T7_P3, label: BrainLocation.T7_P3 },
];

const SignIn = lazy(() => import('../routes/SignIn'));
const ForgotPassword = lazy(() => import('../routes/ForgotPassword'));
const SetNewPassword = lazy(() => import('../routes/SetNewPassword'));
const Invitation = lazy(() => import('../routes/Invitation'));
const InvitationWelcome = lazy(() => import('../routes/InvitationWelcome'));
const ChangePassword = lazy(() => import('../routes/ChangePassword'));
const PatientChangeEmail = lazy(() => import('../routes/PatientChangeEmail'));
const WnAdminHome = lazy(() => import('../routes/WnAdminRoutes/Home'));
const OrganizationProfile = lazy(() => import('../routes/OrganizationProfile'));
const TosListPage = lazy(() => import('../routes/TosList'));
const ClientsPage = lazy(() => import('../routes/WnAdminRoutes/Clients'));
const MyProfile = lazy(() => import('../routes/MyProfile'));
const AddUser = lazy(() => import('../routes/AddUser'));
const EditUserProfile = lazy(
  () => import('../routes/WnAdminRoutes/UserProfile/EditUserProfile')
);
const SessionHistoryPage = lazy(() => import('../routes/SessionHistoryPage'));
const StoreAdminHome = lazy(() => import('../routes/StoreAdminRoutes/Home'));
const AddPatient = lazy(() => import('../routes/AddPatient'));
const ClientProfilePage = lazy(() => import('../routes/PatientProfile'));
const BraincareReportPage = lazy(
  () => import('../routes/Reports/BraincareReportPage')
);
const ScaleViewDetails = lazy(
  () => import('../components/PatientProfileScalesComponents/ScaleViewDetails')
);
const ScaleComparison = lazy(
  () => import('../components/PatientProfileScalesComponents/ScaleComparison')
);
const ExecutiveHome = lazy(() => import('../routes/ExecutiveRoutes/Home'));
const StoreUserAndWNSales = lazy(
  () => import('../routes/StoreUserAndWNSalesRoutes/Home')
);
const EegLabUserHome = lazy(() => import('../routes/EegLabUserRoutes/Home'));
const ClientHomePage = lazy(() => import('../routes/ClientRoutes/Home'));
const MyProfileClient = lazy(() => import('../routes/MyProfileClient'));
const PPMDownloadsHistory = lazy(() => import('../routes/PPMDownloadsHistory'));
const PDFViewComponent = lazy(() => import('../components/PDFViewComponent'));
const WNAdminSystemSettingsPage = lazy(
  () => import('routes/SettingsPage/WNAdminSettingsPage')
);
const OrganizationsPage = lazy(
  () => import('../routes/WnAdminRoutes/Organizations')
);
const WnAdminUserProfilePage = lazy(
  () => import('../routes/WnAdminRoutes/UserProfile')
);

export const ROUTES = {
  HOME: '/home',
  FORGOT_PASSWORD: '/forgot-password',
  SET_NEW_PASSWORD: '/set-new-password',
  SIGNIN: '/signin',
  INVITATION: '/shared/invitation',
  INVITATION_WELCOME: '/invitation-welcome',
  ADD_USER: '/add-user',
  ADD_PATIENT: '/add-client',
  USER_PROFILE: '/user-profile',
  PATIENT_PROFILE: '/client-profile',
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/change-password',
  CLIENTS: '/clients',
  TOS: '/tos',
  BRAINCARE_REPORT: '/braincare-report',
  BRAINCARE_REPORT_PREVIEW: '/braincare-report-preview',
  SCALE_VIEW_DETAILS: '/scale-view-details',
  SCALE_COMPARISON: '/scale-comparison',
  ORGANIZATION: '/organization',
  SESSION_HISTORY: '/session-history',
  PPM_DOWNLOADS_HISTORY: '/ppm-downloads-history',
  PDF_PREVIEW: '/pdf-preview',
  NOT_FOUND: '*',
  DEFAULT: '/',
  SYSTEM_SETTINGS: '/system-settings',
  SYSTEM_USERS: '/system-users',
  ORGANIZATIONS: '/organizations',
  EDIT_USER_PROFILE: '/edit-user-profile',
};

const ROUTE_COMPONENTS = {
  SIGNIN: {
    id: 'signin',
    path: ROUTES.SIGNIN,
    title: 'Sign In',
    exact: true,
    sidemenu: false,
    component: SignIn,
  },
  FORGOT_PASSWORD: {
    id: 'forgot-password',
    title: 'Forgot Password',
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    sidemenu: false,
    component: ForgotPassword,
  },
  SET_NEW_PASSWORD: {
    id: 'set-new-password',
    title: 'Set New Password',
    path: ROUTES.SET_NEW_PASSWORD,
    exact: true,
    sidemenu: false,
    component: SetNewPassword,
  },
  INVITATION: {
    id: 'invitation',
    title: 'Invitation',
    path: ROUTES.INVITATION,
    exact: true,
    sidemenu: false,
    component: Invitation,
  },
  INVITATION_WELCOME: {
    id: 'invitation-welcome',
    path: ROUTES.INVITATION_WELCOME,
    title: 'Invitation Welcome',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    icon: <ProfileIcon />,
    component: InvitationWelcome,
  },
  CHANGE_PASSWORD: {
    id: 'change-password',
    title: 'Change Password',
    path: ROUTES.CHANGE_PASSWORD,
    exact: true,
    sidemenu: false,
    component: ChangePassword,
  },
  PATIENT_CHANGE_EMAIL: {
    id: 'client-change-email',
    title: 'Client Change Email',
    path: '/patient/:id/change-email/:token',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    icon: <SystemUsersIcon />,
    component: PatientChangeEmail,
  },
  WNADMIN_HOME: {
    id: 'system-users',
    path: ROUTES.HOME,
    title: 'System Users',
    exact: true,
    sidemenu: true,
    icon: <SystemUsersIcon />,
    component: WnAdminHome,
  },
  ORGANIZATION_PROFILE: {
    id: 'organization-profile',
    path: `${ROUTES.ORGANIZATION}/:id`,
    title: 'Organization Profile',
    sidemenuParentId: 'home',
    exact: true,
    sidemenu: false,
    component: OrganizationProfile,
  },
  TOS_LIST: {
    id: 'clients',
    path: ROUTES.TOS,
    title: 'TOS',
    exact: true,
    sidemenu: true,
    icon: <TosListIcon />,
    component: TosListPage,
  },
  WN_ADMIN_CLIENTS: {
    id: 'clients',
    path: ROUTES.CLIENTS,
    title: 'Clients',
    exact: true,
    sidemenu: true,
    icon: <SideMenuClientsIcon />,
    component: ClientsPage,
  },
  MY_PROFILE: {
    id: 'myprofile',
    path: ROUTES.PROFILE,
    title: 'My Profile',
    exact: true,
    sidemenu: true,
    icon: <ProfileIcon />,
    component: MyProfile,
  },
  ADD_USER: {
    id: 'home-add-user',
    path: ROUTES.ADD_USER,
    title: 'Add User',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    component: AddUser,
  },
  WN_ADMIN_EDIT_USER_PROFILE: {
    id: 'edit-user-profile',
    path: `${ROUTES.EDIT_USER_PROFILE}/:id`,
    title: 'User Profile',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    component: EditUserProfile,
  },
  SESSION_HISTORY: {
    id: 'session-history',
    path: `${ROUTES.SESSION_HISTORY}/:id`,
    title: 'Session History',
    sidemenuParentId: 'home',
    exact: true,
    sidemenu: false,
    component: SessionHistoryPage,
  },
  STOREADMIN_HOME: {
    id: 'home',
    path: ROUTES.HOME,
    title: 'Home Page',
    exact: true,
    sidemenu: true,
    icon: <HomeIcon />,
    component: StoreAdminHome,
  },
  ADD_PATIENT: {
    id: 'home-add-client',
    path: ROUTES.ADD_PATIENT,
    title: 'Add Client',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    component: AddPatient,
  },
  PATIENT_PROFILE: {
    id: 'home-client-profile',
    path: `${ROUTES.PATIENT_PROFILE}/:id`,
    title: 'Client Profile',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    component: ClientProfilePage,
  },
  BRAINCARE_REPORT: {
    id: 'braincare-report',
    path: `${ROUTES.BRAINCARE_REPORT}/:id`,
    title: 'Braincare Report',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    component: BraincareReportPage,
  },
  SCALE_VIEW_DETAILS: {
    id: 'scale-view-details',
    path: ROUTES.SCALE_VIEW_DETAILS,
    title: 'Scale View Details',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    component: ScaleViewDetails,
  },
  SCALE_COMPARISON: {
    id: 'scale-comparison',
    path: ROUTES.SCALE_COMPARISON,
    title: 'Scale Comparison',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    component: ScaleComparison,
  },
  EXECUTIVE_HOME: {
    id: 'home',
    path: ROUTES.HOME,
    title: 'Home Page',
    exact: true,
    sidemenu: true,
    icon: <HomeIcon />,
    component: ExecutiveHome,
  },
  EXECUTIVE_PROFILE: {
    id: 'myprofile',
    path: ROUTES.PROFILE,
    title: 'My Profile',
    exact: true,
    sidemenu: true,
    icon: <ProfileIcon />,
    component: null,
  },
  STOREUSER_WNSALES_HOME: {
    id: 'home',
    path: ROUTES.HOME,
    title: 'Home Page',
    exact: true,
    sidemenu: true,
    icon: <HomeIcon />,
    component: StoreUserAndWNSales,
  },
  EEGLABUSER_HOME: {
    id: 'home',
    path: ROUTES.HOME,
    title: 'Home Page',
    exact: true,
    sidemenu: true,
    icon: <HomeIcon />,
    component: EegLabUserHome,
  },
  CLIENT_HOME: {
    id: 'home',
    path: ROUTES.HOME,
    title: 'Home Page',
    exact: true,
    sidemenu: true,
    icon: <HomeIcon />,
    component: ClientHomePage,
  },
  CLIENT_PROFILE: {
    id: 'myprofile',
    path: ROUTES.PROFILE,
    title: 'My Profile',
    exact: true,
    sidemenu: true,
    icon: <ProfileIcon />,
    component: MyProfileClient,
  },
  PPM_DOWNLOADS_HISTORY: {
    id: 'ppm-downloads-history',
    path: ROUTES.PPM_DOWNLOADS_HISTORY,
    title: 'PPM Downloads History',
    exact: true,
    sidemenu: true,
    icon: <PPMDonwloadsHistoryIcon />,
    component: PPMDownloadsHistory,
  },
  PDF_PREVIEW: {
    id: 'pdf-preview',
    path: ROUTES.PDF_PREVIEW,
    title: 'PDF Preview',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    component: PDFViewComponent,
  },
  SYSTEM_SETTINGS: {
    id: 'system-settings',
    path: ROUTES.SYSTEM_SETTINGS,
    title: 'System Settings',
    exact: true,
    sidemenu: true,
    icon: <SystemSettingsPageIcon />,
    component: WNAdminSystemSettingsPage,
  },
  ORGANIZATIONS: {
    id: 'organizations',
    path: ROUTES.ORGANIZATIONS,
    title: 'Organizations',
    exact: true,
    sidemenu: true,
    icon: <BuildingIcon />,
    component: OrganizationsPage,
  },
  WN_ADMIN_USER_PROFILE: {
    id: 'user-profile',
    path: `${ROUTES.USER_PROFILE}/:id`,
    title: 'User Profile',
    exact: true,
    sidemenu: false,
    sidemenuParentId: 'home',
    component: WnAdminUserProfilePage,
  },
};

export const UNAUTHORIZED_ROUTES = [
  ROUTE_COMPONENTS.SIGNIN,
  ROUTE_COMPONENTS.FORGOT_PASSWORD,
  ROUTE_COMPONENTS.SET_NEW_PASSWORD,
  ROUTE_COMPONENTS.CHANGE_PASSWORD,
  ROUTE_COMPONENTS.PATIENT_CHANGE_EMAIL,
  ROUTE_COMPONENTS.INVITATION,
];

export const WN_ADMIN_ROUTES = [
  ROUTE_COMPONENTS.WNADMIN_HOME,
  ROUTE_COMPONENTS.ORGANIZATION_PROFILE,
  ROUTE_COMPONENTS.WN_ADMIN_CLIENTS,
  ROUTE_COMPONENTS.ORGANIZATIONS,
  ROUTE_COMPONENTS.PPM_DOWNLOADS_HISTORY,
  ROUTE_COMPONENTS.SYSTEM_SETTINGS,
  ROUTE_COMPONENTS.MY_PROFILE,
  ROUTE_COMPONENTS.ADD_USER,
  ROUTE_COMPONENTS.WN_ADMIN_USER_PROFILE,
  ROUTE_COMPONENTS.SESSION_HISTORY,
  ROUTE_COMPONENTS.PATIENT_PROFILE,
  ROUTE_COMPONENTS.WN_ADMIN_EDIT_USER_PROFILE,
];

export const STORE_ADMIN_ROUTES = [
  ROUTE_COMPONENTS.ORGANIZATION_PROFILE,
  ROUTE_COMPONENTS.STOREADMIN_HOME,
  ROUTE_COMPONENTS.TOS_LIST,
  ROUTE_COMPONENTS.PPM_DOWNLOADS_HISTORY,
  ROUTE_COMPONENTS.MY_PROFILE,
  ROUTE_COMPONENTS.ADD_USER,
  ROUTE_COMPONENTS.ADD_PATIENT,
  ROUTE_COMPONENTS.PATIENT_PROFILE,
  ROUTE_COMPONENTS.BRAINCARE_REPORT,
  ROUTE_COMPONENTS.SCALE_VIEW_DETAILS,
  ROUTE_COMPONENTS.SCALE_COMPARISON,
  ROUTE_COMPONENTS.PDF_PREVIEW,
];

export const EXECUTIVE_ROUTES = [
  ROUTE_COMPONENTS.EXECUTIVE_HOME,
  ROUTE_COMPONENTS.EXECUTIVE_PROFILE,
];

export const STORE_USER_AND_WN_SALES_ROUTES = [
  ROUTE_COMPONENTS.ORGANIZATION_PROFILE,
  ROUTE_COMPONENTS.STOREUSER_WNSALES_HOME,
  ROUTE_COMPONENTS.TOS_LIST,
  ROUTE_COMPONENTS.PPM_DOWNLOADS_HISTORY,
  ROUTE_COMPONENTS.MY_PROFILE,
  ROUTE_COMPONENTS.ADD_PATIENT,
  ROUTE_COMPONENTS.PATIENT_PROFILE,
  ROUTE_COMPONENTS.BRAINCARE_REPORT,
  ROUTE_COMPONENTS.SCALE_VIEW_DETAILS,
  ROUTE_COMPONENTS.SCALE_COMPARISON,
  ROUTE_COMPONENTS.PDF_PREVIEW,
];

export const EEG_LAB_USER_ROUTES = [
  ROUTE_COMPONENTS.EEGLABUSER_HOME,
  ROUTE_COMPONENTS.MY_PROFILE,
  ROUTE_COMPONENTS.BRAINCARE_REPORT,
  ROUTE_COMPONENTS.PATIENT_PROFILE,
  ROUTE_COMPONENTS.PDF_PREVIEW,
];

export const CLIENT_ROUTES = [
  ROUTE_COMPONENTS.CLIENT_HOME,
  ROUTE_COMPONENTS.CLIENT_PROFILE,
  ROUTE_COMPONENTS.BRAINCARE_REPORT,
  ROUTE_COMPONENTS.PDF_PREVIEW,
  ROUTE_COMPONENTS.INVITATION_WELCOME,
  ROUTE_COMPONENTS.PATIENT_CHANGE_EMAIL,
];

export const STATUS_VALUE = {
  [UserStatus.PENDING_APPROVAL]: 'Pending approval',
  [UserStatus.REJECTED]: 'Rejected',
  [UserStatus.APPROVED]: 'Approved',
};

export const EEG_STATUS_VALUE = {
  [EegStatus.BROKEN]: 'Processing failed',
  [EegStatus.PENDING_PROCESSING]: 'Pending processing',
  [EegStatus.PROCESSING]: 'Processing',
  [EegStatus.OK]: 'Ok',
};

export const REPORT_STATUS_VALUE = {
  [ReportStatus.DELAYED]: 'Delayed',
  [ReportStatus.APPROVED]: 'Approved',
  [ReportStatus.REJECTED]: 'Rejected',
  [ReportStatus.BROKEN]: 'Processing failed',
  [ReportStatus.READY_FOR_REVIEW]: 'Ready for review',
  [ReportStatus.NOT_SPECIFIED]: 'Not specified',
};

export const REPORT_STATUS_LABEL = {
  APPROVE: 'Approve',
  REPLACE: 'Replace',
  REJECT: 'Reject',
  READY_FOR_REVIEW: 'Ready for review',
  SEND_FOR_REVIEW: 'Send for review',
};

export const MONTH_RANGE: [Date, Date] = [
  startOfMonth(sub(new Date(), { months: 1 })),
  endOfMonth(sub(new Date(), { months: 1 })),
];
export const WEEK_RANGE: [Date, Date] = [
  startOfWeek(sub(new Date(), { weeks: 1 })),
  endOfWeek(sub(new Date(), { weeks: 1 })),
];
export const YEAR_RANGE: [Date, Date] = [
  startOfYear(sub(new Date(), { years: 1 })),
  endOfYear(sub(new Date(), { years: 1 })),
];

export const LAST_WEEK_RANGE_OPTION: DateRangeOption = {
  value: WEEK_RANGE,
  label: 'Last week',
};
export const LAST_MONTH_RANGE_OPTION: DateRangeOption = {
  value: MONTH_RANGE,
  label: 'Last month',
};
export const LAST_YEAR_RANGE_OPTION: DateRangeOption = {
  value: YEAR_RANGE,
  label: 'Last year',
};

export const RANGE_PICKER_OPTIONS: DateRangeOption[] = [
  LAST_WEEK_RANGE_OPTION,
  LAST_MONTH_RANGE_OPTION,
  LAST_YEAR_RANGE_OPTION,
];

export const DEFAULT_AVATAR_POSITION = { x: 0.5, y: 0.5 };

export const SUPPORT_LINK =
  'https://waveneurohelp.zendesk.com/hc/en-us/requests/new?ticket_form_id=1500000408301';

export const HEALTHY_INDICATOR_ID = 2;

export const EXPIRED_TOKEN_ALERT_MESSAGE = 'Please, log in to continue';

export const UPLOAD_FILE_MESSAGE = 'File successfully uploaded!';

export enum TosEnum {
  SIGNED = 'SIGNED',
  NOT_SIGNED = 'NOT_SIGNED',
  WAITING_SIGNATURE = 'WAITING_SIGNATURE',
  SIGNED_MANUALLY = 'SIGNED_MANUALLY',
}

/** At the moment, both numbers and strings are used for statuses in the backend.
 * After the transition to the strings, it will be necessary to remove some of the types for TOS.
 */
export const TosRecord: Record<TosEnum | TosStatus, TosEnum> = {
  [TosStatus.SIGNED]: TosEnum.SIGNED,
  [TosEnum.SIGNED]: TosEnum.SIGNED,
  [TosStatus.NOT_SIGNED]: TosEnum.NOT_SIGNED,
  [TosEnum.NOT_SIGNED]: TosEnum.NOT_SIGNED,
  [TosStatus.WAITING_SIGNATURE]: TosEnum.WAITING_SIGNATURE,
  [TosEnum.WAITING_SIGNATURE]: TosEnum.WAITING_SIGNATURE,
  [TosStatus.SIGNED_MANUALLY]: TosEnum.SIGNED_MANUALLY,
  [TosEnum.SIGNED_MANUALLY]: TosEnum.SIGNED_MANUALLY,
};

export const gaEventName = {
  USER_BRAINCARE_VIEW: 'user_braincare_view',
  USER_BRAINCARE_DOWNLOAD: 'user_braincare_download',
  USER_NEUROREF_DOWNLOAD: 'user_neuroref_download',
};
