import React, {
  FC,
  useMemo,
  useState,
  createContext,
  useCallback,
} from 'react';

import { clientsTableFiltersInitialData } from '../constants/table';
import { usePageData } from '../hooks/wnAdmin/usePageData';
import { CliensContextProps, PageDataType, ProviderProps } from '../types';
import { serializeClientsQueryString } from '../utils/serializeClientsQueryString';

export const ClientsPageContext = createContext<CliensContextProps>({
  queryString: '',
  handleInputSearchChange: () => undefined,
  handleInputSearchClick: () => undefined,
  handleFilters: () => undefined,
  pageData: {
    searchTerm: null,
    searchInputValue: '',
    filters: clientsTableFiltersInitialData,
    sortType: 'asc',
  },
  setPageData: () => undefined,
  clearClientsPageContextState: () => undefined,
});

const WnAdminClientsProvider: FC<ProviderProps> = (props) => {
  const [pageData, setPageData] = useState<PageDataType>({
    searchTerm: null,
    searchInputValue: '',
    filters: clientsTableFiltersInitialData,
    sortType: 'asc',
  });

  const {
    handleInputSearchChange,
    handleInputSearchClick,
    handleFilters,
  } = usePageData(pageData, setPageData);

  const queryString = useMemo(
    () =>
      serializeClientsQueryString({
        search: pageData.searchTerm,
        filters: pageData.filters,
        sortType: pageData.sortType,
      }),
    [pageData.searchTerm, pageData.filters, pageData.sortType]
  );

  const clearClientsPageContextState = useCallback(() => {
    setPageData({
      searchTerm: null,
      searchInputValue: '',
      filters: clientsTableFiltersInitialData,
      sortType: 'asc',
    });
  }, []);

  const { children } = props;

  const memoizedValue = useMemo(
    () => ({
      handleFilters,
      handleInputSearchChange,
      handleInputSearchClick,
      queryString,
      pageData,
      setPageData,
      clearClientsPageContextState,
    }),
    [
      handleFilters,
      handleInputSearchChange,
      handleInputSearchClick,
      queryString,
      pageData,
      setPageData,
      clearClientsPageContextState,
    ]
  );

  return (
    <ClientsPageContext.Provider value={memoizedValue}>
      {children}
    </ClientsPageContext.Provider>
  );
};

export default WnAdminClientsProvider;
