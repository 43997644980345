import { PPMDownloadsHistoryFilters } from '../types';

export const serializePPMDownloadsHistoryTableParams = (
  search: string | null,
  filters: PPMDownloadsHistoryFilters
) => {
  const res = [];
  const { organizations } = filters;

  if (search) {
    res.push(`search=${search}`);
  }

  for (let i = 0; i <= organizations?.length - 1; i++) {
    if (organizations[i]?.value) {
      res.push(`org_id=${organizations[i].value}`);
    }
  }

  return res.join('&');
};
