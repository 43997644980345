import React from 'react';

const ArrowRightIcon: React.FunctionComponent<{ fill?: string }> = ({
  fill = '#12141A',
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 11.9997C16.5005 12.2334 16.4191 12.4598 16.27 12.6397L11.27 18.6397C11.1003 18.8439 10.8564 18.9724 10.592 18.9967C10.3275 19.0211 10.0642 18.9395 9.86003 18.7697C9.65581 18.6 9.52739 18.3561 9.50301 18.0916C9.47863 17.8272 9.56029 17.5639 9.73003 17.3597L14.21 11.9997L9.89003 6.63972C9.80697 6.53744 9.74493 6.41974 9.7075 6.2934C9.67007 6.16706 9.65798 6.03457 9.67192 5.90354C9.68586 5.77251 9.72555 5.64553 9.78873 5.52989C9.8519 5.41426 9.9373 5.31225 10.04 5.22972C10.1429 5.13814 10.2635 5.06878 10.3944 5.02599C10.5252 4.9832 10.6635 4.9679 10.8006 4.98104C10.9377 4.99418 11.0705 5.03549 11.1909 5.10237C11.3112 5.16926 11.4165 5.26027 11.5 5.36973L16.33 11.3697C16.4555 11.5548 16.5154 11.7766 16.5 11.9997Z"
      fill={fill}
    />
  </svg>
);

export default ArrowRightIcon;
