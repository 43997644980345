import axios from 'axios';

import { AUTH_USER_TOKEN_KEY } from '../constants';
import { getItem } from './localStorageApi';

let CANCEL_TOKEN_SOURCE = axios.CancelToken.source();

export const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL ?? `https://api.${window.location.host}`,
  headers: { 'Content-Type': 'application/json' },
});

const generateNewCancelTokenSource = () => {
  CANCEL_TOKEN_SOURCE = axios.CancelToken.source();
};

export const finishPendingRequests = (cancellationReason: string) => {
  CANCEL_TOKEN_SOURCE.cancel(cancellationReason);
  generateNewCancelTokenSource();
};

type Request = {
  url: string;
  data?: any;
  headers?: any;
};

export const uploadFile = (options: Request) => {
  const { url, data, headers } = options;
  const defaultHeaders = { 'Content-Type': 'multipart/form-data' };
  const headersOptions = headers
    ? { ...headers, ...defaultHeaders }
    : { ...defaultHeaders };
  return axiosInstance({
    method: 'POST',
    url,
    data,
    headers: headersOptions,
    cancelToken: CANCEL_TOKEN_SOURCE.token,
  });
};

export const post = (options: Request) => {
  const { url, data } = options;
  return axiosInstance({
    method: 'POST',
    url,
    data,
    headers: {
      Authorization: `Bearer ${getItem(AUTH_USER_TOKEN_KEY)}`,
    },
    cancelToken: CANCEL_TOKEN_SOURCE.token,
  });
};

export const patch = (options: Request) => {
  const { url, data } = options;
  return axiosInstance({
    method: 'PATCH',
    url,
    data,
    headers: {
      Authorization: `Bearer ${getItem(AUTH_USER_TOKEN_KEY)}`,
    },
    cancelToken: CANCEL_TOKEN_SOURCE.token,
  });
};

export const postUnauthorized = (options: Request) => {
  const { url, data, headers } = options;
  const headersOptions = headers ? { headers } : {};
  return axiosInstance({
    method: 'POST',
    url,
    data,
    ...headersOptions,
    cancelToken: CANCEL_TOKEN_SOURCE.token,
  });
};

export const get = (options: Request) => {
  const { url } = options;
  return axiosInstance({
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${getItem(AUTH_USER_TOKEN_KEY)}`,
    },
    cancelToken: CANCEL_TOKEN_SOURCE.token,
  });
};

export const put = (options: Request) => {
  const { url, data } = options;
  return axiosInstance({
    method: 'PUT',
    url,
    data,
    headers: {
      Authorization: `Bearer ${getItem(AUTH_USER_TOKEN_KEY)}`,
    },
    cancelToken: CANCEL_TOKEN_SOURCE.token,
  });
};

export const del = (options: Request) => {
  const { url } = options;
  return axiosInstance({
    method: 'DELETE',
    url,
    headers: {
      Authorization: `Bearer ${getItem(AUTH_USER_TOKEN_KEY)}`,
    },
    cancelToken: CANCEL_TOKEN_SOURCE.token,
  });
};
