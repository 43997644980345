import React, { useMemo, useState } from 'react';

export interface VideoSideMenuContextProps {
  visible: boolean;
  toggleVisibility: (value: boolean) => void;
}

export const VideoSideMenuContext = React.createContext<VideoSideMenuContextProps>(
  {
    visible: false,
    toggleVisibility: () => undefined,
  }
);

interface VideoSideMenuProviderProps {
  children: React.ReactNode;
}

const VideoSideMenuProvider: React.FunctionComponent<VideoSideMenuProviderProps> = (
  props
): JSX.Element => {
  const [visible, toggleVisibility] = useState<boolean>(false);

  const { children } = props;

  const sideMenuContextData = useMemo(
    () => ({
      visible,
      toggleVisibility,
    }),
    [visible, toggleVisibility]
  );

  return (
    <VideoSideMenuContext.Provider value={sideMenuContextData}>
      {children}
    </VideoSideMenuContext.Provider>
  );
};

export default VideoSideMenuProvider;
