import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router';

import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';

import { getIdleTime } from 'helpers';

import { post } from 'utils/api';

import { useClearTablesData } from 'hooks/useClearTablesData';

import { UserRole } from 'types';

import { logout } from 'cognito/logout';

type IdleTimerType = {
  getLastActiveTime: () => Date | null;
  reset: () => void;
  pause: () => void;
  start: () => void;
};

export const IdleTimerContext = React.createContext<IdleTimerType>({
  getLastActiveTime: () => null,
  reset: () => undefined,
  pause: () => undefined,
  start: () => undefined,
});

const LOG_OUT_TIMER = 900000; // 5 min for test (300000)
const CHECK_ACTIVITY_TIME = 899000; // LOG_OUT_TIMER - 1 sec

// INFO: this logic should work for all roles except the client!!!

export const IdleTimerProvider: FC = ({ children }) => {
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);

  const { isAuthenticated } = authContext;

  const { clearTablesData } = useClearTablesData();

  const history = useHistory();

  const isClient = userContext?.user?.role === UserRole.CLIENT;

  const onIdle = () => {
    if (isAuthenticated && !isClient) {
      logout(userContext, authContext, clearTablesData, history);
    }
  };

  const { start, reset, pause, getLastActiveTime } = useIdleTimer({
    onIdle,
    timeout: LOG_OUT_TIMER,
    promptTimeout: 0,
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: false,
    startManually: true,

    // disable timer when it worked
    stopOnIdle: true,

    // cross tab logic
    crossTab: true,
    syncTimers: 5000,
  });

  useEffect(() => {
    // when we open/refresh tab -> start timer
    if (isAuthenticated && !isClient) {
      start();
    }
  }, [isAuthenticated, isClient, start]);

  useEffect(() => {
    const checker = setInterval(() => {
      if (isAuthenticated && !isClient) {
        const isLogOut = getIdleTime({ getLastActiveTime, LOG_OUT_TIMER });
        if (!isLogOut && !isClient) {
          post({ url: '/users/update-activity' });
        } else {
          clearInterval(checker);
        }
      }
    }, CHECK_ACTIVITY_TIME);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isClient]);

  const timerContextData = useMemo(
    () => ({ getLastActiveTime, reset, pause, start }),
    [getLastActiveTime, reset, pause, start]
  );

  return (
    <IdleTimerContext.Provider value={timerContextData}>
      {children}
    </IdleTimerContext.Provider>
  );
};

// https://idletimer.dev/
