import React, { FC } from 'react';

type ArrowLeftIconProps = {
  fill?: string;
  withStick?: boolean;
};

const ArrowLeftIcon: FC<ArrowLeftIconProps> = ({
  fill = '#6B7071',
  withStick,
}) =>
  withStick ? (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6.00071H3.14L6.77 1.64111C6.93974 1.43691 7.0214 1.17365 6.99702 0.909247C6.97264 0.644844 6.84422 0.400955 6.64 0.231232C6.43578 0.0615082 6.1725 -0.0201464 5.90808 0.00423112C5.64365 0.0286087 5.39974 0.157022 5.23 0.36122L0.23 6.36068C0.196361 6.4084 0.166279 6.45853 0.14 6.51067C0.14 6.56066 0.14 6.59066 0.0700002 6.64066C0.0246737 6.75531 0.000941121 6.87735 0 7.00062C0.000941121 7.1239 0.0246737 7.24594 0.0700002 7.36059C0.0700002 7.41059 0.0699999 7.44059 0.14 7.49058C0.166279 7.54272 0.196361 7.59285 0.23 7.64057L5.23 13.64C5.32402 13.7529 5.44176 13.8437 5.57485 13.9059C5.70793 13.9681 5.85309 14.0002 6 14C6.23365 14.0005 6.46009 13.9191 6.64 13.77C6.74126 13.6861 6.82496 13.583 6.88631 13.4666C6.94766 13.3503 6.98546 13.223 6.99754 13.0921C7.00961 12.9611 6.99573 12.829 6.95669 12.7034C6.91764 12.5778 6.8542 12.4612 6.77 12.3601L3.14 8.00054H15C15.2652 8.00054 15.5196 7.89519 15.7071 7.70767C15.8946 7.52015 16 7.26582 16 7.00062C16 6.73543 15.8946 6.4811 15.7071 6.29358C15.5196 6.10606 15.2652 6.00071 15 6.00071Z"
        fill="#6B7071"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99997 12.0003C7.99951 11.7666 8.08089 11.5402 8.22997 11.3603L13.23 5.36028C13.3997 5.15606 13.6436 5.02763 13.908 5.00325C14.1725 4.97888 14.4358 5.06054 14.64 5.23028C14.8442 5.40001 14.9726 5.64393 14.997 5.90835C15.0214 6.17278 14.9397 6.43606 14.77 6.64028L10.29 12.0003L14.61 17.3603C14.693 17.4626 14.7551 17.5803 14.7925 17.7066C14.8299 17.8329 14.842 17.9654 14.8281 18.0965C14.8141 18.2275 14.7744 18.3545 14.7113 18.4701C14.6481 18.5857 14.5627 18.6878 14.46 18.7703C14.3571 18.8619 14.2365 18.9312 14.1056 18.974C13.9748 19.0168 13.8365 19.0321 13.6994 19.019C13.5623 19.0058 13.4295 18.9645 13.3091 18.8976C13.1888 18.8307 13.0835 18.7397 13 18.6303L8.16997 12.6303C8.04445 12.4452 7.9846 12.2234 7.99997 12.0003Z"
        fill={fill}
      />
    </svg>
  );

export default ArrowLeftIcon;
