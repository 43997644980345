import React, { useEffect, useMemo, useState } from 'react';

import { REMEMBER_ME_USERNAME, USER } from '../constants';
import { getItem, setItem } from '../utils/localStorageApi';

export interface UserContextProps {
  user: any;
  setUser: (value: any) => void;
  firstEntranceUsername: string;
  setFirstEntranceUsername: (value: string) => void;
  rememberUsername: string;
  setRememberUsername: (value: string) => void;
  forgotPasswordEmail: string;
  setForgotPasswordEmail: (value: string) => void;
}

export const UserContext = React.createContext<UserContextProps>({
  user: {},
  setUser: () => undefined,
  firstEntranceUsername: '',
  setFirstEntranceUsername: () => undefined,
  rememberUsername: '',
  setRememberUsername: () => undefined,
  forgotPasswordEmail: '',
  setForgotPasswordEmail: () => undefined,
});

interface UserProviderProps {
  children: React.ReactNode;
}

const UserProvider: React.FunctionComponent<UserProviderProps> = (
  props
): JSX.Element => {
  const userDataString: string | null = getItem(USER);
  const savedUsername: string | null = getItem(REMEMBER_ME_USERNAME);
  const userDataObject: any = userDataString
    ? JSON.parse(userDataString)
    : null;

  const [user, setUser] = useState<any>(userDataObject);
  const [rememberUsername, setRememberUsername] = useState<any>(savedUsername);
  const [firstEntranceUsername, setFirstEntranceUsername] = useState<string>(
    ''
  );
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>('');

  useEffect(() => {
    setItem(USER, JSON.stringify(user));
    setItem(REMEMBER_ME_USERNAME, rememberUsername);
  }, [user, rememberUsername]);

  const { children } = props;

  const userContextData = useMemo(
    () => ({
      user,
      setUser,
      setFirstEntranceUsername,
      firstEntranceUsername,
      rememberUsername,
      setRememberUsername,
      forgotPasswordEmail,
      setForgotPasswordEmail,
    }),
    [
      user,
      setUser,
      setFirstEntranceUsername,
      firstEntranceUsername,
      rememberUsername,
      setRememberUsername,
      forgotPasswordEmail,
      setForgotPasswordEmail,
    ]
  );

  return (
    <UserContext.Provider value={userContextData}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
