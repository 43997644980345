import { ChangeEventHandler, ReactNode } from 'react';
import { Row, TableBodyPropGetter, TableBodyProps } from 'react-table';

export enum UserRole {
  STORE_ADMIN = 'STORE_ADMIN',
  WN_ADMIN = 'WN_ADMIN',
  STORE_USER = 'STORE_USER',
  EEG_LAB_USER = 'EEG_LAB_USER',
  WN_SALES = 'WN_SALES',
  EXECUTIVE = 'EXECUTIVE',
  CLIENT = 'CLIENT',
}

export enum EegLabUserTableState {
  NEUROREF = 'NEUROREF',
  BRAINCARE = 'BRAINCARE',
  PROTOCOL = 'PROTOCOL',
}

export enum TosStatus {
  SIGNED,
  NOT_SIGNED,
  WAITING_SIGNATURE,
  SIGNED_MANUALLY,
}

export type RouteType = {
  id: string;
  path: string;
  component: any;
  exact: boolean;
  title: string;
  sidemenu: boolean;
  subroutes?: RouteType[];
  icon?: React.ReactElement;
  sidemenuParentId?: string;
};

export interface RequestResponse {
  data: any;
  error: any;
  isLoading: boolean;
}

export interface RequestOptions {
  url: string;
  custom401errorMessage?: string;
}

export enum ReportStatus {
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  DELAYED = 'DELAYED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  BROKEN = 'BROKEN',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export enum ProtocolStatus {
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  PROCESSING = 'PROCESSING',
  PENDING_PROCESSING = 'PENDING_PROCESSING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  BROKEN = 'BROKEN',
  UPDATED = 'UPDATED',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  OK = 'OK',
}

export enum EegStatus {
  PENDING_PROCESSING = 'PENDING_PROCESSING',
  PROCESSING = 'PROCESSING',
  BROKEN = 'BROKEN',
  OK = 'OK',
}

export enum UserStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export enum ProtocolType {
  NEST = 'NEST',
  NEXSTIM = 'NEXSTIM',
}

export enum ReportType {
  BRAINCARE = 'BRAINCARE',
  NEUROREF = 'NEUROREF',
}

export enum BrainLocation {
  Fp1_Fpz_Fp2 = 'Fp1-Fpz-Fp2',
  F1_Fz_F2 = 'F1-Fz-F2',
  P1_Pz_P2 = 'P1-Pz-P2',
  C1_Cz_C2 = 'C1-Cz-C2',
  C2_C4_C6 = 'C2-C4-C6',
  C3_CP3_P3 = 'C3-CP3-P3',
  C4_CP4_P4 = 'C4-CP4-P4',
  C5_C3_C1 = 'C5-C3-C1',
  Cz_CPz_Pz = 'Cz-CPz-Pz',
  O1_Oz_O2 = 'O1-Oz-O2',
  F1_F3_F5 = 'F1-F3-F5',
  F2_F4_F6 = 'F2-F4-F6',
  F5_F3_F1 = 'F5-F3-F1',
  F7_FT7_T3 = 'F7-FT7-T3',
  F8_FT8_T4 = 'F8-FT8-T4',
  FC1_FCz_FC2 = 'FC1-FCz-FC2',
  FCz_Cz_CPz = 'FCz-Cz-CPz',
  FT7_T3_TP7 = 'FT7-T3-TP7',
  FT8_T4_TP8 = 'FT8-T4-TP8',
  P1_P3_P5 = 'P1-P3-P5',
  P2_P4_P6 = 'P2-P4-P6',
  P5_PO3_O1 = 'P5-PO3-O1',
  P6_PO4_O2 = 'P6-PO4-O2',
  T3_CP5_P3 = 'T3-CP5-P3',
  T4_CP6_P4 = 'T4-CP6-P4',
  T7_P3 = 'T7-P3',
}

export interface EegFileType {
  active: boolean;
  code: number;
  id: string;
  name: string;
}

export enum Sex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum SignInRoleType {
  WN_USER = 'WN_USER',
  WN_CLIENT = 'WN_CLIENT',
}

export interface Organization {
  id: string | number;
  name: string;
  createdAt: string;
  isFake: boolean;
  modifiedAt: string | null;
  is_braincare_available: boolean;
  is_fake: boolean;
  is_nest_available: boolean;
  is_neuroref_available: boolean;
  is_nexstim_available: boolean;
}

export type SelectOption = {
  value: string | number;
  label: string;
  activeColor?: string;
  __isNew__?: boolean;
};

export type DropdownOption = {
  value: string | number;
  label: string;
  isFake: boolean;
};

type MenuOptionsAsync = (payload?: MenuPayload) => Promise<void>;
type MenuOptionsVoid = (payload?: MenuPayload) => void;

export interface MenuOptions {
  label: string;
  onClick: MenuOptionsAsync | MenuOptionsVoid;
  isMain?: boolean;
  labelTos?: string;
}

export interface MenuPayload {
  data: EEGMenuPayload;
}

export interface EEGMenuPayload {
  EEG: number;
}

export type User = {
  first_name: string;
  last_name: string;
  clinic: string | null;
  location: string | null;
  status: UserStatus;
  created_at: string;
  email: string;
  is_active: boolean;
  id: number;
  modifiedAt: string | null;
  organization: string | null;
  organizations: any[];
  role: UserRole;
};

export interface PatientFiltersCommonValues {
  delayed: boolean;
  approved: boolean;
  rejected: boolean;
  protocolBroken: boolean;
  brokenReport: boolean;
  protocolNotSpecified: boolean;
  protocolUpdated: boolean;
  protocolApproved: boolean;
  archived: boolean;
}

export interface ClientReportsFilter {
  approved: boolean;
  rejected: boolean;
  processing: boolean;
  broken: boolean;
}

export interface UserTableFilterFormValues {
  pendingApproval: boolean;
  active: boolean;
  inactive: boolean;
  approved: boolean;
  rejected: boolean;
  storeAdmin: boolean;
  storeUser: boolean;
  eegLabUser: boolean;
  wnSales: boolean;
  wnAdmin: boolean;
  executive: boolean;
  allowed: boolean;
}

export interface UserTableFiltersData extends UserTableFilterFormValues {
  organizations: SelectOption[];
}

export interface ClientsTableFilterFormValues {
  waitingForSignature: boolean;
  signed: boolean;
  notSigned: boolean;
}
export interface ClientsTableFiltersData extends ClientsTableFilterFormValues {
  organizations: SelectOption[];
}
export interface PatientFiltersFormVal extends PatientFiltersCommonValues {
  organizations: SelectOption[];
}

export interface EegUserFilterValues {
  readyForReview: boolean;
  delayed: boolean;
  rejected: boolean;
  approved: boolean;
  nest_rfr: boolean;
  nest_broken: boolean;
  nexstim_broken: boolean;
  protocolPending: boolean;
  protocolProcessing: boolean;
  protocolBroken: boolean;
  protocolSuccess: boolean;
  brokenBcReport: boolean;
  brokenNeurorefReport: boolean;
  nexstim_rfr: boolean;
  nest_approved: boolean;
  nexstim_approved: boolean;
  readyForReviewBc: boolean;
  approvedBc: boolean;
  rejectedBc: boolean;
}

export interface EegUserPageFilters extends EegUserFilterValues {
  organizations: SelectOption[];
}

export type ButtonTypes = 'button' | 'submit' | 'reset' | undefined;

export interface Size {
  width?: number;
  height?: number;
}

export type DateRangeOption = {
  value: [Date, Date];
  label: string;
};

export enum PatientProfileTab {
  TREATMENT = 'TREATMENT',
  NOTE = 'NOTE',
  SCALES = 'SCALES',
  SESSION_HISTORY = 'SESSION_HISTORY',
}

export enum ClientProfileTab {
  REPORTS = 'REPORTS',
  SCALES = 'SCALES',
}

export type AvatarPreview = {
  croppedAvatar: string;
  position: Position;
  scale: number;
};

export type Position = {
  x: number;
  y: number;
};

export type AlternativeIdType = { alt_id: string; name: string };

export interface ClientData {
  organization: string | number;
  first_name: string;
  last_name: string;
  birthday: string;
  email: string;
  sex: string;
  chief_compliant: string;
  alternative_ids: AlternativeIdType[];
}

export interface ClientProfileData {
  firstName: string;
  lastName: string;
  birthday: string;
  chiefComplaint: string;
  email: string;
  organization?: OrganizationViewType;
  username: string;
}

export enum ReprocessType {
  EEG = 'EEG',
  BRAINCARE = 'BRAINCARE',
  NEUROREF = 'NEUROREF',
}

export type EEGHistoryType = {
  id: number;
  nest: null | NestProtocolType;
  nextstim: null | NexProtocolType;
  recording_date: null | string;
};

export type NestProtocolType = {
  frequency: null | number;
  id: number;
  created_at: string;
  modified_at: string | null;
  status: ProtocolStatus;
};

export type NexProtocolType = {
  frequency: null | number;
  id: number;
  locations: string[];
  created_at: string;
  data: NexStimDataType[];
  modified_at: string | null;
  status: ProtocolStatus;
};

type NexStimDataType = {
  bursts_in_train: number;
  created_at: string;
  frequency: number;
  id: number;
  interburst_train: number;
  intertrain_time: number;
  location: BrainLocation;
  modified_at: string;
  number_of_trains: number;
  pulses_in_burst: number;
};

export type OrganizationType = {
  created_at: string;
  id: number;
  is_fake: boolean;
  modified_at: string;
  name: string;
  number_of_members: number;
  number_of_clients: number;
  number_of_users: number;
  is_braincare_available: boolean;
  is_nest_available: boolean;
  is_neuroref_available: boolean;
  is_nexstim_available: boolean;
};

export type OrganizationViewType = {
  id: number;
  name: string;
  is_fake: boolean;
  clients_count: number;
  users_count: number;
  complaints: {
    id: string;
    name: string;
  }[];
  request_types: string[];
  contact_info: {
    email: string;
    phone_number: string;
    address: {
      street: string;
      unit: string;
      city: string;
      state: string;
      postal_code: string;
      country: string;
      coordinates: {
        latitude: number;
        longitude: number;
      };
    };
  } | null;
};

export const EmptyOrganizationPlug: OrganizationType = {
  created_at: '',
  id: 0,
  is_fake: false,
  modified_at: '',
  name: '',
  number_of_members: 0,
  number_of_clients: 0,
  number_of_users: 0,
  is_braincare_available: false,
  is_nest_available: false,
  is_neuroref_available: false,
  is_nexstim_available: false,
};

export type PermissionsReportsAndProtocolsType = {
  isNeuroref: boolean;
  isBraincare: boolean;
  isNest: boolean;
  isNext: boolean;
};

export const errorTextAddRequest =
  'The request can be added when the TOS is signed.';

export type BcvarsType = {
  alpha_coherence_F3_F4: number;
  brainscore: number;
  burst_frequency: number;
  created_at: string;
  factor_1_score: number;
  frontal_local_alpha_frequency: number;
  frontal_local_brain_score: number;
  hidden: boolean;
  id: number;
  interference: number;
  low_voltage: boolean;
  lower_bound_frequency: number;
  modified_at: string;
  nearest_burst_frequency_to_centroid: number;
  percent_beta: number;
  posterior_local_brain_score: number;
  relative_alpha_power: number;
  relative_theta_power: number;
  status: ReportStatus;
  upper_bound_frequency: number;
};

export type AttachmentType = {
  attachment_filename: string;
  created_at: string;
  id: number;
  modified_at: string;
  url: string;
};

export type ImageType = {
  created_at: string;
  eeg_recording_date: string;
  id: number;
  image_filename: string;
  modified_at: string;
  url: string;
};

export type MainBCReport = {
  attachments: AttachmentType[];
  brainwave_synchrony_score: number;
  created_at: string;
  current_frequency: number;
  eeg_date: string;
  eeg_location: string;
  eegs: {
    eeg_filename: string;
    id: number;
  }[];
  gender: string;
  id: number;
  images: ImageType[];
  interference_score: number;
  modified_at: string;
  name: string;
  note: null;
  opportunities: string[];
  optimal_range_lower: number;
  optimal_range_upper: number;
  strengths: string[];
  yob: string;
};

export type ChiefComplaintsType = {
  id: string;
  label: string;
  value: string;
};

export type ChiefItemType = { id: string; name: string };

export type PatientDataSessionType = {
  birthday: string;
  chief_complaint: string;
  first_name: string;
  gender: 'Male' | 'Female';
  last_name: string;
  org_name: string;
  user_name: string;
};

export type SessionsType = {
  id: number;
  eeg_id: number;
  finished_at: string;
  app_type: string;
  protocol_id: number;
  sonal_id: string;
  initialized_at: number;
  duration: number;
  duration_commitment: number;
  status: 'Completed' | 'Terminated' | 'Initiated';
  timezone_offset: number;
};

export type SessionHistoryType = {
  patientData: PatientDataSessionType;
  sessions: SessionsType[];
};

export interface PPMDownloadsHistoryTableProps {
  data: PPMDownloadsHistoryDataItem[];
  isLoading: boolean;
}

export interface PPMDownloadsHistoryTableBodyProps {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  rows: Array<Row<any>>;
  prepareRow: (row: Row<any>) => void;
}

export interface PPMDownloadsHistoryTableColumn {
  Header: string;
  accessor: string;
  width: string;
  icon?: string;
  description?: string;
}

export interface PPMDownloadsHistoryDataItem {
  client: {
    first_name: string;
    id: number;
    last_name: string;
  };
  downloaded_at: string;
  id: string;
  org: {
    id: number;
    is_fake: boolean;
    name: string;
  };
  user: {
    first_name: string;
    id: number;
    last_name: string;
    role: string;
  };
}

export interface InfiniteScrollerTableProps {
  onScroll: () => void;
  data: any[];
  tableComponent: React.FunctionComponent<any>;
  context: any;
  loaderComponent?: React.ReactNode | JSX.Element;
  height?: string;
  scrollThreshold?: number;
  isLoading: boolean;
}

interface PPMDownloadsHistoryFilterOrganization {
  isFake: boolean | undefined;
  label: string;
  value: number;
}

export interface PPMDownloadsHistoryFilters {
  organizations: PPMDownloadsHistoryFilterOrganization[];
}

export interface DownloadButtonProps {
  url: string;
  classNames?: string;
  children: ReactNode | JSX.Element;
}

export interface ScaleQuestionType {
  id: number;
  name: string;
  options: { id: number; value: string; with_input: boolean }[];
  required: true;
  type: number;
}

export interface ScaleFieldType {
  id: number;
  name: string;
  questions_count: number;
  questions: ScaleQuestionType[];
}

export interface ScaleAnswer {
  question_id: number;
  selected_option_id: number;
  selected_option_text: string | null;
  text: string | null;
}

export interface ScaleType {
  answered_questions_count: string;
  created_at: string;
  filled_at: string;
  id: number;
  modified_at: string;
  scale: ScaleFieldType;
  status: number;
  answers: ScaleAnswer[];
}

export interface ScaleProgressesResponseType {
  data: ScaleType;
  isLoading: boolean;
}

export interface ClientReportsResponseType {
  data: {
    reports: {
      created_at: string;
      eeg: { filename: string; id: number; status: string };
      id: number;
      modified_at: string;
      recording_date: string;
      report_type: string;
      status: string;
    }[];
    total: number;
    pages: number;
    hasNext: boolean;
    hasPrev: boolean;
  };
  isLoading: boolean;
}

export interface ClientScalesResponseType {
  data: {
    total: number;
    page: number;
    pages: number;
    hasNext: boolean;
    hasPrev: boolean;
    nextSum: number;
    scaleProgresses: {
      answered_questions_count: 10;
      created_at: string;
      filled_at: string;
      id: 217;
      modified_at: string;
      scale: { id: 7; name: string; questions_count: 10 };
      status: 1;
    }[];
  };
  isLoading: boolean;
}

export type PageType = {
  title: string;
};

export type ReportStatusProps = {
  status: ReportStatus | null;
  reportId: string | number;
  reportType: string;
  eegStatus: EegStatus | null;
  orgId: number | null;
};

export type SessionHistoryProps = {
  isShowClientCard?: boolean;
  tableHeaderClassNames?: string;
};

export type SessionHistoryContextType = {
  isShowDownloadButton: boolean;
  setToggleShowDownloadButton: (callback: () => boolean) => void;
  clearSessionHistoryPageContextState: () => void;
};

export interface IconProps {
  color?: string;
  width?: string;
  height?: string;
}

export interface SideMenuIconProps {
  isActive?: boolean;
}

export interface PageDataType {
  searchTerm: string | null;
  searchInputValue: string;
  filters: any;
  sortType: string;
}

export interface ProviderProps {
  children: ReactNode;
}

export interface CliensContextProps {
  handleFilters: (values: any) => void;
  queryString: string;
  handleInputSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleInputSearchClick: () => void;
  pageData: PageDataType;
  setPageData: (v: PageDataType) => void;
  clearClientsPageContextState: () => void;
}

export interface ClientType {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  number_of_sonal_sessions: number;
  org_index_data: { id: number; name: string; is_fake: boolean };
  tos_state: string;
  user_name: string;
}

export interface OrganizationsContextProps {
  handleFilters: (values: any) => void;
  queryString: string;
  handleInputSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleInputSearchClick: () => void;
  pageData: Omit<PageDataType, 'sortType'>;
  setPageData: (v: Omit<PageDataType, 'sortType'>) => void;
  clearOrganizationsPageContextState: () => void;
}

export interface OrganizationsTableFiltersDataType {
  organizations: SelectOption[];
}

export type OrganizationsTablePageDataType = {
  searchTerm: null | string;
  searchInputValue: string;
  filters: OrganizationsTableFiltersDataType;
};
