import { SignInRoleType } from 'types';

import {
  AUTH_USER_ACCESS_TOKEN_KEY,
  AUTH_USER_REFRESH_TOKEN_KEY,
  AUTH_USER_TOKEN_KEY,
  ROUTES,
  USER,
} from '../constants';
import { post } from '../utils/api';
import { removeItem } from '../utils/localStorageApi';
import { logoutType, removeUserDataType } from './types';

export const removeUserData: removeUserDataType = (
  history,
  setAuthenticated,
  setUser,
  clearTablesData
) => {
  removeItem(USER);
  removeItem(AUTH_USER_ACCESS_TOKEN_KEY);
  removeItem(AUTH_USER_REFRESH_TOKEN_KEY);
  removeItem(AUTH_USER_TOKEN_KEY);

  setAuthenticated(false);
  setUser(null);
  clearTablesData();
  history?.push(ROUTES.SIGNIN);
};

export const logout: logoutType = (
  userContext,
  authContext,
  clearTablesData,
  history
) => {
  const { setUser } = userContext;
  const { setAuthenticated, roleType } = authContext;

  const isWnUser = roleType === SignInRoleType.WN_USER;

  const url = isWnUser ? '/logout' : '/clients/logout';

  post({ url }).finally(() =>
    removeUserData(history, setAuthenticated, setUser, clearTablesData)
  );
};
