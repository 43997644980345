import React, { ChangeEventHandler, useMemo, useState } from 'react';

import { patientFiltersCommonValues } from '../constants/table';
import { usePageData } from '../hooks/usePageData';
import { PatientFiltersCommonValues } from '../types';
import { serializePatientProfileTableParams } from '../utils/patientProfileTable';

export interface PatientProfileTableContextProps {
  queryString: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleFilters: (values: PatientFiltersCommonValues) => void;
  setSearchTerm: (v: string) => void;
  totalPageCount: number;
  setTotalPageCount: (pageCount: number) => void;
  hasNext: boolean;
  setHasNext: (value: boolean) => void;
  hasPrev: boolean;
  setHasPrev: (value: boolean) => void;
  pageData: PatientProfileTablePageData;
  setPageData: (v: PatientProfileTablePageData) => void;
  clearPatientProfileTableContextState: () => void;
}

export type PatientProfileTablePageData = {
  searchTerm: string | null;
  currentPage: number;
  filters: PatientFiltersCommonValues;
  sortFields: {
    created_at: string;
  };
};

export type WnAdminTablePageData = {
  searchTerm: string | null;
  currentPage: number;
  filters: PatientFiltersCommonValues;
  sortFields: {
    first_name: string;
  };
};

export const PatientProfileTableContext = React.createContext<PatientProfileTableContextProps>(
  {
    queryString: '',
    handleChange: () => undefined,
    handleFilters: () => undefined,
    totalPageCount: 0,
    setTotalPageCount: () => undefined,
    setSearchTerm: () => undefined,
    hasNext: false,
    setHasNext: () => undefined,
    hasPrev: false,
    setHasPrev: () => undefined,
    pageData: {
      searchTerm: null,
      filters: patientFiltersCommonValues,
      currentPage: 1,
      sortFields: {
        created_at: '',
      },
    },
    setPageData: () => undefined,
    clearPatientProfileTableContextState: () => undefined,
  }
);

interface PatientProfileTableProps {
  children: React.ReactNode;
}

const PatientProfileTableProvider: React.FunctionComponent<PatientProfileTableProps> = (
  props
) => {
  const [pageData, setPageData] = useState<PatientProfileTablePageData>({
    searchTerm: null,
    currentPage: 1,
    filters: patientFiltersCommonValues,
    sortFields: {
      created_at: '',
    },
  });

  const {
    totalPageCount,
    setTotalPageCount,
    hasNext,
    hasPrev,
    setHasNext,
    setHasPrev,
    handleChange,
    handleFilters,
    setSearchTerm,
  } = usePageData(pageData, setPageData);

  const queryString = useMemo(
    () =>
      serializePatientProfileTableParams(
        pageData.searchTerm,
        pageData.filters,
        pageData.sortFields
      ),
    [pageData.searchTerm, pageData.filters, pageData.sortFields]
  );

  const clearPatientProfileTableContextState = () => {
    setTotalPageCount(0);
    setHasNext(false);
    setHasPrev(false);
    setPageData({
      searchTerm: null,
      currentPage: 1,
      filters: patientFiltersCommonValues,
      sortFields: {
        created_at: '',
      },
    });
  };

  const { children } = props;

  return (
    <PatientProfileTableContext.Provider
      value={{
        setSearchTerm,
        queryString,
        handleChange,
        handleFilters,
        totalPageCount,
        setTotalPageCount,
        hasNext,
        setHasNext,
        hasPrev,
        setHasPrev,
        pageData,
        setPageData,
        clearPatientProfileTableContextState,
      }}
    >
      {children}
    </PatientProfileTableContext.Provider>
  );
};

export default PatientProfileTableProvider;
