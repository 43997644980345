import { PatientFiltersFormVal, ProtocolStatus, ReportStatus } from '../types';

export const serializePatientTableParams = (
  search: string | null,
  filters: PatientFiltersFormVal,
  sortFields: {
    first_name: string;
  }
) => {
  const res = [];
  if (search) {
    res.push(`search=${search}`);
  }

  // sorting

  if (sortFields.first_name) {
    res.push(
      `sort=${sortFields.first_name.includes('-') ? '-' : ''}last_name,${
        sortFields.first_name
      }`
    );
  }

  if (filters.approved) {
    res.push(`report_status=${ReportStatus.APPROVED}`);
  }
  if (filters.rejected) {
    res.push(`report_status=${ReportStatus.REJECTED}`);
  }
  if (filters.delayed) {
    res.push(`report_status=${ReportStatus.DELAYED}`);
  }
  if (filters.brokenReport) {
    res.push(`report_status=${ReportStatus.BROKEN}`);
  }
  if (filters.protocolBroken) {
    res.push(`protocol_status=${ProtocolStatus.BROKEN}`);
  }
  if (filters.protocolUpdated) {
    res.push(`protocol_status=${ProtocolStatus.UPDATED}`);
  }
  if (filters.protocolApproved) {
    res.push(`protocol_status=${ProtocolStatus.APPROVED}`);
  }
  if (filters.protocolNotSpecified) {
    res.push(`protocol_status=${ProtocolStatus.NOT_SPECIFIED}`);
  }
  if (filters.archived) {
    res.push(`eeg.is_archived=${filters.archived}`);
  }
  filters.organizations.forEach((org) => {
    res.push(`organization=${org.value}`);
  });

  return res.join('&');
};
