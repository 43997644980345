import React, { FC } from 'react';

interface HomeIconProps {
  isActive?: boolean;
}

const PPMDonwloadsHistoryIcon: FC<HomeIconProps> = ({
  isActive,
}): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.416 4H5.61538C4.72463 4 4 4.68995 4 5.53809V19.9619C4 20.81 4.72463 21.5 5.61538 21.5H16.3846C17.2754 21.5 18 20.81 18 19.9619V10.9C17.6769 10.9656 17.3425 11 17 11C14.2386 11 12 8.76142 12 6C12 5.2889 12.1484 4.61246 12.416 4ZM14.2308 11.2119H7.76923C7.47161 11.2119 7.23077 11.4412 7.23077 11.7246C7.23077 12.008 7.47161 12.2373 7.76923 12.2373H14.2308C14.5284 12.2373 14.7692 12.008 14.7692 11.7246C14.7692 11.4412 14.5284 11.2119 14.2308 11.2119ZM14.2308 13.2627H7.76923C7.47161 13.2627 7.23077 13.492 7.23077 13.7754C7.23077 14.0588 7.47161 14.2881 7.76923 14.2881H14.2308C14.5284 14.2881 14.7692 14.0588 14.7692 13.7754C14.7692 13.492 14.5284 13.2627 14.2308 13.2627ZM14.2308 15.3135H7.76923C7.47161 15.3135 7.23077 15.5428 7.23077 15.8262C7.23077 16.1096 7.47161 16.3389 7.76923 16.3389H14.2308C14.5284 16.3389 14.7692 16.1096 14.7692 15.8262C14.7692 15.5428 14.5284 15.3135 14.2308 15.3135ZM12.0769 17.3643H7.76923C7.47161 17.3643 7.23077 17.5936 7.23077 17.877C7.23077 18.1603 7.47161 18.3896 7.76923 18.3896H12.0769C12.3745 18.3896 12.6154 18.1603 12.6154 17.877C12.6154 17.5936 12.3745 17.3643 12.0769 17.3643Z"
      fill={isActive ? '#FFDE6E' : '#FBFBFB'}
    />
    <path
      d="M17 2C14.7945 2 13 3.79446 13 6C13 8.20554 14.7945 10 17 10C19.2055 10 21 8.20554 21 6C21 3.79446 19.2055 2 17 2ZM18.512 7.512C18.392 7.632 18.2345 7.69231 18.0769 7.69231C17.9194 7.69231 17.7618 7.632 17.6418 7.512L16.5649 6.43508C16.4492 6.32 16.3846 6.16369 16.3846 6V4.30769C16.3846 3.96738 16.6597 3.69231 17 3.69231C17.3403 3.69231 17.6154 3.96738 17.6154 4.30769V5.74523L18.512 6.64185C18.7526 6.88246 18.7526 7.27138 18.512 7.512Z"
      fill={isActive ? '#FFDE6E' : '#FBFBFB'}
    />
  </svg>
);

export default PPMDonwloadsHistoryIcon;
