import React, { useContext } from 'react';

import { ROLES_VALUES } from '../../constants';
import { UserContext } from '../../context/UserContext';
import { UserRole } from '../../types';

const SidebarMenuUserInfo: React.FunctionComponent = () => {
  const { user } = useContext(UserContext);

  return (
    <span className="text-white opacity-50 rounded-xl font-inter font-normal text-0.75rem text-center py-1 px-2 cursor-default">
      {ROLES_VALUES[user?.role as keyof typeof UserRole]}
    </span>
  );
};

export default SidebarMenuUserInfo;
