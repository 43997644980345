import React, { ChangeEventHandler, useMemo, useState } from 'react';

import { clientReportsTableFiltersInitialValues } from '../constants/table';
import { usePageData } from '../hooks/usePageData';
import { ClientReportsFilter } from '../types';
import { serializeClientReportsTableParams } from '../utils/client';

export interface ClientReportsTableContextProps {
  queryString: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  setSearchTerm: (v: string) => void;
  handleFilters: (values: ClientReportsFilter) => void;
  totalPageCount: number;
  setTotalPageCount: (pageCount: number) => void;
  hasNext: boolean;
  setHasNext: (value: boolean) => void;
  hasPrev: boolean;
  setHasPrev: (value: boolean) => void;
  pageData: ClientReportsPageData;
  setPageData: (v: ClientReportsPageData) => void;
  clearClientReportsTableContextState: () => void;
}

export type ClientReportsPageData = {
  searchTerm: string | null;
  filters: ClientReportsFilter;
  currentPage: number;
  sortFields: {
    recording_date: string;
  };
};

const pageDataInitialValues: ClientReportsPageData = {
  searchTerm: null,
  filters: clientReportsTableFiltersInitialValues,
  currentPage: 1,
  sortFields: {
    recording_date: '',
  },
};

export const ClientReportsTableContext = React.createContext<ClientReportsTableContextProps>(
  {
    queryString: '',
    handleChange: () => undefined,
    handleFilters: () => undefined,
    setSearchTerm: () => undefined,
    totalPageCount: 0,
    setTotalPageCount: () => undefined,
    hasNext: false,
    setHasNext: () => undefined,
    hasPrev: false,
    setHasPrev: () => undefined,
    pageData: pageDataInitialValues,
    setPageData: () => undefined,
    clearClientReportsTableContextState: () => undefined,
  }
);

interface ClientReportsProviderProps {
  children: React.ReactNode;
}

export const ClientReportsProvider: React.FunctionComponent<ClientReportsProviderProps> = (
  props
) => {
  const { children } = props;

  const [pageData, setPageData] = useState<ClientReportsPageData>(
    pageDataInitialValues
  );

  const {
    totalPageCount,
    setTotalPageCount,
    hasNext,
    hasPrev,
    setHasNext,
    setHasPrev,
    handleChange,
    handleFilters,
    setSearchTerm,
  } = usePageData(pageData, setPageData);

  const queryString = useMemo(
    () =>
      serializeClientReportsTableParams(
        pageData.searchTerm,
        pageData.filters,
        pageData.sortFields
      ),
    [pageData.searchTerm, pageData.filters, pageData.sortFields]
  );

  const clientReportsTableData = useMemo(() => {
    const clearClientReportsTableContextState = () => {
      setTotalPageCount(0);
      setHasNext(false);
      setHasPrev(false);
      setPageData(pageDataInitialValues);
    };
    return {
      setSearchTerm,
      queryString,
      handleChange,
      handleFilters,
      totalPageCount,
      setTotalPageCount,
      hasNext,
      setHasNext,
      hasPrev,
      setHasPrev,
      pageData,
      setPageData,
      clearClientReportsTableContextState,
    };
  }, [
    setSearchTerm,
    queryString,
    handleChange,
    handleFilters,
    totalPageCount,
    setTotalPageCount,
    hasNext,
    setHasNext,
    hasPrev,
    setHasPrev,
    pageData,
    setPageData,
  ]);

  return (
    <ClientReportsTableContext.Provider value={clientReportsTableData}>
      {children}
    </ClientReportsTableContext.Provider>
  );
};
