import { ChangeEventHandler, useEffect } from 'react';

interface Result {
  handleFilters: (filtersToSet: any) => void;
  handleInputSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleInputSearchClick: () => void;
}

export const usePageData = (
  pageData: any,
  setPageData: (value: any) => void
): Result => {
  useEffect(() => {
    if (!pageData.searchInputValue && pageData.searchTerm) {
      setPageData({
        ...pageData,
        searchTerm: '',
      });
    }
  }, [pageData, setPageData]);

  const handleInputSearchChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (pageData.currentPage !== 1) {
      setPageData({
        ...pageData,
        searchInputValue: event.target.value,
      });
      return;
    }
    setPageData({
      ...pageData,
      searchInputValue: event.target.value,
    });
  };

  const handleInputSearchClick = () => {
    setPageData({
      ...pageData,
      currentPage: 1,
      searchTerm: pageData.searchInputValue,
    });
  };

  const handleFilters = (filtersToSet: any) => {
    setPageData({
      ...pageData,
      currentPage: 1,
      filters: filtersToSet,
    });
  };

  return {
    handleInputSearchChange,
    handleInputSearchClick,
    handleFilters,
  };
};
