import React from 'react';

import defaultAvatar from '../assets/icons/default-avatar-icon.svg';
import LazyImage from './LazyImage';

interface AvatarProps {
  src: string;
  imageStyles: string;
}

const Avatar: React.FunctionComponent<AvatarProps> = ({ src, imageStyles }) => {
  if (!src) src = defaultAvatar;

  return <LazyImage src={src} alt="Avatar" imageStyles={imageStyles} />;
};

export default Avatar;
