import {
  STORE_ADMIN_ROUTES,
  WN_ADMIN_ROUTES,
  EXECUTIVE_ROUTES,
  STORE_USER_AND_WN_SALES_ROUTES,
  EEG_LAB_USER_ROUTES,
  CLIENT_ROUTES,
} from '../constants';
import { RouteType, UserRole } from '../types';

export const getRoleBasedRoutes = (role: UserRole): RouteType[] => {
  switch (role) {
    case UserRole.EXECUTIVE:
      return EXECUTIVE_ROUTES;
    case UserRole.STORE_ADMIN:
      return STORE_ADMIN_ROUTES;
    case UserRole.STORE_USER:
      return STORE_USER_AND_WN_SALES_ROUTES;
    case UserRole.WN_ADMIN:
      return WN_ADMIN_ROUTES;
    case UserRole.WN_SALES:
      return STORE_USER_AND_WN_SALES_ROUTES;
    case UserRole.EEG_LAB_USER:
      return EEG_LAB_USER_ROUTES;
    case UserRole.CLIENT:
      return CLIENT_ROUTES;
    default:
      return [];
  }
};

const setSubroutes = (parent: RouteType, child: RouteType) => {
  if (parent.hasOwnProperty('subroutes')) {
    parent.subroutes!.push(child);
  } else {
    parent.subroutes = [child];
  }
};

const formatRoutesForSideMenu = (routes: RouteType[]) => {
  const routesClone = routes.map((route) => ({ ...route }));
  for (let i = 0; i < routesClone.length - 1; i++) {
    const routeA = routesClone[i];
    for (let j = i + 1; j < routesClone.length; j++) {
      const routeB = routesClone[j];
      if (routeA.id === routeB?.sidemenuParentId) {
        setSubroutes(routeA, routeB);
      } else if (routeB.id === routeA?.sidemenuParentId) {
        setSubroutes(routeB, routeA);
      }
    }
  }

  return routesClone.filter((x) => !x.sidemenuParentId);
};

export const getRoleBasedSideMenuRoutes = (role: UserRole): RouteType[] => {
  switch (role) {
    case UserRole.EXECUTIVE:
      return formatRoutesForSideMenu(EXECUTIVE_ROUTES);
    case UserRole.STORE_ADMIN:
      return formatRoutesForSideMenu(STORE_ADMIN_ROUTES);
    case UserRole.STORE_USER:
      return formatRoutesForSideMenu(STORE_USER_AND_WN_SALES_ROUTES);
    case UserRole.WN_ADMIN:
      return formatRoutesForSideMenu(WN_ADMIN_ROUTES);
    case UserRole.WN_SALES:
      return formatRoutesForSideMenu(STORE_USER_AND_WN_SALES_ROUTES);
    case UserRole.EEG_LAB_USER:
      return formatRoutesForSideMenu(EEG_LAB_USER_ROUTES);
    case UserRole.CLIENT:
      return formatRoutesForSideMenu(CLIENT_ROUTES);
    default:
      return [];
  }
};

export const getPathWithoutParams = (path: string) =>
  path.replace(/(\/[0-9]+|\/:id)(?=[^/]*$)/, '');
