import React from 'react';

interface HomeIconProps {
  isActive?: boolean;
}

const HomeIcon: React.FunctionComponent<HomeIconProps> = ({
  isActive,
  children,
}): JSX.Element => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1068 12.3525L12.474 3.69713C12.4119 3.63464 12.3381 3.58506 12.2568 3.55124C12.1755 3.51741 12.0884 3.5 12.0004 3.5C11.9124 3.5 11.8253 3.51741 11.744 3.55124C11.6627 3.58506 11.5889 3.63464 11.5267 3.69713L2.89402 12.3525C2.64252 12.6049 2.5 12.9476 2.5 13.3051C2.5 14.0474 3.1015 14.651 3.84133 14.651H4.75092V20.8271C4.75092 21.1993 5.05062 21.5 5.42159 21.5H10.6591V16.7896H13.0064V21.5H18.5792C18.9502 21.5 19.2499 21.1993 19.2499 20.8271V14.651H20.1595C20.5157 14.651 20.8574 14.5101 21.1089 14.2556C21.6307 13.7299 21.6307 12.8782 21.1068 12.3525Z"
      fill={isActive ? '#FFDE6E' : '#FBFBFB'}
    />
  </svg>
);

export default HomeIcon;
