import React from 'react';

interface NotFoundIconProps {
  width?: string;
  height?: string;
}

const NotFoundIcon: React.FunctionComponent<NotFoundIconProps> = ({
  height = '414',
  width = '460',
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 460 414"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="460" height="414" fill="white" />
    <path
      d="M66.221 104.749L68.1571 108.656L72.4867 109.282L69.3545 112.324L70.0944 116.618L66.2222 114.59L62.35 116.618L63.09 112.324L59.9578 109.282L64.2873 108.656L66.221 104.749Z"
      fill="#EBEBEB"
    />
    <path
      d="M453.734 288.718L455.67 292.625L460 293.252L456.868 296.293L457.608 300.587L453.736 298.559L449.863 300.587L450.603 296.293L447.471 293.252L451.801 292.625L453.734 288.718Z"
      fill="#F5F5F5"
    />
    <path
      d="M376.985 402.131L378.921 406.038L383.251 406.664L380.119 409.706L380.858 414L376.986 411.972L373.114 414L373.854 409.706L370.722 406.664L375.051 406.038L376.985 402.131Z"
      fill="#F5F5F5"
    />
    <path
      d="M201.39 35.4565L202.108 36.9069L203.715 37.1395C203.806 37.1519 203.892 37.1897 203.962 37.2485C204.033 37.3074 204.086 37.3849 204.114 37.4722C204.142 37.5595 204.145 37.6529 204.122 37.7418C204.1 37.8306 204.052 37.9113 203.985 37.9745L202.823 39.1028L203.097 40.6976C203.113 40.7872 203.103 40.8793 203.069 40.9636C203.034 41.0479 202.977 41.1209 202.903 41.1745C202.829 41.228 202.742 41.2598 202.651 41.2664C202.56 41.273 202.469 41.254 202.388 41.2117L200.951 40.4578L199.515 41.2117C199.434 41.2539 199.343 41.2727 199.252 41.2661C199.161 41.2595 199.073 41.2276 198.999 41.1742C198.926 41.1207 198.868 41.0478 198.834 40.9635C198.799 40.8793 198.789 40.7872 198.805 40.6976L199.08 39.1028L197.916 37.9745C197.85 37.911 197.803 37.8303 197.78 37.7414C197.758 37.6526 197.761 37.5593 197.79 37.4722C197.818 37.3851 197.87 37.3077 197.941 37.2489C198.011 37.19 198.097 37.1521 198.188 37.1395L199.795 36.9069L200.513 35.4565C200.554 35.3754 200.616 35.3071 200.694 35.2594C200.771 35.2117 200.86 35.1864 200.951 35.1864C201.042 35.1864 201.132 35.2117 201.209 35.2594C201.286 35.3071 201.349 35.3754 201.39 35.4565Z"
      fill="#EBEBEB"
    />
    <path
      d="M3.62784 383.572L4.34623 385.024L5.95306 385.262C6.04427 385.275 6.1301 385.313 6.20068 385.371C6.27126 385.43 6.32372 385.508 6.352 385.595C6.38028 385.682 6.38324 385.776 6.36053 385.865C6.33783 385.954 6.29038 386.034 6.22366 386.097L5.06104 387.227L5.33164 388.812C5.34703 388.902 5.33697 388.994 5.30261 389.078C5.26826 389.162 5.21097 389.235 5.13723 389.288C5.06349 389.341 4.97623 389.373 4.88533 389.38C4.79442 389.386 4.7035 389.367 4.62282 389.325L3.18602 388.572L1.74922 389.325C1.66854 389.367 1.57761 389.386 1.48671 389.38C1.3958 389.373 1.30855 389.341 1.2348 389.288C1.16106 389.235 1.10378 389.162 1.06942 389.078C1.03506 388.994 1.02501 388.902 1.04039 388.812L1.31458 387.217L0.15197 386.088C0.0852511 386.025 0.0378029 385.944 0.0150967 385.855C-0.00760955 385.766 -0.00465177 385.673 0.0236287 385.586C0.0519092 385.498 0.104363 385.421 0.174945 385.362C0.245526 385.303 0.331362 385.265 0.422565 385.253L2.02939 385.014L2.74779 383.563C2.78894 383.481 2.85228 383.413 2.9306 383.365C3.00893 383.317 3.09908 383.293 3.19081 383.294C3.28253 383.295 3.37212 383.321 3.44939 383.371C3.52665 383.42 3.58849 383.49 3.62784 383.572Z"
      fill="#F5F5F5"
    />
    <path
      d="M35.9295 20.1246L36.648 21.575L38.2548 21.8076C38.346 21.82 38.4318 21.8578 38.5024 21.9166C38.573 21.9755 38.6254 22.053 38.6537 22.1403C38.682 22.2275 38.685 22.321 38.6622 22.4098C38.6395 22.4987 38.5921 22.5794 38.5254 22.6425L37.3628 23.7709L37.6381 25.3657C37.6535 25.4553 37.6434 25.5474 37.6089 25.6316C37.5745 25.7158 37.5171 25.7888 37.4433 25.8422C37.3695 25.8957 37.2821 25.9275 37.1911 25.9342C37.1001 25.9408 37.009 25.9219 36.9281 25.8797L35.4913 25.1271L34.0545 25.8797C33.9737 25.9221 33.8827 25.9411 33.7916 25.9345C33.7006 25.9279 33.6132 25.896 33.5394 25.8425C33.4657 25.789 33.4084 25.716 33.3741 25.6317C33.3399 25.5474 33.33 25.4552 33.3457 25.3657L33.6199 23.7709L32.4573 22.6425C32.3906 22.5794 32.3431 22.4987 32.3204 22.4098C32.2977 22.321 32.3006 22.2275 32.3289 22.1403C32.3572 22.053 32.4097 21.9755 32.4802 21.9166C32.5508 21.8578 32.6367 21.82 32.7279 21.8076L34.3347 21.575L35.0531 20.1246C35.0937 20.0434 35.1563 19.9752 35.2337 19.9275C35.3111 19.8798 35.4003 19.8545 35.4913 19.8545C35.5823 19.8545 35.6716 19.8798 35.749 19.9275C35.8264 19.9752 35.8889 20.0434 35.9295 20.1246Z"
      fill="#EBEBEB"
    />
    <path
      d="M33.9851 307.607C33.9851 307.92 33.892 308.226 33.7174 308.486C33.5429 308.746 33.2948 308.949 33.0045 309.069C32.7143 309.189 32.3949 309.22 32.0867 309.159C31.7785 309.098 31.4954 308.947 31.2732 308.726C31.0509 308.505 30.8995 308.223 30.838 307.916C30.7766 307.609 30.8079 307.291 30.928 307.002C31.0481 306.712 31.2515 306.465 31.5126 306.291C31.7738 306.117 32.0808 306.024 32.395 306.024C32.8165 306.024 33.2208 306.191 33.519 306.487C33.8171 306.784 33.9848 307.187 33.9851 307.607Z"
      fill="#EBEBEB"
    />
    <path
      d="M260.928 109.099C260.928 109.412 260.835 109.718 260.661 109.978C260.486 110.238 260.238 110.441 259.947 110.561C259.657 110.681 259.338 110.712 259.029 110.651C258.721 110.59 258.438 110.439 258.216 110.218C257.994 109.997 257.842 109.714 257.781 109.407C257.72 109.1 257.751 108.782 257.872 108.493C257.992 108.204 258.195 107.957 258.457 107.783C258.718 107.609 259.025 107.516 259.339 107.516C259.761 107.516 260.165 107.683 260.463 107.979C260.761 108.276 260.928 108.679 260.928 109.099Z"
      fill="#EBEBEB"
    />
    <path
      d="M178.355 21.4366C178.355 21.7497 178.262 22.0557 178.087 22.316C177.913 22.5763 177.665 22.7792 177.374 22.899C177.084 23.0188 176.765 23.0501 176.456 22.989C176.148 22.928 175.865 22.7772 175.643 22.5559C175.421 22.3345 175.269 22.0525 175.208 21.7454C175.147 21.4384 175.178 21.1201 175.298 20.8309C175.419 20.5417 175.622 20.2945 175.884 20.1206C176.145 19.9467 176.452 19.8538 176.766 19.8538C177.188 19.8541 177.592 20.021 177.89 20.3178C178.187 20.6145 178.355 21.0169 178.355 21.4366Z"
      fill="#F5F5F5"
    />
    <path
      d="M180.884 408.047C185.612 406.931 188.543 402.207 187.43 397.495C186.317 392.783 181.582 389.868 176.854 390.984C172.126 392.099 169.195 396.823 170.308 401.535C171.42 406.247 176.156 409.162 180.884 408.047Z"
      fill="#F0F0F0"
    />
    <path
      d="M175.832 394.406C174.03 394.403 172.273 394.971 170.816 396.028C170.073 397.729 169.892 399.62 170.298 401.43C170.703 403.24 171.675 404.874 173.073 406.098C174.471 407.323 176.223 408.073 178.077 408.242C179.93 408.411 181.79 407.989 183.388 407.038C183.376 407.026 183.376 407.026 183.388 407.026C184.093 405.723 184.447 404.259 184.415 402.779C184.383 401.299 183.966 399.852 183.205 398.58C182.444 397.308 181.364 396.255 180.072 395.524C178.78 394.792 177.319 394.407 175.832 394.406Z"
      fill="#E6E6E6"
    />
    <path
      d="M107.68 247.164H34.8162V214.393L107.68 128.111H142.541V216.257H160.61V247.17H142.541V273.974H107.68V247.164ZM107.68 216.251V171.119L69.1797 216.251H107.68Z"
      fill="#42CDDD"
    />
    <path
      d="M173.079 201.675C173.079 174.415 178.005 155.34 187.857 144.448C197.71 133.556 212.716 128.109 232.877 128.107C242.566 128.107 250.52 129.297 256.74 131.677C262.396 133.71 267.575 136.873 271.961 140.971C275.656 144.483 278.786 148.542 281.24 153.004C283.669 157.665 285.501 162.611 286.691 167.727C289.405 178.673 290.757 189.909 290.717 201.184C290.717 227.204 286.298 246.249 277.46 258.318C268.622 270.386 253.401 276.418 231.797 276.414C219.687 276.414 209.899 274.49 202.436 270.643C194.907 266.737 188.566 260.893 184.073 253.719C180.601 248.436 177.901 241.214 175.971 232.051C173.959 222.052 172.99 211.873 173.079 201.675ZM212.751 201.773C212.751 220.033 214.371 232.505 217.611 239.191C220.851 245.877 225.548 249.219 231.703 249.218C233.657 249.24 235.597 248.875 237.409 248.145C239.221 247.414 240.869 246.332 242.258 244.962C245.237 242.126 247.432 237.642 248.844 231.512C250.255 225.382 250.959 215.828 250.955 202.849C250.955 183.806 249.335 171.007 246.095 164.453C242.855 157.899 237.993 154.622 231.511 154.621C224.898 154.621 220.119 157.964 217.174 164.649C214.229 171.334 212.756 183.709 212.754 201.773H212.751Z"
      fill="#42CDDD"
    />
    <path
      d="M375.96 247.164H303.096V214.392L375.96 128.11H410.821V216.257H428.888V247.17H410.821V273.974H375.96V247.164ZM375.96 216.251V171.118L337.459 216.251H375.96Z"
      fill="#42CDDD"
    />
    <path
      opacity="0.2"
      d="M402.728 128.107C370.532 149.78 328.852 178.359 317.346 247.158H314.891C326.086 179.193 366.245 149.863 398.453 128.107H402.728Z"
      fill="black"
    />
    <path
      d="M236.201 343.328C215.882 343.328 195.72 334.189 185.93 319.249C179.88 310.018 172.855 290.682 193.778 264.885L195.641 266.385C180.84 284.635 178.103 302.944 187.934 317.944C199.901 336.205 228.165 345.385 252.268 338.853C276.966 332.156 291.962 310.754 293.41 280.133C298.36 175.4 354.215 143.138 394.999 119.586C420.337 104.949 438.644 94.375 434.62 72.0677C434.062 68.9665 432.824 66.8338 430.833 65.5289C425.621 62.108 415.444 64.4733 403.661 67.2083C380.545 72.5758 348.887 79.9209 330.024 47.9652L332.089 46.7557C350.056 77.199 379.476 70.3727 403.113 64.886C415.434 62.0233 426.075 59.5578 432.146 63.5369C434.714 65.2223 436.293 67.8739 436.974 71.6478C441.295 95.6012 421.393 107.097 396.196 121.651C355.893 144.926 300.691 176.811 295.806 280.245C294.307 311.973 278.668 334.169 252.902 341.153C247.456 342.611 241.84 343.343 236.201 343.328Z"
      fill="#407BFF"
    />
    <path
      d="M236.201 343.328C215.882 343.328 195.72 334.189 185.93 319.249C179.88 310.018 172.855 290.682 193.778 264.885L195.641 266.385C180.84 284.635 178.103 302.944 187.934 317.944C199.901 336.205 228.165 345.385 252.268 338.853C276.966 332.156 291.962 310.754 293.41 280.133C298.36 175.4 354.215 143.138 394.999 119.586C420.337 104.949 438.644 94.375 434.62 72.0677C434.062 68.9665 432.824 66.8338 430.833 65.5289C425.621 62.108 415.444 64.4733 403.661 67.2083C380.545 72.5758 348.887 79.9209 330.024 47.9652L332.089 46.7557C350.056 77.199 379.476 70.3727 403.113 64.886C415.434 62.0233 426.075 59.5578 432.146 63.5369C434.714 65.2223 436.293 67.8739 436.974 71.6478C441.295 95.6012 421.393 107.097 396.196 121.651C355.893 144.926 300.691 176.811 295.806 280.245C294.307 311.973 278.668 334.169 252.902 341.153C247.456 342.611 241.84 343.343 236.201 343.328Z"
      fill="#C4C4C4"
    />
    <path
      d="M305.334 19.7059C310.852 19.7408 316.334 20.5988 321.596 22.2513C321.596 22.2513 334.724 44.6398 336.324 49.7651C335.772 54.8225 327.206 63.1135 327.206 63.1135L305.334 19.7059Z"
      fill="#407BFF"
    />
    <path
      d="M305.334 19.7059C310.852 19.7408 316.334 20.5988 321.596 22.2513C321.596 22.2513 334.724 44.6398 336.324 49.7651C335.772 54.8225 327.206 63.1135 327.206 63.1135L305.334 19.7059Z"
      fill="#C4C4C4"
    />
    <path
      d="M344.345 128.467C339.275 122.286 334.65 115.755 330.505 108.925C329.499 107.197 328.545 105.442 327.644 103.657C327.132 102.645 326.635 101.624 326.155 100.595C325.526 99.6011 325.043 98.5233 324.718 97.3939C323.281 82.2791 328.474 71.1528 323.488 59.0199L303.766 66.699C303.766 66.699 305.442 88.3133 309.273 101.245C311.649 109.268 317.025 116.214 322.205 122.64C323.826 124.651 325.388 126.708 327.006 128.723C328.537 130.632 330.253 132.379 331.788 134.275C334.119 137.153 334.901 139.901 333.123 143.293C333.029 143.476 332.929 143.651 332.827 143.834C332.325 144.719 334.68 145.719 336.157 143.859C338.602 140.78 338.218 140.97 340.302 138.344C341.569 136.751 343.022 135.03 344.276 133.534C344.87 132.826 345.201 131.935 345.214 131.013C345.226 130.09 344.919 129.191 344.345 128.467Z"
      fill="#8EF3FF"
    />
    <path
      d="M344.345 128.467C339.275 122.286 334.65 115.755 330.505 108.925C329.499 107.197 328.545 105.442 327.644 103.657C327.132 102.645 326.635 101.624 326.155 100.595C325.526 99.6011 325.043 98.5233 324.718 97.3939C323.281 82.2791 328.474 71.1528 323.488 59.0199L303.766 66.699C303.766 66.699 305.442 88.3133 309.273 101.245C311.649 109.268 317.025 116.214 322.205 122.64C323.826 124.651 325.388 126.708 327.006 128.723C328.537 130.632 330.253 132.379 331.788 134.275C334.119 137.153 334.901 139.901 333.123 143.293C333.029 143.476 332.929 143.651 332.827 143.834C332.325 144.719 334.68 145.719 336.157 143.859C338.602 140.78 338.218 140.97 340.302 138.344C341.569 136.751 343.022 135.03 344.276 133.534C344.87 132.826 345.201 131.935 345.214 131.013C345.226 130.09 344.919 129.191 344.345 128.467Z"
      fill="#E6E6E6"
    />
    <path
      opacity="0.3"
      d="M339.523 122.248C336.392 125.594 332.915 128.602 329.149 131.221C329.663 131.792 330.174 132.376 330.685 132.959C334.452 130.428 337.873 127.421 340.863 124.013C340.416 123.43 339.97 122.838 339.523 122.248Z"
      fill="#C4C4C4"
    />
    <path
      opacity="0.3"
      d="M344.34 128.471C344.294 128.409 344.246 128.358 344.201 128.298C343.276 129.347 342.741 130.682 342.686 132.078C342.6 133.035 342.756 133.999 343.139 134.882C343.536 134.421 343.909 133.966 344.275 133.533C344.869 132.825 345.2 131.935 345.212 131.013C345.223 130.091 344.915 129.193 344.34 128.471Z"
      fill="#C4C4C4"
    />
    <path
      d="M300.645 34.0216C298.499 37.2103 295.932 40.0966 293.012 42.6024C291.388 43.9802 289.614 45.1721 287.724 46.1557C286.726 46.6622 285.692 47.0948 284.631 47.4499L283.823 47.7087L283.562 47.7898C283.374 47.8471 283.182 47.8984 282.992 47.9437C282.647 48.0217 282.298 48.0763 281.946 48.1071C280.934 48.1856 279.916 48.0912 278.935 47.828C277.701 47.4829 276.519 46.9764 275.419 46.3215C274.528 45.8076 273.667 45.2445 272.84 44.6349C271.34 43.5084 269.91 42.294 268.555 40.9982C265.942 38.5268 263.542 35.8394 261.383 32.9648C260.964 32.4041 260.755 31.7149 260.792 31.0166C260.829 30.3183 261.11 29.6549 261.587 29.1415C262.063 28.628 262.705 28.2969 263.401 28.2055C264.097 28.1141 264.803 28.2682 265.397 28.641L265.488 28.6994C268.314 30.4886 271.164 32.3708 273.946 34.0574C275.352 34.8828 276.724 35.713 278.072 36.3595C278.689 36.6782 279.324 36.9633 279.972 37.2135C280.38 37.3935 280.816 37.5012 281.262 37.532C281.325 37.532 281.251 37.4437 280.809 37.4592C280.671 37.4665 280.534 37.4844 280.398 37.5129C280.31 37.5298 280.223 37.5521 280.137 37.5797C280.055 37.6071 280.172 37.557 280.179 37.5498L280.576 37.3471C281.108 37.0635 281.623 36.7497 282.119 36.4072C283.188 35.6519 284.19 34.8058 285.112 33.8785C287.2 31.6921 289.159 29.3874 290.979 26.9759L291.007 26.9425C291.962 25.7039 293.366 24.8879 294.918 24.6698C296.471 24.4516 298.047 24.8487 299.308 25.7757C300.57 26.7028 301.416 28.0858 301.665 29.6276C301.914 31.1694 301.546 32.7471 300.64 34.0216H300.645Z"
      fill="#407BFF"
    />
    <path
      d="M300.645 34.0216C298.499 37.2103 295.932 40.0966 293.012 42.6024C291.388 43.9802 289.614 45.1721 287.724 46.1557C286.726 46.6622 285.692 47.0948 284.631 47.4499L283.823 47.7087L283.562 47.7898C283.374 47.8471 283.182 47.8984 282.992 47.9437C282.647 48.0217 282.298 48.0763 281.946 48.1071C280.934 48.1856 279.916 48.0912 278.935 47.828C277.701 47.4829 276.519 46.9764 275.419 46.3215C274.528 45.8076 273.667 45.2445 272.84 44.6349C271.34 43.5084 269.91 42.294 268.555 40.9982C265.942 38.5268 263.542 35.8394 261.383 32.9648C260.964 32.4041 260.755 31.7149 260.792 31.0166C260.829 30.3183 261.11 29.6549 261.587 29.1415C262.063 28.628 262.705 28.2969 263.401 28.2055C264.097 28.1141 264.803 28.2682 265.397 28.641L265.488 28.6994C268.314 30.4886 271.164 32.3708 273.946 34.0574C275.352 34.8828 276.724 35.713 278.072 36.3595C278.689 36.6782 279.324 36.9633 279.972 37.2135C280.38 37.3935 280.816 37.5012 281.262 37.532C281.325 37.532 281.251 37.4437 280.809 37.4592C280.671 37.4665 280.534 37.4844 280.398 37.5129C280.31 37.5298 280.223 37.5521 280.137 37.5797C280.055 37.6071 280.172 37.557 280.179 37.5498L280.576 37.3471C281.108 37.0635 281.623 36.7497 282.119 36.4072C283.188 35.6519 284.19 34.8058 285.112 33.8785C287.2 31.6921 289.159 29.3874 290.979 26.9759L291.007 26.9425C291.962 25.7039 293.366 24.8879 294.918 24.6698C296.471 24.4516 298.047 24.8487 299.308 25.7757C300.57 26.7028 301.416 28.0858 301.665 29.6276C301.914 31.1694 301.546 32.7471 300.64 34.0216H300.645Z"
      fill="#E6E6E6"
    />
    <path
      d="M256.874 26.2268L258.278 28.5718C258.278 28.5718 259.349 31.6921 261.494 32.2694L267.309 30.3932L267.018 29.9065H267.027C266.282 28.7782 266.362 26.6002 266.624 24.7883C266.886 22.9765 265.942 22.9121 265.245 23.363C264.748 23.9289 264.4 24.6082 264.231 25.3406C263.948 24.945 263.634 24.5725 263.292 24.2265L261.516 22.4648C261.147 22.0985 260.652 21.8863 260.131 21.8717C259.61 21.857 259.104 22.041 258.715 22.3861L257.278 23.66C256.921 23.974 256.685 24.4023 256.612 24.871C256.538 25.3396 256.631 25.8192 256.874 26.2268Z"
      fill="#407BFF"
    />
    <path
      d="M256.874 26.2268L258.278 28.5718C258.278 28.5718 259.349 31.6921 261.494 32.2694L267.309 30.3932L267.018 29.9065H267.027C266.282 28.7782 266.362 26.6002 266.624 24.7883C266.886 22.9765 265.942 22.9121 265.245 23.363C264.748 23.9289 264.4 24.6082 264.231 25.3406C263.948 24.945 263.634 24.5725 263.292 24.2265L261.516 22.4648C261.147 22.0985 260.652 21.8863 260.131 21.8717C259.61 21.857 259.104 22.041 258.715 22.3861L257.278 23.66C256.921 23.974 256.685 24.4023 256.612 24.871C256.538 25.3396 256.631 25.8192 256.874 26.2268Z"
      fill="#C4C4C4"
    />
    <path
      d="M311.22 17.6484C304.76 19.346 298.573 21.9433 292.842 25.3633C291.95 25.9204 291.25 26.736 290.837 27.7008C290.423 28.6656 290.316 29.7333 290.529 30.7606C292.833 42.0193 298.089 57.1031 303.764 66.6978L330.239 55.7875C330.421 51.1357 323.993 36.0828 317.446 21.5333C316.269 18.9176 314.017 16.9733 311.22 17.6484Z"
      fill="#407BFF"
    />
    <path
      d="M311.22 17.6484C304.76 19.346 298.573 21.9433 292.842 25.3633C291.95 25.9204 291.25 26.736 290.837 27.7008C290.423 28.6656 290.316 29.7333 290.529 30.7606C292.833 42.0193 298.089 57.1031 303.764 66.6978L330.239 55.7875C330.421 51.1357 323.993 36.0828 317.446 21.5333C316.269 18.9176 314.017 16.9733 311.22 17.6484Z"
      fill="#DEDFDF"
    />
    <path
      d="M321.548 30.757L316.296 29.0024C317.47 32.0631 321.723 35.9444 324.712 38.225C323.721 35.8323 322.653 33.331 321.548 30.757Z"
      fill="#C4C4C4"
    />
    <path
      d="M309.476 5.83514C307.291 1.68427 302.562 -0.406661 296.885 0.0656783C292.095 0.464066 287.852 5.33298 288.356 7.96305C288.86 10.5931 292.887 11.7096 293.617 12.6232L290.297 15.0207C289.904 15.3032 289.574 15.6618 289.324 16.075C289.074 16.4881 288.911 16.9473 288.844 17.4249C288.777 17.9025 288.807 18.3887 288.933 18.8543C289.06 19.3199 289.279 19.7552 289.578 20.1341C290.975 21.9018 292.811 23.7483 293.889 25.052C303.065 24.8134 309.852 21.3269 312.298 17.9716C311.421 13.7027 311.649 9.96453 309.476 5.83514Z"
      fill="#407BFF"
    />
    <path
      d="M309.476 5.83514C307.291 1.68427 302.562 -0.406661 296.885 0.0656783C292.095 0.464066 287.852 5.33298 288.356 7.96305C288.86 10.5931 292.887 11.7096 293.617 12.6232L290.297 15.0207C289.904 15.3032 289.574 15.6618 289.324 16.075C289.074 16.4881 288.911 16.9473 288.844 17.4249C288.777 17.9025 288.807 18.3887 288.933 18.8543C289.06 19.3199 289.279 19.7552 289.578 20.1341C290.975 21.9018 292.811 23.7483 293.889 25.052C303.065 24.8134 309.852 21.3269 312.298 17.9716C311.421 13.7027 311.649 9.96453 309.476 5.83514Z"
      fill="#DEDFDF"
    />
    <path
      d="M304.978 8.40677C305.63 10.0709 305.773 11.8902 305.387 13.6348C305.001 15.3793 304.104 16.9707 302.811 18.2078C301.517 19.4448 299.884 20.272 298.119 20.5846C296.353 20.8973 294.534 20.6814 292.892 19.9643C291.249 19.2473 289.857 18.0611 288.892 16.5559C287.926 15.0508 287.43 13.2941 287.466 11.5081C287.502 9.72206 288.07 7.98688 289.096 6.52192C290.122 5.05697 291.561 3.92802 293.231 3.27783C294.341 2.84597 295.525 2.63607 296.715 2.66012C297.906 2.68417 299.081 2.9417 300.172 3.41799C301.262 3.89428 302.248 4.58001 303.073 5.436C303.898 6.29199 304.545 7.30146 304.978 8.40677Z"
      fill="#263238"
    />
    <path
      d="M382.718 115.905C382.585 111.983 382.415 112.375 382.3 109.029C382.232 107.002 382.222 104.749 382.209 102.8C382.203 101.877 381.878 100.986 381.289 100.274C380.7 99.5625 379.884 99.0745 378.976 98.8921C377.389 98.5712 375.798 98.2658 374.214 97.9295C372.148 97.4905 370.105 97.0182 368.076 96.4313C366.501 95.9757 364.944 95.4715 363.406 94.9189C361.755 94.3225 360.124 93.6772 358.51 92.9878C356.624 92.1815 354.76 91.3179 352.909 90.4376C350.844 89.45 348.794 88.429 346.751 87.3949C338.87 74.3936 338.701 65.8366 330.245 55.7863L312.093 63.9663C312.093 63.9663 325.43 87.5416 334.513 97.5359C339.741 103.29 347.945 105.885 355.259 107.764C360.537 109.121 365.889 110.16 371.249 111.141C373.331 111.521 375.596 111.669 377.389 112.908C378.716 113.925 379.626 115.389 379.947 117.026C380.032 117.356 380.104 117.69 380.169 118.025C380.353 119.026 382.799 118.272 382.718 115.905Z"
      fill="#407BFF"
    />
    <path
      d="M382.718 115.905C382.585 111.983 382.415 112.375 382.3 109.029C382.232 107.002 382.222 104.749 382.209 102.8C382.203 101.877 381.878 100.986 381.289 100.274C380.7 99.5625 379.884 99.0745 378.976 98.8921C377.389 98.5712 375.798 98.2658 374.214 97.9295C372.148 97.4905 370.105 97.0182 368.076 96.4313C366.501 95.9757 364.944 95.4715 363.406 94.9189C361.755 94.3225 360.124 93.6772 358.51 92.9878C356.624 92.1815 354.76 91.3179 352.909 90.4376C350.844 89.45 348.794 88.429 346.751 87.3949C338.87 74.3936 338.701 65.8366 330.245 55.7863L312.093 63.9663C312.093 63.9663 325.43 87.5416 334.513 97.5359C339.741 103.29 347.945 105.885 355.259 107.764C360.537 109.121 365.889 110.16 371.249 111.141C373.331 111.521 375.596 111.669 377.389 112.908C378.716 113.925 379.626 115.389 379.947 117.026C380.032 117.356 380.104 117.69 380.169 118.025C380.353 119.026 382.799 118.272 382.718 115.905Z"
      fill="#DEDFDF"
    />
    <path
      opacity="0.3"
      d="M373.513 97.7744C372.794 97.6181 372.076 97.4595 371.35 97.2889C371.407 101.408 369.467 108.521 368.756 110.677L371.031 111.107C372.526 106.812 373.362 102.318 373.513 97.7744Z"
      fill="#C4C4C4"
    />
    <path
      opacity="0.3"
      d="M382.207 102.796C382.201 101.873 381.877 100.98 381.288 100.267C380.699 99.5546 379.882 99.0655 378.974 98.8825C378.598 98.8062 378.21 98.7262 377.832 98.6606C378.02 100.017 378.716 101.252 379.78 102.12C380.474 102.755 381.307 103.222 382.213 103.483C382.209 103.242 382.208 103.023 382.207 102.796Z"
      fill="#C4C4C4"
    />
    <path
      d="M303.284 8.48311C303.763 10.2914 301.736 12.1926 300.11 10.6301C298.603 9.01802 296.953 7.54382 295.182 6.22518C293.515 5.18747 295.737 3.37802 298.355 4.07818C299.474 4.3587 300.507 4.90634 301.366 5.6738C302.225 6.44125 302.883 7.40545 303.284 8.48311Z"
      fill="white"
    />
    <path
      d="M303.422 66.1181C302.578 66.427 304.12 67.8548 304.12 67.8548C304.12 67.8548 320.847 62.145 331.06 56.2658C331.086 55.91 331.026 55.5533 330.885 55.2255C330.743 54.8978 330.525 54.6086 330.248 54.3824C321.535 58.7931 312.578 62.7112 303.422 66.1181Z"
      fill="#407BFF"
    />
    <path
      d="M303.422 66.1181C302.578 66.427 304.12 67.8548 304.12 67.8548C304.12 67.8548 320.847 62.145 331.06 56.2658C331.086 55.91 331.026 55.5533 330.885 55.2255C330.743 54.8978 330.525 54.6086 330.248 54.3824C321.535 58.7931 312.578 62.7112 303.422 66.1181Z"
      fill="#C4C4C4"
    />
    <path
      d="M315.753 16.86C319.056 18.5203 322.165 20.2809 325.269 22.2275C326.814 23.1972 328.353 24.1932 329.869 25.2679C331.397 26.3211 332.904 27.4316 334.419 28.6816L334.986 29.1444L335.694 29.7801C336.132 30.1733 336.546 30.5919 336.934 31.0337C337.322 31.4524 337.643 31.8568 337.957 32.2575C338.27 32.6583 338.594 33.0639 338.874 33.4599C339.992 35.0008 341.026 36.601 341.97 38.2536C343.811 41.4447 345.398 44.7743 346.718 48.2121C346.963 48.8616 346.975 49.5757 346.752 50.2331C346.529 50.8905 346.084 51.4507 345.494 51.8185C344.903 52.1864 344.203 52.3392 343.512 52.251C342.821 52.1628 342.182 51.8391 341.703 51.3348L341.645 51.2716C339.238 48.7334 336.931 46.0556 334.609 43.5686C332.334 41.0889 329.925 38.5423 327.979 37.4366C325.261 35.7476 322.232 34.0694 319.228 32.4186L310.173 27.4089L310.151 27.397C308.773 26.6432 307.748 25.3796 307.297 23.8792C306.846 22.3788 307.006 20.7619 307.742 19.3779C308.478 17.9939 309.731 16.9538 311.23 16.4822C312.729 16.0107 314.354 16.1455 315.755 16.8576L315.753 16.86Z"
      fill="#407BFF"
    />
    <path
      d="M315.753 16.86C319.056 18.5203 322.165 20.2809 325.269 22.2275C326.814 23.1972 328.353 24.1932 329.869 25.2679C331.397 26.3211 332.904 27.4316 334.419 28.6816L334.986 29.1444L335.694 29.7801C336.132 30.1733 336.546 30.5919 336.934 31.0337C337.322 31.4524 337.643 31.8568 337.957 32.2575C338.27 32.6583 338.594 33.0639 338.874 33.4599C339.992 35.0008 341.026 36.601 341.97 38.2536C343.811 41.4447 345.398 44.7743 346.718 48.2121C346.963 48.8616 346.975 49.5757 346.752 50.2331C346.529 50.8905 346.084 51.4507 345.494 51.8185C344.903 52.1864 344.203 52.3392 343.512 52.251C342.821 52.1628 342.182 51.8391 341.703 51.3348L341.645 51.2716C339.238 48.7334 336.931 46.0556 334.609 43.5686C332.334 41.0889 329.925 38.5423 327.979 37.4366C325.261 35.7476 322.232 34.0694 319.228 32.4186L310.173 27.4089L310.151 27.397C308.773 26.6432 307.748 25.3796 307.297 23.8792C306.846 22.3788 307.006 20.7619 307.742 19.3779C308.478 17.9939 309.731 16.9538 311.23 16.4822C312.729 16.0107 314.354 16.1455 315.755 16.8576L315.753 16.86Z"
      fill="#DEDFDF"
    />
    <path
      d="M349.607 54.0448L348.59 51.5102C348.59 51.5102 348.022 48.2598 345.995 47.3557L339.96 48.3016L340.171 48.8264H340.162C340.72 50.0574 340.298 52.1972 339.755 53.9446C339.213 55.692 340.134 55.9055 340.893 55.568C341.331 55.3748 341.743 54.603 342.21 53.7788C342.427 54.2135 342.678 54.6303 342.962 55.0253L344.438 57.0435C344.745 57.4622 345.201 57.7486 345.712 57.8443C346.224 57.9401 346.753 57.8379 347.192 57.5587L348.813 56.5246C349.215 56.2684 349.514 55.8803 349.659 55.4275C349.804 54.9746 349.786 54.4855 349.607 54.0448Z"
      fill="#407BFF"
    />
    <path
      d="M349.607 54.0448L348.59 51.5102C348.59 51.5102 348.022 48.2598 345.995 47.3557L339.96 48.3016L340.171 48.8264H340.162C340.72 50.0574 340.298 52.1972 339.755 53.9446C339.213 55.692 340.134 55.9055 340.893 55.568C341.331 55.3748 341.743 54.603 342.21 53.7788C342.427 54.2135 342.678 54.6303 342.962 55.0253L344.438 57.0435C344.745 57.4622 345.201 57.7486 345.712 57.8443C346.224 57.9401 346.753 57.8379 347.192 57.5587L348.813 56.5246C349.215 56.2684 349.514 55.8803 349.659 55.4275C349.804 54.9746 349.786 54.4855 349.607 54.0448Z"
      fill="#C4C4C4"
    />
    <path
      opacity="0.3"
      d="M310.696 30.5698L309.237 30.689L300.274 52.2592C300.764 52.2953 301.256 52.2551 301.734 52.14C301.734 52.14 313.42 47.8006 316.957 46.2107C314.575 41.2976 310.696 30.5698 310.696 30.5698Z"
      fill="#407BFF"
    />
    <path
      d="M294.331 36.2274C295.409 41.8609 297.417 47.2772 300.274 52.256C304.652 50.7531 311.961 47.9178 315.497 46.3267C313.14 41.2245 311.051 36.0039 309.237 30.6858C305.467 30.9387 297.25 34.022 294.331 36.2274Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M303.229 40.8944C303.054 41.4772 302.709 41.9951 302.238 42.3826C301.768 42.7702 301.192 43.01 300.585 43.0717C299.977 43.1335 299.365 43.0143 298.825 42.7294C298.285 42.4445 297.843 42.0067 297.553 41.4711C297.263 40.9356 297.139 40.3266 297.197 39.7209C297.255 39.1153 297.491 38.5402 297.877 38.0686C298.263 37.5969 298.78 37.2497 299.364 37.071C299.948 36.8923 300.572 36.89 301.157 37.0644C301.545 37.1803 301.907 37.3712 302.221 37.6264C302.536 37.8816 302.797 38.196 302.989 38.5517C303.182 38.9074 303.302 39.2973 303.343 39.6993C303.384 40.1013 303.346 40.5074 303.229 40.8944Z"
      fill="#42CDDD"
    />
    <path
      opacity="0.6"
      d="M305.52 36.7859C305.462 36.977 305.349 37.1468 305.195 37.2738C305.041 37.4009 304.852 37.4795 304.653 37.4997C304.454 37.5199 304.254 37.4809 304.077 37.3875C303.9 37.2941 303.755 37.1505 303.66 36.975C303.565 36.7994 303.524 36.5997 303.543 36.4012C303.562 36.2026 303.64 36.0142 303.766 35.8595C303.893 35.7049 304.062 35.5912 304.254 35.5326C304.445 35.474 304.649 35.4733 304.841 35.5306C304.968 35.5684 305.087 35.6309 305.19 35.7146C305.293 35.7982 305.379 35.9013 305.442 36.0179C305.505 36.1345 305.544 36.2624 305.557 36.3942C305.571 36.526 305.558 36.6591 305.52 36.7859Z"
      fill="#42CDDD"
    />
    <path
      opacity="0.6"
      d="M308.192 35.7506C308.135 35.942 308.022 36.1122 307.867 36.2396C307.713 36.367 307.524 36.4459 307.325 36.4663C307.125 36.4867 306.924 36.4476 306.747 36.3541C306.57 36.2606 306.425 36.1168 306.33 35.941C306.235 35.7651 306.194 35.5651 306.213 35.3662C306.232 35.1673 306.31 34.9786 306.437 34.8238C306.563 34.669 306.733 34.5551 306.925 34.4966C307.117 34.4381 307.321 34.4376 307.513 34.4952C307.641 34.5332 307.759 34.5958 307.862 34.6795C307.965 34.7631 308.051 34.8662 308.114 34.9827C308.177 35.0993 308.216 35.2271 308.23 35.3589C308.243 35.4906 308.23 35.6237 308.192 35.7506Z"
      fill="#42CDDD"
    />
    <path
      opacity="0.5"
      d="M311.959 45.0381L300.888 49.3977L300.219 47.2281L311.291 42.8685L311.959 45.0381Z"
      fill="#42CDDD"
    />
    <path
      opacity="0.2"
      d="M250.854 210.814C250.828 217.759 250.155 224.687 248.842 231.509C247.429 237.639 245.234 242.124 242.257 244.963C240.87 246.335 239.223 247.417 237.412 248.148C235.601 248.879 233.662 249.244 231.708 249.221C225.554 249.221 220.857 245.878 217.616 239.19C217.046 237.989 216.562 236.749 216.167 235.481C209.321 241.348 202.948 247.741 197.105 254.601L190.748 262.068C194.169 265.522 198.117 268.417 202.446 270.644C209.905 274.484 219.691 276.409 231.804 276.417C233.936 276.417 235.995 276.357 237.994 276.238C245.327 265.424 251.393 253.811 256.074 241.623L273.855 195.439L250.854 210.814Z"
      fill="black"
    />
    <path
      d="M90.5676 273.778L111.521 294.652L170.202 239.584C150.012 231.939 110.648 253.775 90.5676 273.778Z"
      fill="#A4A7A8"
    />
    <path
      d="M163.646 346.578L142.693 325.705L197.97 267.247C205.646 287.36 183.726 326.574 163.646 346.578Z"
      fill="#A4A7A8"
    />
    <path
      d="M243.841 215.024L266.698 171.118L222.626 193.89C208.466 201.206 195.393 210.442 183.779 221.334L108.65 291.792L145.564 328.565L216.292 253.722C227.225 242.152 236.496 229.129 243.841 215.024Z"
      fill="#C1F9FF"
    />
    <path
      d="M196.902 256.034C205.432 256.034 212.346 249.146 212.346 240.649C212.346 232.152 205.432 225.263 196.902 225.263C188.372 225.263 181.458 232.152 181.458 240.649C181.458 249.146 188.372 256.034 196.902 256.034Z"
      fill="white"
    />
    <path
      d="M196.902 250.531C202.381 250.531 206.822 246.107 206.822 240.649C206.822 235.191 202.381 230.767 196.902 230.767C191.423 230.767 186.982 235.191 186.982 240.649C186.982 246.107 191.423 250.531 196.902 250.531Z"
      fill="#A4A7A8"
    />
    <path
      opacity="0.3"
      d="M158.047 315.356L121.91 279.357L151.498 251.609L185.901 285.875L158.047 315.356Z"
      fill="#42CDDD"
    />
    <path
      d="M98.7478 306.768C71.682 308.962 62.7152 326.218 59.6584 349.158C58.1018 360.858 57.4074 372.695 47.5461 379.544C46.9494 379.944 46.4995 380.526 46.2643 381.203C46.0291 381.88 46.0213 382.614 46.2423 383.296C46.4632 383.978 46.9008 384.569 47.489 384.981C48.0772 385.393 48.784 385.603 49.5026 385.58C85.8286 384.45 102.772 366.734 104.9 359.309C104.345 363.178 103.343 366.97 101.916 370.61C101.646 371.257 101.59 371.972 101.756 372.653C101.922 373.333 102.302 373.943 102.84 374.394C103.378 374.845 104.046 375.113 104.747 375.16C105.448 375.207 106.147 375.03 106.74 374.655C116.939 368.29 129.729 356.578 130.578 337.586C123.038 327.026 98.7478 306.768 98.7478 306.768Z"
      fill="#407BFF"
    />
    <path
      d="M98.7478 306.768C71.682 308.962 62.7152 326.218 59.6584 349.158C58.1018 360.858 57.4074 372.695 47.5461 379.544C46.9494 379.944 46.4995 380.526 46.2643 381.203C46.0291 381.88 46.0213 382.614 46.2423 383.296C46.4632 383.978 46.9008 384.569 47.489 384.981C48.0772 385.393 48.784 385.603 49.5026 385.58C85.8286 384.45 102.772 366.734 104.9 359.309C104.345 363.178 103.343 366.97 101.916 370.61C101.646 371.257 101.59 371.972 101.756 372.653C101.922 373.333 102.302 373.943 102.84 374.394C103.378 374.845 104.046 375.113 104.747 375.16C105.448 375.207 106.147 375.03 106.74 374.655C116.939 368.29 129.729 356.578 130.578 337.586C123.038 327.026 98.7478 306.768 98.7478 306.768Z"
      fill="#34D6E9"
    />
    <path
      d="M134.735 345.753L91.3914 302.574L114.478 297.598L139.724 322.748L134.735 345.753Z"
      fill="#42CDDD"
    />
  </svg>
);

export default NotFoundIcon;
