import { FILTERS_QUERY_PARAMS } from '../constants/api';
import { ClientsTableFiltersData } from '../types';

export const serializeClientsQueryString = (params: {
  search: string | null;
  filters: ClientsTableFiltersData;
  sortType?: string;
}) => {
  const { search, filters, sortType } = params ?? {};

  const res = [];
  if (search) {
    res.push(`search=${search}`);
  }
  if (filters.waitingForSignature) {
    res.push(`tos_status=${FILTERS_QUERY_PARAMS.WAITING_FOR_SIGNATURE}`);
  }
  if (filters.signed) {
    res.push(`tos_status=${FILTERS_QUERY_PARAMS.SIGNED}`);
  }
  if (filters.notSigned) {
    res.push(`tos_status=${FILTERS_QUERY_PARAMS.NOT_SIGNED}`);
  }

  // sorting
  if (sortType) {
    res.push(`sort=fullname:${sortType}`);
  }

  filters.organizations.forEach((org) => {
    res.push(`org_id=${org.value}`);
  });
  return res.join('&');
};
