import React from 'react';

interface TOSListIconProps {
  isActive?: boolean;
}

export const TosListIcon: React.FunctionComponent<TOSListIconProps> = ({
  isActive,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.61538 20.5H17.3846C18.2754 20.5 19 19.81 19 18.9619V8.12695H15.2308C14.34 8.12695 13.6154 7.437 13.6154 6.58887V3H6.61538C5.72463 3 5 3.68995 5 4.53809V18.9619C5 19.81 5.72463 20.5 6.61538 20.5ZM8.76923 10.2119H15.2308C15.5284 10.2119 15.7692 10.4412 15.7692 10.7246C15.7692 11.008 15.5284 11.2373 15.2308 11.2373H8.76923C8.47161 11.2373 8.23077 11.008 8.23077 10.7246C8.23077 10.4412 8.47161 10.2119 8.76923 10.2119ZM8.76923 12.2627H15.2308C15.5284 12.2627 15.7692 12.492 15.7692 12.7754C15.7692 13.0588 15.5284 13.2881 15.2308 13.2881H8.76923C8.47161 13.2881 8.23077 13.0588 8.23077 12.7754C8.23077 12.492 8.47161 12.2627 8.76923 12.2627ZM8.76923 14.3135H15.2308C15.5284 14.3135 15.7692 14.5428 15.7692 14.8262C15.7692 15.1096 15.5284 15.3389 15.2308 15.3389H8.76923C8.47161 15.3389 8.23077 15.1096 8.23077 14.8262C8.23077 14.5428 8.47161 14.3135 8.76923 14.3135ZM8.76923 16.3643H13.0769C13.3745 16.3643 13.6154 16.5936 13.6154 16.877C13.6154 17.1603 13.3745 17.3896 13.0769 17.3896H8.76923C8.47161 17.3896 8.23077 17.1603 8.23077 16.877C8.23077 16.5936 8.47161 16.3643 8.76923 16.3643Z"
      fill={isActive ? '#FFDE6E' : '#FBFBFB'}
    />
    <path
      d="M15.2308 7.10156H18.6845L14.6923 3.3004V6.58886C14.6923 6.87174 14.9337 7.10156 15.2308 7.10156Z"
      fill={isActive ? '#FFDE6E' : '#FBFBFB'}
    />
  </svg>
);
