import React, {
  ChangeEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { eegUserFiltersInitialValues } from '../constants/table';
import { usePageData } from '../hooks/wnAdmin/usePageData';
import { EegUserPageFilters, ReprocessType } from '../types';
import { serializeEegLabUserBraincareTableParams } from '../utils/eegLabUserBraincare';
import { EegTablePageData } from './EegLabUserNeurorefFiltersContext';

export interface EegLabUserBraincareFilterContextProps {
  queryString: string;
  handleInputSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleInputSearchClick: () => void;
  handleFilters: (values: EegUserPageFilters) => void;
  pageData: EegTablePageData;
  setPageData: (v: EegTablePageData) => void;
  clearEegHomeBraincareTableContextState: () => void;
  eegIdToReprocess: number | string;
  setReprocessEegId: (value: number | string) => void;
  reprocessType: ReprocessType | null;
  setReprocessType: (v: ReprocessType | null) => void;
  totalCount: number;
  setTotalCount: (total: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const EegLabUserBraincareFilterContext = React.createContext<EegLabUserBraincareFilterContextProps>(
  {
    queryString: '',
    handleInputSearchChange: () => undefined,
    handleInputSearchClick: () => undefined,
    handleFilters: () => undefined,
    pageData: {
      searchTerm: null,
      searchInputValue: '',
      filters: eegUserFiltersInitialValues,
      currentPage: 1,
      sortFields: {
        created_at: '',
        is_archived: false,
      },
    },
    totalCount: 0,
    setTotalCount: () => undefined,
    setPageData: () => undefined,
    clearEegHomeBraincareTableContextState: () => undefined,
    eegIdToReprocess: '',
    setReprocessEegId: () => undefined,
    reprocessType: null,
    setReprocessType: () => undefined,
    isLoading: false,
    setIsLoading: () => undefined,
  }
);

interface EegLabUserBraincareFilterProviderProps {
  children: React.ReactNode;
}

const EegLabUserBraincareFilterProvider: React.FunctionComponent<EegLabUserBraincareFilterProviderProps> = (
  props
): JSX.Element => {
  const [pageData, setPageData] = useState<EegTablePageData>({
    searchTerm: null,
    searchInputValue: '',
    filters: eegUserFiltersInitialValues,
    currentPage: 1,
    sortFields: {
      created_at: '',
      is_archived: false,
    },
  });
  const [eegIdToReprocess, setEegIdToReprocess] = useState<number | string>('');
  const [reprocessType, setReprocessType] = useState<ReprocessType | null>(
    null
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleInputSearchChange,
    handleInputSearchClick,
    handleFilters,
  } = usePageData(pageData, setPageData);

  const queryString = useMemo(
    () =>
      serializeEegLabUserBraincareTableParams(
        pageData.searchTerm,
        pageData.filters,
        pageData.sortFields
      ),
    [pageData.searchTerm, pageData.filters, pageData.sortFields]
  );

  const clearEegHomeBraincareTableContextState = useCallback(() => {
    setPageData({
      searchTerm: null,
      searchInputValue: '',
      filters: eegUserFiltersInitialValues,
      currentPage: 1,
      sortFields: {
        created_at: '',
        is_archived: false,
      },
    });
  }, []);

  const { children } = props;

  const memoizedValues = useMemo(
    () => ({
      queryString,
      handleInputSearchChange,
      handleInputSearchClick,
      handleFilters,
      pageData,
      setPageData,
      clearEegHomeBraincareTableContextState,
      eegIdToReprocess,
      setReprocessEegId: setEegIdToReprocess,
      reprocessType,
      setReprocessType,
      totalCount,
      setTotalCount,
      isLoading,
      setIsLoading,
    }),
    [
      queryString,
      handleInputSearchChange,
      handleInputSearchClick,
      handleFilters,
      pageData,
      setPageData,
      clearEegHomeBraincareTableContextState,
      eegIdToReprocess,
      setEegIdToReprocess,
      reprocessType,
      setReprocessType,
      totalCount,
      setTotalCount,
      isLoading,
      setIsLoading,
    ]
  );

  return (
    <EegLabUserBraincareFilterContext.Provider value={memoizedValues}>
      {children}
    </EegLabUserBraincareFilterContext.Provider>
  );
};

export default EegLabUserBraincareFilterProvider;
