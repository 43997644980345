import { EegUserPageFilters, ReportStatus } from '../types';

export const serializeEegLabUserBraincareTableParams = (
  search: string | null,
  filters: EegUserPageFilters,
  sortFields: {
    created_at: string;
    is_archived: boolean;
  }
) => {
  const res = [];
  if (search) {
    res.push(`search=${search}`);
  }
  if (filters.approvedBc) {
    res.push(`status=${ReportStatus.APPROVED}`);
  }
  if (filters.rejectedBc) {
    res.push(`status=${ReportStatus.REJECTED}`);
  }
  if (filters.brokenBcReport) {
    res.push(`status=${ReportStatus.BROKEN}`);
  }
  if (filters.readyForReviewBc) {
    res.push(`status=${ReportStatus.READY_FOR_REVIEW}`);
  }
  if (sortFields.created_at) {
    res.push(`sort=${sortFields.created_at}`);
  }
  res.push(`is_archived=${sortFields.is_archived}`);
  filters.organizations.forEach((org) => {
    res.push(`org_id=${org.value}`);
  });
  return res.join('&');
};
