import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import SideBarLogo from '../../assets/icons/SideBarLogo';
import { ROUTES } from '../../constants';
import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { RouteType, SignInRoleType } from '../../types';
import {
  getPathWithoutParams,
  getRoleBasedSideMenuRoutes,
} from '../../utils/routing';
import MenuItem from '../MenuItem';
import SidebarMenuClientInfo from './SidebarMenuClientInfo';
import { SidebarMenuLogOut } from './SidebarMenuLogOut';
import SidebarMenuUserInfo from './SidebarMenuUserInfo';
import { SidebarMenuVersionInfo } from './SidebarMenuVersionInfo';

const SidebarMenu: React.FunctionComponent = () => {
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);
  const { roleType } = authContext;
  const isWnUser = roleType === SignInRoleType.WN_USER;

  const { user } = userContext;
  const router = useLocation();
  const sideMenuRoutes = useMemo(() => getRoleBasedSideMenuRoutes(user?.role), [
    user?.role,
  ]);

  const activeRoute: RouteType | undefined = sideMenuRoutes.find(
    (route) =>
      route.path === router.pathname ||
      route.subroutes?.some(
        (subrote) =>
          getPathWithoutParams(subrote.path) ===
          getPathWithoutParams(router.pathname)
      )
  );

  const [selectedActiveRoute, setSelectedActiveRoute] = useState<
    string | undefined
  >(activeRoute?.path);

  useEffect(() => {
    setSelectedActiveRoute(
      sideMenuRoutes.find((route) => route.path === activeRoute?.path)?.path ||
        selectedActiveRoute ||
        ROUTES.HOME
    );
  }, [activeRoute?.path, sideMenuRoutes, selectedActiveRoute]);

  return (
    <aside className="fixed z-9999 top-0 left-0 h-full bg-outer-space overflow-hidden whitespace-nowrap flex flex-col justify-between w-105px pt-8 pb-2">
      <div>
        <div className="flex justify-center mb-2">
          <SideBarLogo />
        </div>
        {isWnUser && (
          <div className="mb-8 flex justify-center">
            <SidebarMenuUserInfo />
          </div>
        )}
        {sideMenuRoutes.map((sideMenuRoute) => (
          <MenuItem
            key={sideMenuRoute.id}
            title={sideMenuRoute.title}
            icon={sideMenuRoute.icon}
            path={sideMenuRoute.path}
            isActive={selectedActiveRoute === sideMenuRoute.path}
          />
        ))}
      </div>
      <div>
        {!isWnUser && <SidebarMenuClientInfo />}
        <SidebarMenuLogOut />
        <SidebarMenuVersionInfo />
      </div>
    </aside>
  );
};

export default SidebarMenu;
