import React, { ChangeEventHandler, useMemo, useState } from 'react';

import { patientFiltersInitialValues } from '../constants/table';
import { usePageData } from '../hooks/usePageData';
import { PatientFiltersFormVal } from '../types';
import { serializePatientTableParams } from '../utils/managePatientsTable';

export interface ManagePatientsTableContextProps {
  queryString: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  setSearchTerm: (v: string) => void;
  handleFilters: (values: PatientFiltersFormVal) => void;
  totalPageCount: number;
  setTotalPageCount: (pageCount: number) => void;
  hasNext: boolean;
  setHasNext: (value: boolean) => void;
  hasPrev: boolean;
  setHasPrev: (value: boolean) => void;
  pageData: ManagePatientsTablePageData;
  setPageData: (v: ManagePatientsTablePageData) => void;
  clearPatientsTableContextState: () => void;
}

export type ManagePatientsTablePageData = {
  searchTerm: string | null;
  filters: PatientFiltersFormVal;
  currentPage: number;
  sortFields: {
    first_name: string;
  };
};

export const ManagePatientsTableContext = React.createContext<ManagePatientsTableContextProps>(
  {
    queryString: '',
    handleChange: () => undefined,
    handleFilters: () => undefined,
    totalPageCount: 0,
    setTotalPageCount: () => undefined,
    setSearchTerm: () => undefined,
    hasNext: false,
    setHasNext: () => undefined,
    hasPrev: false,
    setHasPrev: () => undefined,
    pageData: {
      searchTerm: null,
      filters: patientFiltersInitialValues,
      currentPage: 1,
      sortFields: {
        first_name: '',
      },
    },
    setPageData: () => undefined,
    clearPatientsTableContextState: () => undefined,
  }
);

interface ManagePatientsTableProviderProps {
  children: React.ReactNode;
}

const ManagePatientsTableProvider: React.FunctionComponent<ManagePatientsTableProviderProps> = (
  props
) => {
  const [pageData, setPageData] = useState<ManagePatientsTablePageData>({
    searchTerm: null,
    filters: patientFiltersInitialValues,
    currentPage: 1,
    sortFields: {
      first_name: '',
    },
  });

  const {
    totalPageCount,
    setTotalPageCount,
    hasNext,
    hasPrev,
    setHasNext,
    setHasPrev,
    handleChange,
    handleFilters,
    setSearchTerm,
  } = usePageData(pageData, setPageData);

  const queryString = useMemo(
    () =>
      serializePatientTableParams(
        pageData.searchTerm,
        pageData.filters,
        pageData.sortFields
      ),
    [pageData.searchTerm, pageData.filters, pageData.sortFields]
  );

  const clearPatientsTableContextState = () => {
    setTotalPageCount(0);
    setHasNext(false);
    setHasPrev(false);
    setPageData({
      searchTerm: null,
      filters: patientFiltersInitialValues,
      currentPage: 1,
      sortFields: {
        first_name: '',
      },
    });
  };

  const { children } = props;

  return (
    <ManagePatientsTableContext.Provider
      value={{
        setSearchTerm,
        queryString,
        handleChange,
        handleFilters,
        totalPageCount,
        setTotalPageCount,
        hasNext,
        setHasNext,
        hasPrev,
        setHasPrev,
        pageData,
        setPageData,
        clearPatientsTableContextState,
      }}
    >
      {children}
    </ManagePatientsTableContext.Provider>
  );
};

export default ManagePatientsTableProvider;
