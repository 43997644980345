import React, { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { ROUTES } from '../constants';
import { ClientsPageContext } from '../context/ClientsPageContext';
import { EegLabUserBraincareFilterContext } from '../context/EegLabUserBraincareFiltersContext';
import { EegLabUserFilterContext } from '../context/EegLabUserFiltersContext';
import { EegLabUserNeurorefFilterContext } from '../context/EegLabUserNeurorefFiltersContext';
import { ManagePatientsTableContext } from '../context/ManagePatientsTableContext';
import { OrganizationsPageContext } from '../context/OrganizationsPageContext';
import { PPMDownloadsHistoryTableContext } from '../context/PPMDownloadsHistoryTableContext';
import { SessionHistoryContext } from '../context/SessionHistoryContext';
import { TosTableContext } from '../context/TosTableContext';
import { UserContext } from '../context/UserContext';
import { WnAdminHomeProviderContext } from '../context/WnAdminHomeTableContext';
import { UserRole } from '../types';

interface MenuItemProps {
  title: string;
  path: string;
  isActive: boolean;
  icon: React.ReactNode;
}

const MenuItem: React.FunctionComponent<MenuItemProps> = ({
  title,
  path,
  isActive,
  icon,
}): JSX.Element => {
  const { clearEegHomeTableContextState } = useContext(EegLabUserFilterContext);
  const { clearEegHomeBraincareTableContextState } = useContext(
    EegLabUserBraincareFilterContext
  );
  const { clearEegHomeNeurorefTableContextState } = useContext(
    EegLabUserNeurorefFilterContext
  );
  const { clearWnAdminHomeTableContextState } = useContext(
    WnAdminHomeProviderContext
  );
  const { clearClientsPageContextState } = useContext(ClientsPageContext);
  const { clearTosTableContextState } = useContext(TosTableContext);
  const { clearPatientsTableContextState } = useContext(
    ManagePatientsTableContext
  );
  const { clearSessionHistoryPageContextState } = useContext(
    SessionHistoryContext
  );
  const { clearOrganizationsPageContextState } = useContext(
    OrganizationsPageContext
  );
  const { clearPPMDownloadsHistoryTableContextState } = useContext(
    PPMDownloadsHistoryTableContext
  );

  const { user } = useContext(UserContext);

  const menuItemRef = useRef(null);

  const userRole = user?.role;

  return (
    <Link
      ref={menuItemRef}
      data-tip
      data-for={title}
      to={path}
      onClick={() => {
        if (path === ROUTES.CLIENTS) {
          clearTosTableContextState();
        }
        if (userRole === UserRole.WN_ADMIN) {
          clearWnAdminHomeTableContextState();
          clearClientsPageContextState();
          clearSessionHistoryPageContextState();
          clearOrganizationsPageContextState();
          clearPPMDownloadsHistoryTableContextState();
        }
        if (path === ROUTES.HOME && userRole) {
          if (userRole === UserRole.EEG_LAB_USER) {
            clearEegHomeTableContextState();
            clearEegHomeBraincareTableContextState();
            clearEegHomeNeurorefTableContextState();
          }
          if (
            userRole === UserRole.STORE_ADMIN ||
            userRole === UserRole.STORE_USER ||
            userRole === UserRole.WN_SALES
          ) {
            clearPatientsTableContextState();
            clearSessionHistoryPageContextState();
            clearPPMDownloadsHistoryTableContextState();
            clearTosTableContextState();
          }
        }
      }}
      className={`flex justify-center py-5 ${
        !isActive ? '' : 'bg-solomie-yellow bg-opacity-15'
      }`}
    >
      {React.cloneElement(icon as React.ReactElement, {
        isActive,
      })}
      <ReactTooltip id={title} place="right" effect="solid" className="tooltip">
        {title}
      </ReactTooltip>
    </Link>
  );
};

export default MenuItem;
