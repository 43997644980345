import React, { useEffect, useMemo, useState } from 'react';

import {
  AUTH_USER_ROLE_TYPE,
  IS_AUTHENTICATED,
  USERS_SEEN_RELEASE_NOTES,
} from '../constants';
import { SignInRoleType } from '../types';
import { getItem, setItem } from '../utils/localStorageApi';

export interface AuthContextProps {
  isAuthenticated: boolean;
  setAuthenticated: (value: boolean) => void;
  roleType: SignInRoleType;
  setRoleType: (value: SignInRoleType) => void;
  currentSession: string;
  setCurrentSession: (session: string) => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
  isAuthenticated: false,
  setAuthenticated: () => undefined,
  roleType: SignInRoleType.WN_USER,
  setRoleType: () => undefined,
  currentSession: '',
  setCurrentSession: () => undefined,
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FunctionComponent<AuthProviderProps> = (
  props
): JSX.Element => {
  const authenticated: string | null = getItem(IS_AUTHENTICATED);
  const authRoleType: string | null = getItem(AUTH_USER_ROLE_TYPE);
  const listUsersSeenReleaseNotes: string | null = getItem(
    USERS_SEEN_RELEASE_NOTES
  );

  const roleTypeDefaultValue = (authRoleType: SignInRoleType) => {
    switch (authRoleType) {
      case SignInRoleType.WN_USER:
        return SignInRoleType.WN_USER;
      case SignInRoleType.WN_CLIENT:
        return SignInRoleType.WN_CLIENT;
      default:
        return SignInRoleType.WN_USER;
    }
  };

  const [isAuthenticated, setAuthenticated] = useState<boolean>(
    authenticated === 'true'
  );
  const [roleType, setRoleType] = useState<SignInRoleType>(
    roleTypeDefaultValue(authRoleType as SignInRoleType)
  );
  const [currentSession, setCurrentSession] = useState('');

  useEffect(() => {
    setItem(IS_AUTHENTICATED, isAuthenticated);
    setItem(AUTH_USER_ROLE_TYPE, roleType);
    setItem(USERS_SEEN_RELEASE_NOTES, listUsersSeenReleaseNotes);
  }, [isAuthenticated, roleType, listUsersSeenReleaseNotes]);

  const { children } = props;
  const authData = useMemo(
    () => ({
      isAuthenticated,
      setAuthenticated,
      roleType,
      setRoleType,
      currentSession,
      setCurrentSession,
    }),
    [
      isAuthenticated,
      setAuthenticated,
      roleType,
      setRoleType,
      currentSession,
      setCurrentSession,
    ]
  );

  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
