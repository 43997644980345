import React from 'react';

const LogOut: React.FunctionComponent = (): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9235 10.618C23.8725 10.496 23.7995 10.385 23.7065 10.292L20.7075 7.29299C20.3165 6.90299 19.6845 6.90299 19.2935 7.29299C18.9025 7.68397 18.9025 8.31697 19.2935 8.70697L20.5865 9.99997H15.0005C14.4475 9.99997 14.0005 10.448 14.0005 11C14.0005 11.552 14.4475 11.9999 15.0005 11.9999H20.5865L19.2935 13.2929C18.9025 13.6839 18.9025 14.3169 19.2935 14.7069C19.4885 14.9029 19.7444 14.9999 20.0005 14.9999C20.2565 14.9999 20.5125 14.903 20.7075 14.7069L23.7065 11.7079C23.7995 11.6159 23.8725 11.5049 23.9235 11.3819C24.0245 11.138 24.0245 10.862 23.9235 10.618Z"
      fill="#EA1F4C"
    />
    <path
      d="M17.0005 14C16.4475 14 16.0005 14.448 16.0005 15V20H12.0005V3.99998C12.0005 3.55898 11.7105 3.16898 11.2875 3.042L7.81446 2.00002H16.0005V7.00003C16.0005 7.55203 16.4475 8.00002 17.0005 8.00002C17.5534 8.00002 18.0004 7.55203 18.0004 7.00003V1.00003C18.0004 0.447984 17.5534 0 17.0005 0H1.00047C0.964473 0 0.932457 0.015 0.897488 0.0189844C0.850473 0.024 0.807488 0.0319688 0.762488 0.0429844C0.657488 0.0699844 0.562473 0.111 0.473504 0.166969C0.45152 0.180984 0.42452 0.181969 0.40352 0.197953C0.395457 0.204 0.392457 0.215016 0.384441 0.221016C0.275457 0.306984 0.184473 0.411984 0.118473 0.537C0.104457 0.564 0.101457 0.593016 0.0904883 0.621C0.0584727 0.696984 0.0235039 0.771 0.0115039 0.855C0.00648828 0.885 0.0154883 0.912984 0.0145039 0.942C0.0135195 0.962016 0.000488281 0.980016 0.000488281 0.999984V21C0.000488281 21.477 0.337473 21.887 0.804488 21.98L10.8045 23.98C10.8695 23.994 10.9355 24 11.0005 24C11.2294 24 11.4544 23.9211 11.6344 23.773C11.8654 23.583 12.0004 23.3 12.0004 23V22H17.0005C17.5534 22 18.0004 21.552 18.0004 21V15C18.0004 14.448 17.5534 14 17.0005 14Z"
      fill="#EA1F4C"
    />
  </svg>
);

export default LogOut;
