import React, { useState } from 'react';
import Loader from 'react-loader-spinner';

interface LazyImageProps {
  src: string;
  imageStyles: string;
  alt?: string;
}

const LazyImage: React.FunctionComponent<LazyImageProps> = ({
  src,
  imageStyles,
  alt = 'Image',
}) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="bg-iron rounded-full relative">
      {isLoading && (
        <div
          className={`absolute inset-0 z-10 flex items-center justify-center border-0.5 border-middle-gray ${imageStyles} `}
        >
          <Loader type="Oval" color="#12141A" height={20} width={20} />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={imageStyles}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
};

export default LazyImage;
