import React, { FC } from 'react';

interface ModalCloseType {
  size?: number;
}

const ModalClose: FC<ModalCloseType> = ({ size = 18 }): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 1L1 17M17 17L1 1L17 17Z"
      stroke="#A4A7A8"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default ModalClose;
