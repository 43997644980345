import { getTime } from 'date-fns';

type getIdleTimeType = {
  getLastActiveTime: () => Date | null;
  LOG_OUT_TIMER: number;
};

// return true if idle time > LOG_OUT_TIMER
export const getIdleTime = ({
  getLastActiveTime,
  LOG_OUT_TIMER,
}: getIdleTimeType): boolean => {
  const actualDate = getTime(new Date());
  const lastActivity = getTime(new Date(getLastActiveTime() ?? new Date()));

  return actualDate - lastActivity >= LOG_OUT_TIMER;
};
