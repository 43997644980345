import React, { ChangeEventHandler, useMemo, useState } from 'react';

import { usePageData } from '../hooks/wnAdmin/usePageData';
import { serializePPMDownloadsHistoryTableParams } from '../utils/managePPMDownloadsHistoryTable';

export interface PPMDownloadsHistoryTableContextProps {
  queryString: string;
  handleInputSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleInputSearchClick: () => void;
  pageData: PPMDownloadsHistoryTablePageData;
  handleFilters: (values: any) => void;
  setPageData: (v: PPMDownloadsHistoryTablePageData) => void;
  clearPPMDownloadsHistoryTableContextState: () => void;
}

export type PPMDownloadsHistoryTablePageData = {
  searchTerm: string | null;
  filters: { organizations: any };
  currentPage: number;
};

export const PPMDownloadsHistoryTableContext = React.createContext<PPMDownloadsHistoryTableContextProps>(
  {
    queryString: '',
    pageData: {
      searchTerm: null,
      currentPage: 1,
      filters: {
        organizations: [],
      },
    },
    handleInputSearchChange: () => undefined,
    handleInputSearchClick: () => undefined,
    handleFilters: () => undefined,
    setPageData: () => undefined,
    clearPPMDownloadsHistoryTableContextState: () => undefined,
  }
);

interface PPMDownloadsHistoryTableProviderProps {
  children: React.ReactNode;
}

const PPMDownloadsHistoryTableProvider: React.FunctionComponent<PPMDownloadsHistoryTableProviderProps> = (
  props
) => {
  const [pageData, setPageData] = useState<any>({
    searchTerm: null,
    searchInputValue: '',
    filters: { organizations: [] },
    currentPage: 1,
  });

  const {
    handleInputSearchChange,
    handleInputSearchClick,
    handleFilters,
  } = usePageData(pageData, setPageData);

  const queryString = useMemo(
    () =>
      serializePPMDownloadsHistoryTableParams(
        pageData.searchTerm,
        pageData.filters
      ),
    [pageData.searchTerm, pageData.filters]
  );

  const clearPPMDownloadsHistoryTableContextState = () => {
    setPageData({
      searchTerm: null,
      searchInputValue: '',
      filters: { organizations: [] },
      currentPage: 1,
      lastItemId: null,
    });
  };

  const { children } = props;

  const PPMDownloadsHistoryMemoizedData = useMemo(
    () => ({
      handleFilters,
      handleInputSearchChange,
      handleInputSearchClick,
      queryString,
      pageData,
      setPageData,
      clearPPMDownloadsHistoryTableContextState,
    }),
    [
      handleFilters,
      handleInputSearchChange,
      handleInputSearchClick,
      pageData,
      queryString,
    ]
  );

  return (
    <PPMDownloadsHistoryTableContext.Provider
      value={PPMDownloadsHistoryMemoizedData}
    >
      {children}
    </PPMDownloadsHistoryTableContext.Provider>
  );
};

export default PPMDownloadsHistoryTableProvider;
